import React, { useEffect, useState } from "react";
import { apiLinkShape } from "components/propTypes/apiLinkShape";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import { getPublicLinks } from "data/actions/getPublicLinks";

import APILink from "components/ui/APILink";
import ButtonAsLink from "components/ui/ButtonAsLink";
import { Disclosure, DisclosurePanel } from "@reach/disclosure";
import { XLSpacer } from "components/ui/VerticalSpacers";

export const SplashPageResourceLinks = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPublicLinks());
  }, [dispatch]);

  const {
    createCalnetLink,
    admitsLoginLink,
    delegateAccessLink,
    learnMoreLink,
  } = useSelector((state) => {
    const {
      publicLinks: {
        UC_CC_CLC_SPLASH_CALNET: createCalnetLink,
        UC_CC_CLC_SPLASH_ADMISSIONS: admitsLoginLink,
        UC_CC_CLC_SPLASH_DELEGATES: delegateAccessLink,
        UC_CC_CLC_SPLASH_CLC_SUPPORT: learnMoreLink,
      } = {},
    } = state;

    return {
      createCalnetLink,
      admitsLoginLink,
      delegateAccessLink,
      learnMoreLink,
    };
  });

  const [isOpen, setOpen] = useState(false);

  if (
    createCalnetLink ||
    admitsLoginLink ||
    delegateAccessLink ||
    learnMoreLink
  ) {
    return (
      <SplashPageResourceLinksWrapper>
        <XLSpacer />
        <Disclosure open={isOpen}>
          <div style={{textAlign: 'center', width: '100%'}}>
            <ButtonAsLink onClick={() => setOpen(!isOpen)}>
              {isOpen ? (
                <>
                  Show Less <i className="fa fa-chevron-up"></i>
                </>
              ) : (
                <>
                  New to CalCentral? <i className="fa fa-chevron-down"></i>
                </>
              )}
            </ButtonAsLink>
          </div>

          <DisclosurePanel style={{ padding: `var(--lg)`, minWidth: `100%` }}>
            <strong>Getting started with CalCentral</strong>
            <br />
            {createCalnetLink && (
              <p>
                <DescriptionWithBreak link={createCalnetLink} />
                <APILink link={createCalnetLink} />
              </p>
            )}
            {admitsLoginLink && (
              <p>
                <DescriptionWithBreak link={admitsLoginLink} />
                <APILink link={admitsLoginLink} />
              </p>
            )}
            {delegateAccessLink && (
              <p>
                <DescriptionWithBreak link={delegateAccessLink} />
                <APILink link={delegateAccessLink} />
              </p>
            )}
            {learnMoreLink && (
              <p>
                <DescriptionWithBreak link={learnMoreLink} />
                <APILink link={learnMoreLink} />
              </p>
            )}
          </DisclosurePanel>
        </Disclosure>
      </SplashPageResourceLinksWrapper>
    );
  }
  return null;
};

const DescriptionWithBreak = ({link}) => {
  if (link.displayDescription) {
    return (
      <>
        {link.description}
        <br />
      </>
    );
  }
  return null;
}

DescriptionWithBreak.propTypes = {
  link: apiLinkShape,
};
DescriptionWithBreak.displayName = "DescriptionWithBreak";

// TODO: Remove after react migration is completed
const SplashPageResourceLinksWrapper = styled.div`
  p {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
`;
