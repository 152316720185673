import React from "react";
import PropTypes from "prop-types";
import ButtonAsLink from "../../ui/ButtonAsLink";
import { WidgetSubtitle } from "components/ui/WidgetSubtitle";

export const CommitteeToggle = ({
  isActive,
  setActive,
  numberOfActiveCommittees,
  numberOfCompletedCommittees,
}) => {
  return (
    <>
      <ButtonAsLink
        className="toggle-link"
        onClick={() => setActive(!isActive)}
      >
        View {isActive ? "Completed" : "Active"} Committees
      </ButtonAsLink>
      <WidgetSubtitle>
        {isActive ? "Active" : "Completed"} Committees
      </WidgetSubtitle>
      {isActive
        ? numberOfActiveCommittees === 0 && (
            <div className="widget-padding">
              You are not a member of any active committees.
            </div>
          )
        : numberOfCompletedCommittees === 0 && (
            <div className="widget-padding">
              You are not a member of any completed committees.
            </div>
          )}
    </>
  );
};

CommitteeToggle.propTypes = {
  isActive: PropTypes.bool,
  setActive: PropTypes.func,
  numberOfActiveCommittees: PropTypes.number,
  numberOfCompletedCommittees: PropTypes.number,
};
