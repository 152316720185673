import React from "react";
import { useSelector } from "react-redux";
import currentUserState from "utils/currentUserState";
import { BulletList } from "components/ui/Lists";
import { AccessibleLinkContent } from "components/ui/AccessibleLinkContent";

export function Resources() {
  const { links = [] } = useSelector(
    (state) => currentUserState(state).loanHistoryAidYears
  );

  return (
    <div id="resources">
      <h3>Resources</h3>
      <BulletList>
        {links.map(({ sequence, url, title, descr }) => (
          <li key={sequence}>
            <a
              href={url}
              title={title}
              target="_blank"
              rel="noopener noreferrer"
            >
              <AccessibleLinkContent showNewWindow={true}>
                {title}
              </AccessibleLinkContent>
            </a>
            {descr && <p style={{ marginTop: 0 }}>{descr}</p>}
          </li>
        ))}
      </BulletList>
    </div>
  );
}
