import { setTargetUserId } from 'data/actions/advisingActions';
import { fetchAdvisingStudentProfile } from "data/slices/users/advisingStudentProfile";
import advisingStudentState from "utils/advisingStudentState";

var _ = require('lodash');

angular
  .module('calcentral.controllers')
  .controller('StudentOverviewController', function(
    academicsService,
    adminService,
    advisingFactory,
    apiService,
    committeesService,
    linkService,
    $route,
    $routeParams,
    $scope,
    $ngRedux,
  ) {
    linkService.addCurrentRouteSettings($scope);

    $scope.academics = {
      isLoading: true,
      excludeLinksToRegistrar: true,
    };
    $scope.committees = {
      isLoading: true,
      showCommittees: false,
    };
    $scope.planSemestersInfo = {
      isLoading: true,
    };
    $scope.isAdvisingStudentLookup = $route.current.isAdvisingStudentLookup;
    $scope.targetUser = {
      isLoading: true,
    };
    $scope.degreeProgress = {
      graduate: {},
      undergraduate: {},
      isLoading: true,
    };
    $scope.standingIsVisible = false;

    $scope.profilePicture = {};
    $scope.alumnViewAs = {};

    var parseAlumniProfiles = function(response) {
      angular.extend($scope.alumnViewAs, _.get(response, 'data'));
    };

    var defaultErrorDescription = function(status) {
      if (status === 403) {
        return "You are not authorized to view this user's data.";
      } else {
        return "Sorry, there was a problem fetching this user's data. Contact CalCentral support if the error persists.";
      }
    };

    var errorReport = function(status, errorDescription) {
      return {
        summary: status === 403 ? 'Access Denied' : 'Unexpected Error',
        description: errorDescription || defaultErrorDescription(status),
      };
    };

    var toggleTipViewAs = function() {
      $scope.alumnViewAs.tipVisibleViewAs = !$scope.alumnViewAs
        .tipVisibleViewAs;
    };
    $scope.alumnViewAs.toggleTipViewAs = toggleTipViewAs;

    var loadProfile = function() {
      var targetUserUid = $routeParams.uid;

      $ngRedux.dispatch(setTargetUserId(targetUserUid));
      $ngRedux.dispatch(fetchAdvisingStudentProfile(targetUserUid));

      $ngRedux.subscribe(() => {
        const reduxState = $ngRedux.getState();

        const {
          advisingStudentProfile: {
            loadState: advisingStudentProfileLoadState,
            uid,
            campusSolutionsId,
            fullName,
            roles,
          } = {},
        } = advisingStudentState(reduxState, targetUserUid) || {};

        if (fullName) {
          apiService.util.setTitle(fullName);
        }

        angular.extend(
          $scope.targetUser, {
            advisingStudentProfileLoadState,
            campusSolutionsId,
            fullName,
            roles,
            uid,
          }
        );
      });

      advisingFactory
        .getStudent({
          uid: targetUserUid,
        })
        .then(
          function successCallback(response) {
            $scope.targetUser.academicRoles = _.get(
              response,
              'data.academicRoles'
            );
            $scope.targetUser.ldapUid = targetUserUid;
            advisingFactory.getAlumniProfile().then(parseAlumniProfiles);
          },
          function errorCallback(response) {
            $scope.targetUser.error = errorReport(
              _.get(response, 'data.status'),
              _.get(response, 'data.error')
            );
          }
        )
        .finally(function() {
          $scope.targetUser.isLoading = false;

          // used by status and holds card
          $scope.isNonDegreeSeekingSummerVisitor = academicsService.isNonDegreeSeekingSummerVisitor(
            $scope.targetUser
          );

          // used by status and holds card
          $scope.showResidency =
            $scope.targetUser.roles.student &&
            academicsService.showResidency(
              $scope.targetUser.academicRoles.current
            );

          // used by status and holds card
          if (
            $scope.targetUser.academicRoles.current.ugrd &&
            !$scope.targetUser.academicRoles.current.ugrdNonDegree
          ) {
            $scope.standingIsVisible = true;
          }
        });
    };

    var processSemesters = function(planSemesters) {
      _.forEach(planSemesters, function(semester) {
        angular.extend(semester, {
          show:
            ['current', 'previous', 'next'].indexOf(semester.timeBucket) > -1,
        });
      });
    };

    var loadAcademics = function() {
      advisingFactory
        .getStudentAcademics({
          uid: $routeParams.uid,
        })
        .then(
          function successCallback(response) {
            // Ensure object is cloned to avoid mutation in Redux data store
            let studentAcademicsDataString = JSON.stringify(
              _.get(response, 'data')
            );
            angular.extend($scope, JSON.parse(studentAcademicsDataString));

            processSemesters($scope.planSemesters);

            if (_.get($scope, 'updatePlanURL.url')) {
              linkService.addCurrentPagePropertiesToLink(
                $scope.updatePlanURL,
                $scope.currentPage.name,
                $scope.currentPage.url
              );
            }
          },
          function errorCallback(response) {
            $scope.academics.error = errorReport(
              _.get(response, 'status'),
              _.get(response, 'data.error')
            );
          }
        )
        .finally(function() {
          $scope.academics.isLoading = false;
          $scope.planSemestersInfo.isLoading = false;
        });
    };

    var showTip = function() {
      $scope.degreeProgress.undergraduate.tipVisible = true;
      return false;
    };

    var loadDegreeProgresses = function() {
      advisingFactory
        .getDegreeProgressGraduate({
          uid: $routeParams.uid,
        })
        .then(function(response) {
          $scope.degreeProgress.graduate.progresses = _.get(
            response,
            'data.feed.degreeProgress'
          );
          $scope.degreeProgress.graduate.errored = _.get(response, 'errored');
        })
        .then(function() {
          advisingFactory
            .getDegreeProgressUndergrad({
              uid: $routeParams.uid,
            })
            .then(function(response) {
              $scope.degreeProgress.undergraduate.progresses = _.get(
                response,
                'data.feed.degreeProgress.progresses'
              );
              $scope.degreeProgress.undergraduate.transferCreditReviewDeadline = _.get(
                response,
                'data.feed.degreeProgress.transferCreditReviewDeadline'
              );
              $scope.degreeProgress.undergraduate.showTip = showTip;
              $scope.degreeProgress.undergraduate.links = _.get(
                response,
                'data.feed.links'
              );
              $scope.degreeProgress.undergraduate.errored = _.get(
                response,
                'errored'
              );
            })
            .finally(function() {
              $scope.degreeProgress.undergraduate.showCard =
                apiService.user.profile.features.csDegreeProgressUgrdAdvising &&
                ($scope.targetUser.academicRoles.historical.ugrd ||
                  $scope.degreeProgress.undergraduate.progresses.length);
              $scope.degreeProgress.graduate.showCard =
                apiService.user.profile.features.csDegreeProgressGradAdvising &&
                ($scope.degreeProgress.graduate.progresses.length ||
                  $scope.targetUser.academicRoles.current.grad ||
                  $scope.targetUser.academicRoles.current.law);
              $scope.degreeProgress.undergraduate.showCard = $scope
                .degreeProgress.graduate.showCard
                ? false
                : $scope.degreeProgress.undergraduate.showCard;
              $scope.degreeProgress.isLoading = false;
            });
        });
    };

    var loadCommittees = function() {
      advisingFactory
        .getStudentCommittees({
          uid: $routeParams.uid,
        })
        .then(function(response) {
          var committeeData = _.get(response, 'data');
          if (!committeeData) {
            return;
          }
          var studentCommittees = committeesService.parseCommitteeData(
            committeeData.studentCommittees,
            false
          );

          angular.extend($scope, {
            studentCommittees: studentCommittees,
          });
        })
        .finally(function() {
          $scope.committees.isLoading = false;
          $scope.committees.showCommittees =
            ($scope.targetUser.roles.student ||
              $scope.targetUser.roles.exStudent) &&
            $scope.studentCommittees.length > 0;
        });
    };

    $scope.totalTransferUnits = function() {
      var unitsAdjusted = _.get(
        $scope,
        'transferCredit.ucTransferCrseSch.unitsAdjusted'
      );
      var totalTestUnits = _.get(
        $scope,
        'transferCredit.ucTestComponent.totalTestUnits'
      );
      return academicsService.totalTransferUnits(unitsAdjusted, totalTestUnits);
    };

    $scope.expireAcademicsCache = function() {
      advisingFactory.expireAcademicsCache({
        uid: $routeParams.uid,
      });
    };

    $scope.targetUser.actAs = function() {
      adminService.actAs($scope.targetUser);
    };

    $scope.$on('calcentral.api.user.isAuthenticated', function(
      event,
      isAuthenticated
    ) {
      if (isAuthenticated) {
        // Refresh user properties because the canSeeCSLinks property is sensitive to the current route.
        apiService.user
          .fetch()
          .then(loadProfile)
          .then(loadAcademics)
          .then(loadDegreeProgresses)
          .then(loadCommittees);
      }
    });
  });
