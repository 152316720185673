'use strict';

var _ = require('lodash');

/**
 * Academics controller
 */
angular
  .module('calcentral.controllers')
  .controller('AcademicsController', function(
    academicsFactory,
    academicsService,
    csLinkFactory,
    apiService,
    linkService,
    $q,
    $routeParams,
    $scope,
    $location,
    $ngRedux
  ) {
    $ngRedux.subscribe(() => {
      const {
        apiLinks: {
          UC_CX_XFER_CREDIT_REPORT_STDNT: tcReportLink,
          UC_CX_WAITLIST_STDNT_OPTS: waitlistsAndStudentOptions,
          UC_CX_ACAD_GUIDE_GRADES: academicGuideGradesPolicy,
          UC_CX_WAITLIST_REASON_NOT_ENRL: waitlistReasonLink,
          UC_CX_WAITLIST_SWAP: swapClassInfoLink,
        },
        messages = [],
      } = $ngRedux.getState();

      const studentLinks = {
        academicGuideGradesPolicy,
        messages,
        swapClassInfoLink,
        tcReportLink,
        waitlistReasonLink,
        waitlistsAndStudentOptions,
      };
      const studentLinksPresent = (_.compact(_.values(studentLinks)).length > 0);
      if (studentLinksPresent) {
        $scope.studentLinks = studentLinks;
      }
    });

    linkService.addCurrentRouteSettings($scope);
    apiService.util.setTitle($scope.currentPage.name);

    $scope.academics = {
      isLoading: true,
    };

    $scope.gotoGrading = function(slug) {
      $location.path('/academics/semester/' + slug);
    };

    $scope.redirectToHome = function() {
      apiService.util.redirectToHome();
      return false;
    };

    var checkPageExists = function(page) {
      if (!page) {
        apiService.util.redirect('404');
        return false;
      } else {
        return true;
      }
    };

    var updatePrevNextSemester = function(semestersLists, selectedSemester) {
      var nextSemester = {};
      var nextSemesterCompare = false;
      var previousSemester = {};
      var previousSemesterCompare = false;
      var selectedSemesterCompare =
        selectedSemester.termYear + selectedSemester.termCode;
      for (var i = 0; i < semestersLists.length; i++) {
        var semesterList = semestersLists[i];
        if (!semesterList) {
          continue;
        }
        var isStudentSemesterList = i === 0;
        for (var j = 0; j < semesterList.length; j++) {
          var semester = semesterList[j];
          if (isStudentSemesterList && !semester.hasEnrollmentData) {
            continue;
          }
          var cmp = semester.termYear + semester.termCode;
          if (
            cmp < selectedSemesterCompare &&
            (!previousSemesterCompare || cmp > previousSemesterCompare)
          ) {
            previousSemesterCompare = cmp;
            previousSemester.slug = semester.slug;
          } else if (
            cmp > selectedSemesterCompare &&
            (!nextSemesterCompare || cmp < nextSemesterCompare)
          ) {
            nextSemesterCompare = cmp;
            nextSemester.slug = semester.slug;
          }
        }
      }
      $scope.nextSemester = nextSemester;
      $scope.previousSemester = previousSemester;
      $scope.previousNextSemesterShow =
        nextSemesterCompare || previousSemesterCompare;
    };

    var setClassInfoCategories = function(teachingSemester) {
      $scope.classInfoCategories = [
        {
          title: 'Class Info',
          path: null,
        },
      ];
      if (teachingSemester) {
        if (
          apiService.user.profile.features.classInfoEnrollmentTab &&
          teachingSemester.campusSolutionsTerm
        ) {
          $scope.classInfoCategories.push({
            title: 'Enrollment',
            path: 'enrollment',
          });
        }
        $scope.classInfoCategories.push({
          title: 'Roster',
          path: 'roster',
        });
      }
      if ($routeParams.category) {
        $scope.currentCategory = _.find($scope.classInfoCategories, {
          path: $routeParams.category,
        });
      } else {
        $scope.currentCategory = $scope.classInfoCategories[0];
      }
    };

    var setGradingFlags = function(selectedTeachingSemester) {
      if (!selectedTeachingSemester) {
        return false;
      }
      var teachingCourses = academicsService.getClassesSections(
        selectedTeachingSemester.classes,
        false
      );
      $scope.containsMidpointClass = academicsService.containsMidpointClass(
        selectedTeachingSemester
      );
      $scope.containsLawClass = academicsService.containsLawClass(
        selectedTeachingSemester
      );
      $scope.isSummerSemester = academicsService.isSummerSemester(
        selectedTeachingSemester
      );
      $scope.teachingCoursesHaveTopics = academicsService.courseCollectionHasTopics(
        teachingCourses
      );
    };

    var fillSemesterSpecificPage = function(semesterSlug, data) {
      var isOnlyInstructor = !!$routeParams.teachingSemesterSlug;
      var selectedStudentSemester = academicsService.findSemester(
        data.semesters,
        semesterSlug,
        selectedStudentSemester
      );
      var selectedTeachingSemester = academicsService.findSemester(
        data.teachingSemesters,
        semesterSlug,
        selectedTeachingSemester
      );
      var selectedSemester =
        selectedStudentSemester || selectedTeachingSemester;
      var enrolledCourses;
      var waitlistedCourses;
      if (!checkPageExists(selectedSemester)) {
        return;
      }
      updatePrevNextSemester(
        [data.semesters, data.teachingSemesters],
        selectedSemester
      );

      $scope.selectedSemester = selectedSemester;
      if (selectedStudentSemester && !$routeParams.classId) {
        $scope.selectedCourses = selectedStudentSemester.classes;
        if (!isOnlyInstructor) {
          enrolledCourses = academicsService.getClassesSections(
            selectedStudentSemester.classes,
            false
          );
          waitlistedCourses = academicsService.getClassesSections(
            selectedStudentSemester.classes,
            true
          );
          $scope.swapClassList = academicsService.getSwapClasses(
            enrolledCourses,
            waitlistedCourses
          );
          $scope.swapCount = $scope.swapClassList.length;
          $scope.allCourses = academicsService.getAllClasses(data.semesters);
          $scope.previousCourses = academicsService.getPreviousClasses(
            data.semesters
          );
          $scope.enrolledCourses = enrolledCourses;
          $scope.waitlisted = {
            lawOnly: _.every(waitlistedCourses, { courseCareerCode: 'LAW' }),
            courses: waitlistedCourses,
            haveTopics: academicsService.courseCollectionHasTopics(
              waitlistedCourses
            ),
          };
          $scope.enrolledCoursesHaveTopics = academicsService.courseCollectionHasTopics(
            enrolledCourses
          );
        }
      }
      $scope.selectedStudentSemester = selectedStudentSemester;
      $scope.selectedTeachingSemester = selectedTeachingSemester;
      setGradingFlags(selectedTeachingSemester);

      // Get selected course from URL params and extract data from selected semester schedule
      if ($routeParams.classId) {
        var classSemester = isOnlyInstructor
          ? selectedTeachingSemester
          : selectedStudentSemester;
        $scope.isInstructorOrGsi = isOnlyInstructor;

        var matchingCourse = classSemester.classes.find(course => course.course_id === $routeParams.classId);
        if (matchingCourse) {
          let course = structuredClone(matchingCourse);
          if ($routeParams.sectionSlug) {
            $scope.selectedSection = academicsService.filterBySectionSlug(
              course,
              $routeParams.sectionSlug
            );
          }
          academicsService.normalizeGradingData(course);
          course.topics = academicsService.collectTopics(course);
          $scope.selectedCourse = course.sections.length ? course : null;
          if (isOnlyInstructor) {
            $scope.campusCourseId = course.listings[0].course_id;
          }
        }
        if (!checkPageExists($scope.selectedCourse)) {
          return;
        }
        if (
          $routeParams.sectionSlug &&
          !checkPageExists($scope.selectedSection)
        ) {
          return;
        }
        $scope.selectedCourseCountInstructors = academicsService.countSectionItem(
          $scope.selectedCourse,
          'instructors'
        );
        $scope.selectedCourseCountScheduledSections = academicsService.countSectionItem(
          $scope.selectedCourse
        );
        $scope.selectedCourseLongInstructorsList =
          $scope.selectedCourseCountScheduledSections > 5 ||
          $scope.selectedCourseCountInstructors > 10;

        var recurringCount = academicsService.countSectionItem(
          $scope.selectedCourse,
          'schedules.recurring'
        );
        var oneTimeCount = academicsService.countSectionItem(
          $scope.selectedCourse,
          'schedules.oneTime'
        );
        $scope.classScheduleCount = {
          oneTime: oneTimeCount,
          recurring: recurringCount,
          total: oneTimeCount + recurringCount,
        };
        setClassInfoCategories(selectedTeachingSemester);
      }
    };

    var parseAcademics = function(response) {
      // Ensure object is cloned to avoid mutation in Redux data store
      let responseDataString = JSON.stringify(_.get(response, 'data'));
      let responseData = JSON.parse(responseDataString);
      angular.extend($scope, responseData);

      $scope.hasTeachingClasses = academicsService.hasTeachingClasses(
        _.get(response, 'data.teachingSemesters')
      );
      $scope.canViewFinalExamSchedule =
        apiService.user.profile.roles.student &&
        !apiService.user.profile.delegateActingAsUid &&
        !apiService.user.profile.academicRoles.current.summerVisitor;

      // summarize section topics for courses
      if ($scope.semesters) {
        academicsService.summarizeStudentClassTopics($scope.semesters);
      }

      // Get selected semester from URL params and extract data from semesters array
      var semesterSlug =
        $routeParams.semesterSlug || $routeParams.teachingSemesterSlug;

      if (semesterSlug) {
        fillSemesterSpecificPage(semesterSlug, _.get(response, 'data'));
      } else {
        if (
          $scope.hasTeachingClasses &&
          (!response.data ||
            !response.data.semesters ||
            response.data.semesters.length === 0)
        ) {
          // Show the current semester, or the most recent semester, since otherwise the instructor
          // landing page will be grimly bare.
          $scope.selectedTeachingSemester = academicsService.chooseDefaultSemester(
            response.data.teachingSemesters
          );
          setGradingFlags($scope.selectedTeachingSemester);
          $scope.widgetSemesterName = $scope.selectedTeachingSemester.name;
        }
      }
    };

    // Wait until user profile is fully loaded before hitting academics data
    $scope.$on('calcentral.api.user.isAuthenticated', function(
      event,
      isAuthenticated
    ) {
      if (isAuthenticated) {
        $scope.canViewAcademics = apiService.user.profile.hasAcademicsTab;

        if (!$scope.canViewAcademics) {
          apiService.user.redirectToHome();
        }
        csLinkFactory.getUserLinks();

        var getAcademics = academicsFactory.getAcademics().then(parseAcademics);
        const requests = [getAcademics];
        $q.all(requests);
      }

      $scope.academics.isLoading = false;
    });
  });
