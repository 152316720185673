import React from "react";
import { useSelector } from "react-redux";
import currentUserState from "utils/currentUserState";
import { BulletList } from "components/ui/Lists";
import { ExternalLink } from "components/ui/ExternalLink";

export function Resources() {
  const { links = [] } = useSelector(
    (state) => currentUserState(state).loanHistoryCumulative
  );

  return (
    <div id="resources">
      <h3>Resources</h3>
      <BulletList>
        {links.map(({ sequence, url, title, descr }) => (
          <li key={sequence}>
            <ExternalLink href={url} title={title}>
              {title}
            </ExternalLink>
            {descr && <p style={{ marginTop: 0 }}>{descr}</p>}
          </li>
        ))}
      </BulletList>
    </div>
  );
}
