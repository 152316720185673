import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import { CCTable } from "components/ui/Table";
import AccessibleLinkContent from "components/ui/AccessibleLinkContent";
import { courseShape } from "components/propTypes/courseShape";
import { sectionShape } from "../propTypes/sectionShape";

export default function InstructorsCard({ course }) {
  const sections = course.sections.filter(
    (section) => !section.scheduledWithCcn
  );
  const instructorCount = sections.reduce(
    (accumulator, section) => accumulator + section.instructors.length,
    0
  );

  return (
    <Card data-testid="class-instructors-card">
      <CardTitle>
        <h2>{instructorCount === 1 ? "Instructor" : "Instructors"}</h2>
      </CardTitle>
      <CardBody>
        {sections.map((section, index) => (
          <Section key={index} section={section} index={index} />
        ))}
      </CardBody>
    </Card>
  );
}

InstructorsCard.displayName = "InstructorsCard";
InstructorsCard.propTypes = {
  course: courseShape,
};

function Section({ index, section }) {
  const instructors = [...section.instructors]
    .filter((instructor) => instructor.printInSchedule === "Y")
    .sort((a, b) => a.ccDelegateRoleOrder - b.ccDelegateRoleOrder);

  return (
    <SectionTable data-testid="instructor-section">
      <thead>
        <tr>
          <th width="50%">
            <h3>{section.section_label}</h3>
          </th>
          <th width="30%">{index === 0 && <h3>Role</h3>}</th>
        </tr>
      </thead>
      <tbody>
        {instructors.map((instructor, index) => (
          <tr key={index}>
            <td>
              <a
                href={`https://www.berkeley.edu/directory/results?search-term=${instructor.name}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AccessibleLinkContent showNewWindow={true}>
                  &#9632; {instructor.name}
                </AccessibleLinkContent>
              </a>
            </td>
            <td>{instructor.ccDelegateRole}</td>
          </tr>
        ))}
      </tbody>
    </SectionTable>
  );
}

Section.displayName = "Section";
Section.propTypes = {
  index: PropTypes.number,
  section: sectionShape,
};

const SectionTable = styled(CCTable)`
  thead th {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;
