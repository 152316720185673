import React from "react";
import PropTypes from "prop-types";

import SectionHeader from "./SectionHeader";
import DollarComparisonRow from "./DollarComparisonRow";
import aidYearShape from "./aidYearShape";
import "./AwardComparison.scss";
import { ComparisonTable } from "./ComparisonTable";

import { AwardType } from "./AwardType";

const awardTypeWithoutItems = (awardType) => {
  return { type: awardType.type, description: awardType.description };
};

const uniqueAwardTypes = (comparison, snapshot) => {
  const awardTypes = comparison.awardTypes.map(awardTypeWithoutItems) || [];
  const snapshotTypes = snapshot?.awardTypes.map(awardTypeWithoutItems) || [];

  return awardTypes.concat(snapshotTypes).reduce((accum, current) => {
    const found = accum.find((item) => item.type === current.type);

    if (found) {
      return accum;
    }

    return [...accum, current];
  }, []);
};

const itemsInOneArrayButNotTheOther = (current, snapshot) => {
  const { awards: { awardTypes: currentAwardTypes = [] } = {} } = current || {};
  const mergedCurrentItems = [];
  currentAwardTypes.map((currentAwardType) => {
    currentAwardType.items.map((item) => mergedCurrentItems.push(item));
  });

  const { awards: { awardTypes: snapshotAwardTypes = [] } = {} } =
    snapshot || {};
  const mergedSnapshotItems = [];
  snapshotAwardTypes.map((snapshotAwardType) => {
    snapshotAwardType.items.map((item) => mergedSnapshotItems.push(item));
  });
  const currentSet = new Set(
    mergedCurrentItems.map((item) => item.description)
  );
  const snapshotSet = new Set(
    mergedSnapshotItems.map((item) => item.description)
  );
  const difference = new Set(
    [...snapshotSet].filter((x) => !currentSet.has(x))
  );

  return Array.from(difference);
};

const comparer = (otherArray) => {
  return function (current) {
    return (
      otherArray.filter(function (other) {
        return (
          other.description == current.description &&
          other.value == current.value
        );
      }).length == 0
    );
  };
};

const countTheChanges = (current, snapshot) => {
  const { awards: { awardTypes: currentAwardTypes = [] } = {} } = current || {};
  const mergedCurrentItems = [];
  currentAwardTypes.map((currentAwardType) => {
    currentAwardType.items.map((item) => mergedCurrentItems.push(item));
  });

  const { awards: { awardTypes: snapshotAwardTypes = [] } = {} } =
    snapshot || {};
  const mergedSnapshotItems = [];
  snapshotAwardTypes.map((snapshotAwardType) => {
    snapshotAwardType.items.map((item) => mergedSnapshotItems.push(item));
  });

  if (mergedSnapshotItems.length === 0) {
    return 0;
  }

  // identify current items that are different or not in the snapshot
  const onlyInA = mergedCurrentItems.filter(comparer(mergedSnapshotItems));
  const onlyInB = itemsInOneArrayButNotTheOther(
    mergedCurrentItems,
    mergedSnapshotItems
  ).filter(comparer(mergedCurrentItems));

  const currentVsSnapshot = onlyInA.concat(onlyInB);

  // identify snapshot items that are different or not in the current
  const onlyInA2 = mergedSnapshotItems.filter(comparer(mergedCurrentItems));
  const onlyInB2 = itemsInOneArrayButNotTheOther(
    mergedSnapshotItems,
    mergedCurrentItems
  ).filter(comparer(mergedSnapshotItems));

  const snapshotVsCurrent = onlyInB2.concat(onlyInA2);

  // the following takes the two sets of differences, combines them
  // and removes duplicates (remember, Sets contain unique values)
  const combined = new Set(
    currentVsSnapshot.concat(snapshotVsCurrent).map((item) => item.description)
  );

  return combined.size;
};

const ifLoaded = (dataObj, callback) =>
  dataObj && dataObj.loaded ? callback() : null;

export function Awards({
  expanded,
  onExpand,
  setExpand,
  aidYearData,
  aidYearSnapshot,
}) {
  const numberOfChanges = aidYearSnapshot
    ? countTheChanges(
        aidYearData.currentComparisonData,
        aidYearSnapshot ? aidYearSnapshot : null
      )
    : 0;

  const currentComparison = aidYearData.currentComparisonData.awards;
  const currentSnapshot = aidYearSnapshot?.awards;
  const allAwardTypes = uniqueAwardTypes(currentComparison, currentSnapshot);

  return (
    <div>
      <section
        aria-expanded={expanded}
        aria-label="Awards"
        className="clickable"
        role="button"
        tabIndex="0"
        onClick={() => onExpand(setExpand, expanded)}
        onKeyPress={() => onExpand(setExpand, expanded)}
      >
        <SectionHeader
          expanded={expanded}
          label="Awards"
          numberOfChanges={numberOfChanges}
        />
      </section>

      {expanded ? (
        <div>
          <div className="container">
            <ComparisonTable>
              <thead>
                <tr>
                  <th scope="col">Description</th>
                  <th scope="col">Prior Value</th>
                  <th scope="col">Current Value</th>
                </tr>
              </thead>
              <tbody>
                {allAwardTypes.map((awardType) => (
                  <AwardType
                    key={awardType.type}
                    awardType={awardType}
                    currentComparison={currentComparison}
                    currentSnapshot={currentSnapshot}
                  />
                ))}
              </tbody>
              <tfoot>
                <DollarComparisonRow
                  description="Total Awards"
                  current={aidYearData.currentComparisonData.awards.total}
                  snapshot={ifLoaded(
                    aidYearSnapshot,
                    () => aidYearSnapshot.awards.total
                  )}
                />
              </tfoot>
            </ComparisonTable>
          </div>
        </div>
      ) : (
        <hr />
      )}
    </div>
  );
}

Awards.displayName = "Awards";
Awards.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
  setExpand: PropTypes.func.isRequired,
  aidYearData: aidYearShape.isRequired,
  aidYearSnapshot: PropTypes.object,
};
