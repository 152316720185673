import React from "react";
import PropTypes from "prop-types";
import WithAPILink from "components/ui/WithAPILink";
import { Textbook } from "./Textbook";
import HairlineRule from "components/ui/HairlineRule";

export function CourseBooks({ course }) {
  const {
    isLawCourse,
    courseCode,
    textbooks: { books: { items = [], bookUnavailableError } = {} } = {},
  } = course;

  if (isLawCourse) {
    return (
      <>
        <h3>{courseCode}</h3>
        {isLawCourse && <WithAPILink urlID="UC_CX_LAW_BOOK" /> && (
          <div className="cc-widget-padding">
            Please visit the <WithAPILink urlID="UC_CX_LAW_BOOK" /> for textbook
            information of Law classes.
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <h3>{courseCode}</h3>

      {items.map((item, index) => (
        <Textbook key={index} book={item} />
      ))}

      {items.length == 0 && (
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: bookUnavailableError,
            }}
          ></div>
          <HairlineRule />
        </>
      )}
    </>
  );
}

CourseBooks.displayName = "CourseBooks";
CourseBooks.propTypes = {
  course: PropTypes.object,
};
