import React from "react";
import { react2angular } from "react2angular";
import { BConnectedSetupCard } from "components/cards/BConnectedSetupCard";
import ReduxProvider from "components/ReduxProvider";

function NgBConnectedSetupCard() {
  return (
    <ReduxProvider>
      <BConnectedSetupCard />
    </ReduxProvider>
  );
}

angular
  .module("calcentral.react")
  .component("bconnectedSetupCard", react2angular(NgBConnectedSetupCard));
