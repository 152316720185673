import { useVisibleLinks } from "./useVisibleLinks";
import { useTopCategoryName } from "./useTopCategoryName";

export function useMatchingLinks() {
  const visibleLinks = useVisibleLinks();
  const topCategoryName = useTopCategoryName();

  if (visibleLinks) {
    return visibleLinks
      .filter((link) =>
        link.categories.find((item) =>
          item.topcategory.includes(topCategoryName)
        )
      )
      .sort((a, b) => {
        const aName = a.name ? a.name.toUpperCase() : "";
        const bName = b.name ? b.name.toUpperCase() : "";

        if (aName === bName) {
          return 0;
        }

        return aName > bName ? 1 : -1;
      });
  }
}
