import React from "react";
import { Route, Routes, Link } from "react-router-dom";
import { Page, PageTitle } from "components/ui/Page";
import { PageColumn, EvenThirdsLayout } from "components/ui/PageColumns";
import { BConnectedConnectCard } from "components/cards/BConnectedConnectCard";
import { Sidebar, NavigationGroupItem } from "components/ui/Sidebar";
import { UnstyledList } from "components/ui/Lists";

export function Settings() {
  return (
    <Routes>
      <Route path="" element={<BConnectedPage />}></Route>
      <Route path="bconnected" element={<BConnectedPage />}></Route>
    </Routes>
  );
}
Settings.displayName = "Settings";

function BConnectedPage() {
  return (
    <Page>
      <PageTitle title="Settings" />
      <EvenThirdsLayout style={{ marginTop: `-15px` }}>
        <PageColumn style={{ flex: 1 }}>
          <Sidebar style={{ height: "100%" }}>
            <UnstyledList>
              <NavigationGroupItem>
                <h3>CalCentral Settings</h3>

                <UnstyledList style={{ marginTop: `10px` }}>
                  <li>
                    <Link
                      to="/settings/bconnected"
                      className="active"
                      aria-current="page"
                    >
                      bConnected
                    </Link>
                  </li>
                </UnstyledList>
              </NavigationGroupItem>
            </UnstyledList>
          </Sidebar>
        </PageColumn>
        <PageColumn
          style={{
            gridColumn: `span 2`,
            paddingLeft: `var(--md)`,
            paddingTop: `var(--lg)`,
          }}
        >
          <h1 style={{ fontWeight: `normal`, margin: 0, lineHeight: 1 }}>
            CalCentral Settings
          </h1>
          <BConnectedConnectCard />
        </PageColumn>
      </EvenThirdsLayout>
    </Page>
  );
}
