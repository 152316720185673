import React from "react";
import PropTypes from "prop-types";

import formatCurrency from "functions/formatCurrency";

import styled from "styled-components";

const ItemAmount = ({ amount }) => {
  const className = `ItemAmount ${amount < 0 ? "ItemAmount--payment" : ""}`;

  return (
    <ItemAmountStyles data-testid="item-amount">
      <div className={className}>{formatCurrency(amount)}</div>
    </ItemAmountStyles>
  );
};
ItemAmount.displayName = "ItemAmount";
ItemAmount.propTypes = {
  amount: PropTypes.number,
};

export default ItemAmount;
export const ItemAmountStyles = styled.div`
  .ItemAmount {
    white-space: nowrap;
  }

  @media only screen and (min-width: 576px) {
    .ItemAmount {
      padding-right: 12px;
    }
  }

  .ItemAmount--payment {
    color: green;
  }
`;
