import React from "react";
import { useSelector } from "react-redux";
import { LargeSpacer } from "components/ui/VerticalSpacers";

export const VisaStatus = () => {
  const { code, description } = useSelector((state) => {
    const {
      advising: {
        studentData: {
          demographics: {
            feed: {
              usaCountry: {
                visa: { type: { code, description } = {} } = {},
              } = {},
            } = {},
          } = {},
        } = {},
      } = {},
    } = state;

    return { code, description };
  });

  if (code && description) {
    return (
      <div>
        <h4>Visa</h4>
        <div>
          {code} {description}
        </div>
        <LargeSpacer />
      </div>
    );
  }

  return null;
}
VisaStatus.displayName = "VisaStatus";
