import React from "react";
import { useCurrentSemesters } from "components/hooks/useCurrentSemesters";
import { FinalGradesCardUI } from "./FinalGradesCard/FinalGradesCardUI";

export function FinalGradesCard() {
  const { semester } = useCurrentSemesters();

  if (
    semester &&
    semester.timeBucket === "past" &&
    !semester.gradingInProgress
  ) {
    const primarySections = semester?.classes.map((course) => ({
      ...course.sections.find((section) => section.is_primary_section),
      course_code: course.course_code,
    }));

    return <FinalGradesCardUI semester={semester} sections={primarySections} />;
  }

  return null;
}
