import React from "react";
import { react2angular } from "react2angular";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReduxProvider from "components/ReduxProvider";
import { CourseSitesCard } from "components/cards/CourseSitesCard";
import WithCurrentCourse from "components/data/WithCurrentCourse";
import CourseContext from "components/contexts/CourseContext";

const NgCourseSitesCard = () => (
  <ReduxProvider>
    <Router>
      <Routes>
        <Route
          path="academics/teaching-semester/:semesterId/class/:classId"
          element={
            <WithCurrentCourse>
              <CourseContext.Consumer>
                {({ currentCourse }) => (
                  <CourseSitesCard course={currentCourse} />
                )}
              </CourseContext.Consumer>
            </WithCurrentCourse>
          } 
        />
        
        <Route
          path="academics/semester/:semesterId/class/:classId"
          element={
            <WithCurrentCourse>
              <CourseContext.Consumer>
                {({ currentCourse }) => (
                  <CourseSitesCard course={currentCourse} />
                )}
              </CourseContext.Consumer>
            </WithCurrentCourse>
          }
        />
      </Routes>
    </Router>
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("courseSitesCard", react2angular(NgCourseSitesCard));
