import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components";
import currentUserState from "utils/currentUserState";

export function Messaging() {
  const loanHistoryAidYears = useSelector(
    (state) => currentUserState(state).loanHistoryAidYears
  );

  const {
    messaging: {
      enrolledPriorToFall2016,
      estimatedPaymentDisclaimer,
      inactiveLoanHistory,
    } = {},
  } = loanHistoryAidYears;

  const messagingSections = [
    enrolledPriorToFall2016,
    estimatedPaymentDisclaimer,
    inactiveLoanHistory,
  ];

  const showMessaging = messagingSections.find(
    (section) => section !== undefined
  );

  if (showMessaging) {
    return (
      <MessagingStyles>
        <MessagingSection section={enrolledPriorToFall2016} />
        <MessagingSection section={estimatedPaymentDisclaimer} />
        <MessagingSection section={inactiveLoanHistory} />
      </MessagingStyles>
    );
  }

  return null;
}

function MessagingSection({ section }) {
  if (section) {
    const { code, title, description } = section;

    return (
      <div id={code}>
        <div className="message">
          <h3>{title}</h3>
          <p style={{ marginBottom: 0 }} dangerouslySetInnerHTML={{ __html: description }}></p>
        </div>
      </div>
    );
  }

  return null;
}

MessagingSection.propTypes = {
  section: PropTypes.shape({
    code: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

const MessagingStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
