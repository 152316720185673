import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import { useRoles } from "../hooks/useRoles";
import { dispatchSlice } from "components/hooks/dispatchSlice";
import { getMyStatus } from "data/slices/myStatus";
export function WithdrawnAdmissionCard() {
  dispatchSlice(getMyStatus);
  const isWithdrawnAdmit = useRoles((roles) => roles?.withdrawnAdmit);

  if (isWithdrawnAdmit) {
    return (
      <Card data-testid="withdrawn-admission-card">
        <CardTitle>
          <h2>Withdrawn Admission</h2>
        </CardTitle>
        <CardBody>
          Your admission has been withdrawn from UC Berkeley. If you have
          outstanding bills, please navigate to{" "}
          <Link to="/billing/details">Billing Details</Link> to make payments.
        </CardBody>
      </Card>
    );
  }

  return null;
}

WithdrawnAdmissionCard.displayName = "WithdrawnAdmissionCard";
