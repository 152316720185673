import React from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { areLoaded, haveFailure } from "data/utils/statusHelpers";
import currentUserState from "utils/currentUserState";

import { CardBody } from "components/ui/Card";
import { ErrorMessage } from "components/ui/ErrorMessage";
import WidgetSubtitle from "components/ui/WidgetSubtitle";

import { TaskLinks } from "./TaskLinks";
import { TasksForYear } from "./TasksForYear";

export const Tasks = () => {
  const { finaidYearId } = useParams();

  const { canSeeCSLinks, completedTasks, errored, loaded, tasks } = useSelector(
    (state) => {
      const {
        apiLinks: { loadState: apiLinksLoadState },
        myStatus: { canSeeCSLinks: canSeeCSLinks },
      } = state;

      const {
        agreements: {
          loadState: agreementsLoadState,
          incompleteAgreements = [],
          completedAgreements = [],
        } = {},
        checklistItems: {
          loadState: checklistLoadState,
          incompleteItems = [],
          completedItems = [],
        } = {},
      } = currentUserState(state);

      const loadStates = [
        apiLinksLoadState,
        agreementsLoadState,
        checklistLoadState,
      ];
      const loaded = areLoaded(loadStates);
      const errored = haveFailure(loadStates);

      const tasks = [...incompleteAgreements, ...incompleteItems].filter(
        byFinaidYear(finaidYearId)
      );

      const completedTasks = [...completedAgreements, ...completedItems].filter(
        byFinaidYear(finaidYearId)
      );

      return {
        canSeeCSLinks,
        completedTasks,
        errored,
        loaded,
        tasks,
      };
    }
  );

  return (
    <>
      <WidgetSubtitle>Tasks</WidgetSubtitle>

      {loaded && errored && (
        <div style={{ padding: `var(--md)` }}>
          <ErrorMessage message="An error occurred while trying to retrieve your data." />
        </div>
      )}
      {loaded && !errored && (
        <>
          <TasksForYear
            completedTasks={completedTasks}
            finaidYearId={finaidYearId}
            tasks={tasks}
          />
          <CardBody data-testid="financial-tasks">
            <TaskLinks canSeeCSLinks={canSeeCSLinks} />
          </CardBody>
        </>
      )}
    </>
  );
};

const byFinaidYear = (year) => (task) =>
  task.isFinancialAid && task.aidYear === year;
