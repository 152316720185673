import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Ellipsis } from "components/ui/Ellipsis";
import { CourseListing } from "./CourseListing";
import bCourseIcon from "assets/images/icon_bcourses_256x256.svg";
import { courseShape } from "../../propTypes/courseShape";

export function Course({ course }) {
  if (course.listings) {
    return (
      <CourseStyles data-testid="course">
        {course.listings.map((listing, index) => (
          <CourseListing key={index} course={course} listing={listing} />
        ))}
      </CourseStyles>
    );
  } else {
    const { site_url, name, shortDescription } = course;
    return (
      <BCourseStyles data-testid="bcourses-course">
        <img src={bCourseIcon} width="16" height="16" />
        <div style={{ overflow: `hidden` }}>
          <CourseSiteLink name={name} siteUrl={site_url}></CourseSiteLink>
          <Ellipsis style={{ color: `var(--dusty-gray)` }}>
            {shortDescription}
          </Ellipsis>
        </div>
      </BCourseStyles>
    );
  }
}

Course.displayName = "Course";
Course.propTypes = {
  course: courseShape,
};

export const CourseSiteLink = ({ name, siteUrl }) => {
  if (name && siteUrl) {
    return (
      <a target="_blank" rel="noreferrer" href={siteUrl}>
        {name}
      </a>
    );
  }
  return null;
};
CourseSiteLink.displayName = "CourseSiteLink";
CourseSiteLink.propTypes = {
  name: PropTypes.string,
  siteUrl: PropTypes.string,
};

const CourseStyles = styled.li`
  padding: var(--md) 0;
  border-bottom: 1px solid var(--gallery);

  &:last-child {
    border-bottom: 0;
  }
`;

const BCourseStyles = styled(CourseStyles)`
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
`;
