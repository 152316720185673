import React from "react";
import Spinner from "components/ui/Spinner";

import { Card, CardTitle, CardBody } from "components/ui/Card";
import { fetchAdvisingResources } from "data/actions/advisingResources";
import { fetchAdvisingStudentData } from "data/actions/advisingStudentData";
import { fetchAdvisingStudentProfile } from "data/slices/users/advisingStudentProfile";
import { fetchAdvisingStudentAcademicProfile } from "data/slices/users/advisingStudentAcademicProfile";

import { AcademicProfileList } from "./AcademicProfileCard/AcademicProfileList";
import { Career } from "./StudentSummaryCard/Career";
import { ContactInformation } from "./StudentSummaryCard/ContactInformation";
import { DegreesConferred } from "./StudentSummaryCard/DegreesConferred";
import { DesignatedEmphases } from "./StudentSummaryCard/DesignatedEmphases";
import { Level } from "./StudentSummaryCard/Level";
import { LdapUID } from "./StudentSummaryCard/LdapUID";
import { Majors } from "./StudentSummaryCard/Majors";
import { Minors } from "./StudentSummaryCard/Minors";
import { NameAndPhoto } from "./StudentSummaryCard/NameAndPhoto";
import { StudentID } from "./StudentSummaryCard/StudentID";
import { ExpectedGraduationTermUndergraduate } from "./StudentSummaryCard/ExpectedGraduationTermUndergraduate";
import { ExpectedGraduationTermGradLaw } from "./StudentSummaryCard/ExpectedGraduationTermGradLaw";
import { AdvisingResources } from "./StudentSummaryCard/AdvisingResources";

import { useAdvisingStudent } from "components/hooks/useAdvisingStudent";
import { useSlice } from "components/hooks/useSlice";
import { loadStateForSlices } from "utils/loadStateForSlices";
import { dispatchAdvisingStudentSlice } from "components/hooks/dispatchAdvisingStudentSlice";

export function StudentSummaryCard() {
  dispatchAdvisingStudentSlice([
    fetchAdvisingStudentAcademicProfile,
    fetchAdvisingStudentProfile,
    fetchAdvisingStudentData,
    fetchAdvisingResources,
  ]);

  const studentData = useSlice("advising").studentData || {};
  const resources = useSlice("advising").resources || {};

  const { advisingStudentAcademicProfile, advisingStudentProfile } =
    useAdvisingStudent((student) => student);

  const loadState = loadStateForSlices([
    studentData,
    resources,
    advisingStudentAcademicProfile,
    advisingStudentProfile,
  ]);

  return (
    <Card data-testid="student-summary-card">
      <CardTitle>
        <h2>Student Summary</h2>
      </CardTitle>

      <CardBody>
        {loadState === "pending" && <Spinner />}
        {loadState === "success" && (
          <AcademicProfileList>
            <NameAndPhoto />
            <StudentID />
            <LdapUID />
            <Majors />
            <Minors />
            <DesignatedEmphases />
            <Career />
            <Level />
            <ExpectedGraduationTermUndergraduate />
            <ExpectedGraduationTermGradLaw />
            <DegreesConferred />
            <ContactInformation />
            <AdvisingResources />
          </AcademicProfileList>
        )}
      </CardBody>
    </Card>
  );
}

StudentSummaryCard.displayName = "StudentSummaryCard";
