import React from "react";
import PropTypes from "prop-types";

import formatCurrency from "functions/formatCurrency";
import styled from "styled-components";

const UnappliedBalanceBadge = ({ amount }) => {
  if (amount < 0) {
    const string = `${formatCurrency(Math.abs(amount))} Unapplied`;
    return (
      <UnappliedBadgeStyles data-testid="unapplied-balance-badge">
        <div className="Badge">{string}</div>
      </UnappliedBadgeStyles>
    );
  } else {
    return null;
  }
};
UnappliedBalanceBadge.displayName = "UnappliedBalanceBadge";
UnappliedBalanceBadge.propTypes = {
  amount: PropTypes.number,
};

export default UnappliedBalanceBadge;
export const UnappliedBadgeStyles = styled.div`
  .Badge {
    color: #333;
    border-radius: 3px;
    display: inline-block;
    font-size: 11px;
    font-weight: bold;
    line-height: 21px;
    height: 20px;
    background-color: var(--gallery);
    white-space: nowrap;
    padding-left: 4px;
    padding-right: 4px;
  }
`;
