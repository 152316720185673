import React from "react";
import { Card, CardTitle, CardBody } from "components/ui/Card";
import { dispatchFunction } from "components/hooks/dispatchFunction";
import { fetchMyAcademics } from "data/actions/myAcademicsActions";
import { fetchTransferCredit } from "data/actions/transferCreditActions";
import { fetchLawAwards } from "data/actions/lawAwardsActions";
import { dispatchUserSlice } from "components/hooks/dispatchUserSlice";
import { fetchUserProfile } from "data/slices/userProfile";
import { fetchAcademicProfile } from "data/slices/users/academicProfile";
import { useUser } from "components/hooks/useUser";
import { useSlice } from "components/hooks/useSlice";
import Spinner from "components/ui/Spinner";
import { BlueButton } from "components/ui/Buttons";
import { PrintIcon } from "components/ui/Icons";

import Enrollment from "components/cards/AcademicSummaryCard/Enrollment";
import { AcademicProfile } from "components/cards/AcademicSummaryCard/AcademicProfile";
import { LargeSpacer } from "components/ui/VerticalSpacers";

export function AcademicSummaryCard() {
  dispatchUserSlice([fetchUserProfile, fetchAcademicProfile]);
  dispatchFunction([fetchMyAcademics, fetchTransferCredit, fetchLawAwards]);

  const { loadState: academicsLoadState } = useSlice("myAcademics");
  const { loadState: lawAwardsLoadState } = useSlice("myLawAwards");
  const { loadState: statusLoadState } = useSlice("myStatus");
  const { loadState: userProfileLoadState } = useSlice("userProfile");
  const { loadState: transferCreditLoadState } = useSlice("myTransferCredit");
  const { loadState: academicProfileLoadState } = useUser(
    (user) => user.academicProfile || {}
  );

  const isLoaded = [
    academicsLoadState,
    academicProfileLoadState,
    lawAwardsLoadState,
    statusLoadState,
    transferCreditLoadState,
    userProfileLoadState,
  ].every((state) => state === "success");

  return (
    <Card data-testid="academic-summary-card">
      <CardTitle>
        <h2>Academic Summary</h2>
        <BlueButton onClick={() => window.print()}>
          <PrintIcon /> Print
        </BlueButton>
      </CardTitle>
      <CardBody>
        {isLoaded ? (
          <>
            <h3>Student Profile</h3>
            <AcademicProfile />
            <LargeSpacer />
            <LargeSpacer />
            <Enrollment />
          </>
        ) : (
          <Spinner />
        )}
      </CardBody>
    </Card>
  );
}
