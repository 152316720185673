import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { BILLING_VIEW_PAYMENTS_AID } from "../billingItemViews";
import formatDate from "functions/formatDate";
import DisclosureChevron from "components/../react/components/DisclosureChevron";
import styled from "styled-components";

import { fetchBillingItem } from "data/actions/billingActions";

import ItemAmount from "./ItemAmount";
import PaymentDetails from "./PaymentDetails";
import ItemUpdated from "./ItemUpdated";
import UnappliedBalanceBadge from "../Badges/UnappliedBalanceBadge";

const propTypes = {
  item: PropTypes.object,
  expanded: PropTypes.bool,
  onExpand: PropTypes.func,
  tab: PropTypes.string,
};

const DesktopView = ({ tab, item, expanded, onExpand }) => {
  return (
    <PaymentItemStyles
      data-testid="payment-item-desktop-view"
      className={`${expanded ? "BillingItem--expanded" : ""}`}
      onClick={() => onExpand()}
    >
      <PaymentItemGrid
        data-testid="payment-item-grid"
        className={`BillingItem BillingItem--payment BillingItem--desktop ${
          expanded ? "BillingItem--expanded" : ""
        }`}
        onClick={() => onExpand()}
      >
        <div className="TableColumn__posted">{formatDate(item.postedOn)}</div>
        <div className="TableColumn__description-amount">
          <div className="TableColumn__description">
            <div className="BillingItem__description">{item.description}</div>
            <div className="BillingItem__type">{item.type}</div>
          </div>
          <div className="TableColumn__amount">
            <ItemAmount amount={item.amount} />
            <ItemUpdated item={item} />
          </div>
        </div>
        <div className="TableColumn__status">
          {tab === BILLING_VIEW_PAYMENTS_AID && (
            <UnappliedBalanceBadge amount={item.balance} />
          )}
        </div>
        <div className="TableColumn__due ChargeItem__due">
          <></>
        </div>
        <div className="TableColumn__chevron">
          <DisclosureChevron expanded={expanded} onClick={() => onExpand()} />
        </div>
      </PaymentItemGrid>
      {expanded && <PaymentDetails item={item} />}
    </PaymentItemStyles>
  );
};
DesktopView.displayName = "DesktopView";
DesktopView.propTypes = propTypes;

const PaymentItem = ({ dispatch, ...props }) => {
  useEffect(() => {
    if (props.expanded) {
      dispatch(fetchBillingItem(props.item.id));
    }
  }, [props.expanded]);

  return (
    <Fragment>
      <DesktopView {...props} />
    </Fragment>
  );
};
PaymentItem.displayName = "PaymentItem";
PaymentItem.propTypes = { ...propTypes, dispatch: PropTypes.func };

export default connect()(PaymentItem);
export const PaymentItemGrid = styled.div`
  display: grid;
  grid-template-columns: 82px 3fr 1fr 120px 10px;
  column-gap: 20px;
  padding: 10px;

  .TableColumn__description-amount {
    display: flex;
    justify-content: space-between;

    .TableColumn__description {
      margin-right: 20px;
    }
  }

  .TableColumn__due {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    &.ChargeItem__due {
      flex-direction: column;
      align-items: flex-end;
      justify-content: start;
      color: var(--tundora);
      padding-right: 12px;
    }
  }

  .TableColumn__chevron {
    width: 10px;
    display: grid;
    place-items: center center;
  }

  .TableColumn__amount {
    text-align: right;
  }
`;

export const PaymentItemStyles = styled.div`
  &.BillingItem--expanded,
  &:hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  }
`;
