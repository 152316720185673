import { useUser } from "components/hooks/useUser";
import { useCanViewLink } from "./useCanViewLink";

export function useVisibleLinks() {
  const canView = useCanViewLink();

  const { loadState, links = [] } = useUser((user) => user.campusLinks) || {};

  if (canView && loadState === "success") {
    return links.filter((link) => canView(link));
  }
}
