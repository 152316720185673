import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";

const CardStyles = styled.section`
  background: var(--white);
  border: var(--silver) solid 1px;
`;

const AngularCardStyles = styled(CardStyles)`
  background: var(--white);
  border: none;
  margin-right: var(--large-spacing);
  margin-top: var(--large-spacing);

  .columns:last-child & {
    margin-right: 0;
  }

  // When a card is a child of the 'MainContent' component, it is a full-page
  // card, and should not have a right margin.
  .main & {
    margin-right: 0;
  }
`;

export const Card = ({ children, ...rest }) => {
  const reactOnly = useSelector(({ reactOnly }) => reactOnly);

  if (reactOnly) {
    return <CardStyles {...rest}>{children}</CardStyles>;
  }

  return <AngularCardStyles {...rest}>{children}</AngularCardStyles>;
};
Card.propTypes = {
  children: PropTypes.node,
};
Card.displayName = "Card";

export const CardTitle = (props) => {
  const { title, right } = props;

  if (title) {
    return (
      <CardTitleStyles>
        <h2>{title}</h2>
        {right}
      </CardTitleStyles>
    );
  }

  return <CardTitleStyles {...props} />;
};

CardTitle.displayName = "CardTitle";
CardTitle.propTypes = {
  title: PropTypes.string,
  right: PropTypes.node,
};

const CardTitleStyles = styled.header`
  background: var(--gallery);

  font-family: Arial, sans-serif;
  height: ${({ large }) => (large ? `60px` : `40px`)};
  padding: ${({ large }) =>
    large ? `var(--medium-spacing) 35px` : `0 var(--large-spacing)`};

  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    font-weight: normal;
    font-size: ${({ large }) => (large ? `25px` : `18px`)};
    margin: 0;

    color: var(--mine-shaft);

    span {
      color: var(--dove-gray);
      font-size: 13px;
      text-transform: uppercase;
    }
  }
`;
CardTitle.displayName = "CardTitle";

export const CardBody = styled.div`
  padding: ${({ large }) => (large ? `25px 35px` : `var(--large-spacing)`)};
  ${({ large }) => large && `font-size: 18px;`}
  ${({ large }) => large && `h3 { font-size: 20px; }`}

  > p:first-child {
    margin-top: 0;
  }
`;
CardBody.displayName = "CardBody";

export function RightArrowLink({ to, children, ...rest }) {
  return (
    <Link to={to} {...rest}>
      <strong style={{ fontSize: `14px` }}>
        {children} <i className="fa fa-arrow-right" />
      </strong>
    </Link>
  );
}
RightArrowLink.displayName = "RightArrowLink";
RightArrowLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
};
