import styled from "styled-components";

import Ellipsis from "components/ui/Ellipsis";

export const DescContainer = styled(Ellipsis)`
  color: var(--dusty-gray);
  padding-top: var(--tiny-spacing);
  margin-top: -4px;
  font-size: 12px;
  padding-bottom: var(--small-spacing);
  padding-left: var(--large-spacing);
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  padding-right: 0px;
  padding-top: 0px;
  padding-left: 24px;
`;

export const LinkContainer = styled.div`
  max-width: 320px;
  padding-top: ${(props) =>
    props.subclass && props.subclass.shortDescription ? "0px" : "6px"};
  padding-left: 8px;
  padding-bottom: 8px;
`;

export const ShortDescriptionContainer = styled(Ellipsis)`
  color: var(--dusty-gray);
  margin-top: -4px;
  font-size: 12px;
`;

export const CategoryTitle = styled.h3`
  background: var(--dusty-gray);
  color: var(--white);
  font-size: 12px;
  font-weight: normal;
  margin: 0;
  padding: var(--tiny-spacing) var(--large-spacing);
`;
