import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { Card, CardBody, CardTitle } from "components/ui/Card";

import ServiceAlertForm from "./ServiceAlertForm";
import PreviewBox from "./PreviewBox";
import ServiceAlert from "./ServiceAlert";

import { validationSchema } from "./serviceAlert.module.js";

import postHeaders from "utils/postHeaders";
import mapStateToCSRF from "utils/mapStateToCSRF";

export default connect(mapStateToCSRF)(NewServiceAlert);

function NewServiceAlert({ csrfToken }) {
  const navigate = useNavigate();

  const createServiceAlert = (values) => {
    return fetch("/api/service_alerts/", {
      method: "POST",
      headers: postHeaders(csrfToken),
      body: JSON.stringify({
        service_alert: values,
      }),
    });
  };

  const onSubmit = (values, { setErrors }) => {
    createServiceAlert(values).then((res) => {
      if (res.ok) {
        alert("Service alert created successfully");
        navigate(`/service_alerts`);
      } else {
        res.json().then((errors) => {
          setErrors(errors);
        });
      }
    });
  };

  return (
    <Card data-testid="new-service-alert-card">
      <CardTitle>
        <h2>New Service Alert</h2>
      </CardTitle>
      <CardBody>
        <Formik
          initialValues={{
            title: "",
            body: "",
            publication_date: "",
            banner: false,
            splash: false,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <div style={{ display: `flex`, gap: `15px` }}>
              <div style={{ flex: `1` }}>
                <ServiceAlertForm formik={formik} />
              </div>
              <div style={{ flex: `1` }}>
                <PreviewBox>
                  <ServiceAlert {...formik.values} />
                </PreviewBox>
              </div>
            </div>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
}

NewServiceAlert.displayName = "NewServiceAlert";
NewServiceAlert.propTypes = {
  csrfToken: PropTypes.string,
};
