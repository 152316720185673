import React from "react";
import PropTypes from "prop-types";
import WithAPILink from "components/ui/WithAPILink";

export function SectionRow({ section }) {
  const {
    course_code,
    units,
    grading: {
      grade,
      gradingBasis,
      gradingLapseDeadlineDisplay,
      gradingLapseDeadline,
    } = {},
  } = section;

  return (
    <>
      <tr>
        <td>{course_code}</td>
        <td style={{ paddingRight: `var(--lg)`, textAlign: `right` }}>
          {units.toFixed(1)}
        </td>
        <td>{grade}</td>
      </tr>

      <FrozenLapsedRow
        gradingLapseDeadlineDisplay={gradingLapseDeadlineDisplay}
        gradingLapseDeadline={gradingLapseDeadline}
        gradingBasis={gradingBasis}
      />
    </>
  );
}

SectionRow.propTypes = {
  section: PropTypes.shape({
    course_code: PropTypes.string,
    units: PropTypes.number,
    grading: PropTypes.shape({
      grade: PropTypes.string,
    }),
  }),
};

const FrozenLapsedRow = ({
  gradingBasis,
  gradingLapseDeadline,
  gradingLapseDeadlineDisplay,
}) => {
  if (gradingBasis === "FRZ") {
    return (
      <tr>
        <td></td>
        <td></td>
        <td style={{ paddingBottom: `var(--md)` }}>
          <WithAPILink urlID="UC_CX_ACAD_GUIDE_GRADES" text="Frozen" />
        </td>
      </tr>
    );
  }

  if (gradingLapseDeadlineDisplay && gradingLapseDeadline) {
    return (
      <tr>
        <td colSpan="3" style={{ paddingBottom: `var(--md)` }}>
          <WithAPILink
            urlID="UC_CX_ACAD_GUIDE_GRADES"
            text={`Student Completion Deadline is 30 days before ${gradingLapseDeadline}`}
          />
        </td>
      </tr>
    );
  }

  return null;
};

FrozenLapsedRow.propTypes = {
  gradingBasis: PropTypes.string,
  gradingLapseDeadline: PropTypes.string,
  gradingLapseDeadlineDisplay: PropTypes.bool,
};
