import PropTypes from "prop-types";

export const apiLinkShape = PropTypes.shape({
  comments: PropTypes.string,
  description: PropTypes.string,
  displayDescription: PropTypes.bool,
  isCampusSolutionsLink: PropTypes.bool,
  name: PropTypes.string,
  showNewWindow: PropTypes.bool,
  title: PropTypes.string,
  ucFrom: PropTypes.string,
  ucFromLink: PropTypes.string,
  ucFromText: PropTypes.string,
  ucUpdateCache: PropTypes.bool,
  url: PropTypes.string,
  urlId: PropTypes.string,
});
