import {
  GET_GROUPS_START,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAILURE,
} from "../action-types";
const url = "/api/my/groups";

export default function getGroups() {
  return (dispatch, getState) => {
    const { myGroups } = getState();
    if (myGroups.loadState === "success") {
      return new Promise((resolve, _reject) => resolve(myGroups));
    } else if (myGroups.loadState !== "pending") {
      dispatch({ type: GET_GROUPS_START });
      return fetch(url, { method: "GET" })
        .then((response) => response.json())
        .then((data) => {
          dispatch({
            type: GET_GROUPS_SUCCESS,
            value: data,
          });
        })
        .catch((err) => {
          dispatch({ type: GET_GROUPS_FAILURE, err });
        });
    }
  };
}
