import React from "react";
import { useSelector } from "react-redux";
import currentUserState from "utils/currentUserState";
import { CourseCategory } from "./CourseCategory";

export function Courses() {
  const { courses } = useSelector((state) => {
    const { courses: { classes = [], gradingInProgressClasses = [] } = {} } =
      currentUserState(state);

    return {
      courses:
        process.env.GRADING_IN_PROGRESS_CLASSES === "true"
          ? gradingInProgressClasses
          : classes,
    };
  });

  const { bCourses = [], campusCourses = [] } = courses.reduce(
    splitByEmitter,
    {}
  );
  const {
    student = [],
    instructor = [],
    other = [],
  } = campusCourses.map(appendSubclasses(bCourses)).reduce(splitByRole, {});

  // Add non-campus courses that didn't have an associated campus course to the "Other" category
  bCourses
    .filter((course) => course.courses.length === 0)
    .forEach((course) => other.push(course));

  const onlyStudentInstructor =
    !other.length && !(student.length && instructor.length);

  return (
    <>
      <WaitlistDisclaimerMessage />

      <CourseCategory
        courses={student}
        title="Enrollments"
        onlyStudentInstructor={onlyStudentInstructor}
      />

      <CourseCategory
        courses={instructor}
        title="Teaching"
        onlyStudentInstructor={onlyStudentInstructor}
      />

      <CourseCategory
        courses={other}
        title="Other Site Memberships"
        onlyStudentInstructor={onlyStudentInstructor}
      />
    </>
  );
}

export function WaitlistDisclaimerMessage() {
  const { waitlistDisclaimerMessage } = useSelector((state) => {
    const { courses: { waitlistDisclaimerMessage } = {} } =
      currentUserState(state);

    return {
      waitlistDisclaimerMessage,
    };
  });

  if (waitlistDisclaimerMessage) {
    return (
      <div
        data-testid="waitlist-disclaimer-notice"
        style={{
          color: "var(--dusty-gray)",
          fontSize: "11px",
          padding: "15px",
        }}
      >
        {waitlistDisclaimerMessage.descrlong}
      </div>
    );
  }
  return null;
}

// Split courses into two categories based on the data source.
function splitByEmitter(acc, current) {
  if (Object.keys(acc).length === 0) {
    acc.campusCourses = [];
    acc.bCourses = [];
  }

  if (current.emitter === "Campus") {
    acc.campusCourses.push(current);
  } else {
    acc.bCourses.push(current);
  }

  return acc;
}

// Add subclasses to the campus courses from the non-campus courses based on the "listing ids"
// in the campus course that match the "course ids" in the non-campus courses
function appendSubclasses(nonCampus) {
  return function (course) {
    const courseListingIds = course.listings.map((listing) => listing.id);
    return {
      ...course,
      subclasses: nonCampus.filter((nonCampusCourse) => {
        const matchingIds = nonCampusCourse.courses.map((course) => course.id);
        return courseListingIds.find((listingId) =>
          matchingIds.includes(listingId)
        );
      }),
    };
  };
}

// Split the courses according to role, with a bit of data normalization protection
function splitByRole(acc, current) {
  if (Object.keys(acc).length === 0) {
    acc.student = [];
    acc.instructor = [];
    acc.other = [];
  }

  const role = current.role.toLowerCase() || "";

  if (["instructor", "student"].includes(role)) {
    acc[role].push(current);
  } else {
    acc.other.push(current);
  }

  return acc;
}
