import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import { CumulativeLoanDebtCard } from "../cards/CumulativeLoanDebtCard";

function NgCumulativeLoanDebtCard() {
  return (
    <ReduxProvider>
      <Router>
        <Routes>
          <Route
            path="/finances/cumulative_loan_debt"
            element={<CumulativeLoanDebtCard />}
          />
        </Routes>
      </Router>
    </ReduxProvider>
  );
}

angular
  .module("calcentral.react")
  .component("cumulativeLoanDebtCard", react2angular(NgCumulativeLoanDebtCard));
