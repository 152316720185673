import React from "react";
import SemestersContext from "./contexts/SemestersContext";
import { Link } from "react-router-dom";

export default function SemesterLink() {
  return (
    <SemestersContext.Consumer>
      {({ currentSemester }) => {
        if (currentSemester) {
          return (
            <Link to={`/academics/semester/${currentSemester.slug}`}>
              {currentSemester.name}
            </Link>
          );
        }

        return null;
      }}
    </SemestersContext.Consumer>
  );
}
SemesterLink.displayName = "SemesterLink";
