import PropTypes from "prop-types";
import { useSlice } from "components/hooks/useSlice";

// Given a urlID, returns a API link (if found) from the Redux store in
// render-props style to the children.
export default function WithAPILinkData({ children, urlID }) {
  const link = useSlice("apiLinks", (apiLinks) => apiLinks[urlID]);

  if (link) {
    return children(link);
  }

  return null;
}

WithAPILinkData.propTypes = {
  children: PropTypes.func,
  urlID: PropTypes.string,
};
