import React from "react";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import { LoanSummaryByAidYearCard } from "components/cards/LoanSummaryByAidYearCard";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const NgLoanSummaryByAidYearCard = () => (
  <ReduxProvider>
    <Router>
      <Routes>
        <Route path="/finances/loan_summary_aid_year" element={<LoanSummaryByAidYearCard />} />
      </Routes>
    </Router>
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("loanSummaryByAidYearCard", react2angular(NgLoanSummaryByAidYearCard));
 