import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { CardBody } from "components/ui/Card";
import { ListBullets } from "components/ui/Lists";

export const NoClasses = () => {
  const { roles, hasAcademicsTab, currentTerm } = useSelector(
    ({
      myStatus: { roles = {}, hasAcademicsTab } = {},
      myClasses: { currentTerm } = {},
    }) => {
      return {
        roles,
        hasAcademicsTab,
        currentTerm,
      };
    }
  );

  return (
    <CardBody>
      {roles.student && !roles.faculty && (
        <div>
          You are not enrolled in any UC Berkeley classes for{" "}
          <span> {currentTerm}</span>
        </div>
      )}
      {!roles.student && roles.faculty && hasAcademicsTab && (
        <div>
          <p>You have no classes assigned to you this semester.</p>
          <ListBullets>
            <ul>
              <li>
                Check <a href="/academics">My Academics</a> to see past or
                upcoming semesters.
              </li>
              <li>
                If you have any questions about your teaching assignments,
                please contact your academic department.
              </li>
            </ul>
          </ListBullets>
        </div>
      )}

      {roles.student && roles.faculty && hasAcademicsTab && (
        <div>
          <ListBullets>
            <ul>
              <li>
                Check <a href="/academics">My Academics</a> to see your academic
                history or upcoming semesters.
              </li>
              <li>
                If you still have questions, contact the{" "}
                <a href="http://registrar.berkeley.edu/">
                  Office of the Registrar
                </a>{" "}
                or visit{" "}
                <a href="http://studentcentral.berkeley.edu/">
                  Cal Student Central
                </a>
                .
              </li>
              <li>
                If you have any questions about your teaching assignments,
                please contact your academic department.
              </li>
            </ul>
          </ListBullets>
        </div>
      )}

      {!roles.student && !roles.faculty && (
        <div>
          You are not enrolled in any UC Berkeley classes for{" "}
          <span>{currentTerm}</span>.
        </div>
      )}
    </CardBody>
  );
};

NoClasses.displayName = "NoClasses";
NoClasses.propTypes = {
  roles: PropTypes.object,
  hasAcademicsTab: PropTypes.bool,
  currentTerm: PropTypes.string,
};
