import React, { useState } from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import { haveFailure, areLoaded } from "data/utils/statusHelpers";

import useFocus from "components/useFocus";
import { ErrorMessage } from "components/ui/ErrorMessage";
import NoMessages from "components/cards/NotificationsCard/NoMessages";
import WidgetSubtitle from "components/ui/WidgetSubtitle";
import MessagesBySource from "components/cards/NotificationsCard/MessagesBySource";

import {
  groupByDate,
  byStatusDateTimeAsc,
  dateAndTypeSourcedMessages,
  filterByAidYear,
} from "components/cards/NotificationsCard/notifications.module";

export const Messages = ({ year }) => {
  const { errored, loaded, notifications } = useSelector((state) => {
    const {
      myWebMessages: {
        loadState: webMessagesLoadState,
        universityNotifications: { notifications = [] } = {},
      } = {},
      myStatus: { loadState: statusLoadState } = {},
    } = state;

    const loadStates = [statusLoadState, webMessagesLoadState];
    const loaded = areLoaded(loadStates);
    const errored = haveFailure(loadStates);

    return { errored, loaded, notifications };
  });

  const [expandedItem, setExpandedItem] = useState("");

  // useFocus is used to track whether the user is interacting with the card or
  // has clicked somewhere else on the page
  const [node, hasFocus] = useFocus();

  const aidYearNotifications = notifications
    .filter(filterByAidYear(year))
    .sort(byStatusDateTimeAsc)
    .reduce(groupByDate, [])
    .map(dateAndTypeSourcedMessages);

  if (loaded) {
    return (
      <div ref={node}>
        <WidgetSubtitle>Messages</WidgetSubtitle>
        {errored && (
          <div style={{ padding: `var(--md)` }}>
            <ErrorMessage message="An error occurred while trying to retrieve your data." />
          </div>
        )}
        {!errored && aidYearNotifications.length > 0 ? (
          <div style={{ padding: `0 15px` }}>
            <MessagesBySource
              groupedNotifications={aidYearNotifications}
              selectedSource={""}
              setSelectedSource={() => {}}
              expandedItem={expandedItem}
              setExpandedItem={setExpandedItem}
              hasFocus={hasFocus}
            />
          </div>
        ) : (
          !errored && <NoMessages year={year} />
        )}
      </div>
    );
  }

  return null;
};
Messages.displayName = "FinancialAidMessages";
Messages.propTypes = {
  year: PropTypes.string,
};
