import React from "react";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import { RosterCard } from "components/cards/RosterCard";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { BreakpointProvider } from "react-socks";

const NgRosterCard = () => (
  <BreakpointProvider>
    <ReduxProvider>
      <Router>
        <Routes>
          <Route
            path="/academics/teaching-semester/:semesterId/class/:classId/roster"
            element={<RosterCard />}
          />
        </Routes>
      </Router>
    </ReduxProvider>
  </BreakpointProvider>
);

angular
  .module("calcentral.react")
  .component("rosterCard", react2angular(NgRosterCard));
