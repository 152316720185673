import React, { useState } from "react";
import PropTypes from "prop-types";
import { fetchFinancialAidSummary } from "../../data/slices/users/financialAidSummary";
import FinancialAidYearContext from "../contexts/FinancialAidYearContext";
import { useFinaidYearId } from "components/hooks/useFinaidYearId";
import { useUser } from "components/hooks/useUser";
import { dispatchUserSlice } from "components/hooks/dispatchUserSlice";

export default function WithFinaidYears({ children }) {
  dispatchUserSlice(fetchFinancialAidSummary);

  const financialAidSummary = useUser(
    ({ financialAidSummary = {} }) => financialAidSummary
  );

  const {
    loadState,
    financialAidSummary: { aidYears = [], aid: aidYearSummaries = {} } = {},
  } = financialAidSummary;

  if (loadState === "success") {
    return (
      <ManageCurrentAidYear
        loadState={loadState}
        aidYears={aidYears}
        aidYearSummaries={aidYearSummaries}
      >
        {children}
      </ManageCurrentAidYear>
    );
  }

  return (
    <FinancialAidYearContext.Provider
      value={{
        loadState,
        aidYears,
        aidYearSummaries,
      }}
    >
      {children}
    </FinancialAidYearContext.Provider>
  );
}

WithFinaidYears.propTypes = {
  children: PropTypes.node,
};

function ManageCurrentAidYear({
  aidYears,
  aidYearSummaries,
  loadState,
  children,
}) {
  const finaidYearId = useFinaidYearId();
  const onSummaryPage = !finaidYearId;
  const defaultAidYear =
    aidYears.find((item) => item.defaultAidYear) || aidYears[0];

  const [aidYear, setAidYear] = useState(
    onSummaryPage
      ? defaultAidYear
      : aidYears.find((item) => item.id === finaidYearId)
  );

  const aidYearSummary =
    onSummaryPage && aidYears.length > 0
      ? aidYearSummaries[defaultAidYear.id]
      : aidYearSummaries[finaidYearId];

  return (
    <FinancialAidYearContext.Provider
      value={{
        loadState,
        onSummaryPage,
        aidYears,
        aidYear,
        setAidYear,
        aidYearSummaries,
        aidYearSummary,
      }}
    >
      {children}
    </FinancialAidYearContext.Provider>
  );
}

ManageCurrentAidYear.propTypes = {
  children: PropTypes.node,
  aidYears: PropTypes.array,
  aidYearSummaries: PropTypes.object,
  loadState: PropTypes.string,
};
