import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import Spinner from "components/ui/Spinner";
import { getMyStatus } from "data/slices/myStatus";
import { fetchMyAcademics } from "data/actions/myAcademicsActions";
import { dispatchSlice } from "components/hooks/dispatchSlice";
import { TeachingCoursesBySemester } from "./TeachingCoursesCard/TeachingCoursesBySemester";
import { BaseButton } from "../ui/Buttons";
import { ErrorMessage } from "formik";

export default function TeachingCoursesCard() {
  dispatchSlice(getMyStatus);
  dispatchSlice(fetchMyAcademics);

  const [expanded, setExpanded] = useState(false);
  const { error, loadState, teachingSemesters, delegateActingAsUid } =
    useSelector((state) => {
      const {
        myAcademics: {
          isLoading: myAcademicsLoading = true,
          error: myAcademicsError = null,
          loadState,
          teachingSemesters = [],
        } = {},
        myStatus: {
          isLoading: myStatusLoading = true,
          error: myStatusError = null,
          delegateActingAsUid,
        } = {},
      } = state;

      const error = myAcademicsError || myStatusError;
      const isLoading = myAcademicsLoading || myStatusLoading;

      return {
        error,
        isLoading,
        loadState,
        teachingSemesters,
        delegateActingAsUid,
      };
    });

  const currentAndFutureSemesters = teachingSemesters.filter((semester) =>
    ["current", "future"].includes(semester.timeBucket)
  );

  if (!delegateActingAsUid && teachingSemesters.length > 0) {
    return (
      <Card data-testid="teaching-courses-card">
        <CardTitle>
          <h2>Teaching</h2>
        </CardTitle>

        <CardBody>
          {loadState === "pending" && <Spinner />}

          {loadState === "failure" && (
            <ErrorMessage message={error.statusText} />
          )}

          {loadState === "success" ? (
            expanded ? (
              teachingSemesters.map((semester) => (
                <div key={semester.termId} style={{ marginBottom: "20px" }}>
                  <TeachingCoursesBySemester
                    semester={semester}
                  ></TeachingCoursesBySemester>
                </div>
              ))
            ) : (
              currentAndFutureSemesters.map((semester) => (
                <div key={semester.termId} style={{ marginBottom: "20px" }}>
                  <TeachingCoursesBySemester
                    semester={semester}
                  ></TeachingCoursesBySemester>
                </div>
              ))
            )
          ) : (
            <div>Loading</div>
          )}

          <div style={{ textAlign: "center", marginBottom: "10px" }}>
            <BaseButton
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              {expanded ? "Show Less" : "Show More"}
            </BaseButton>
          </div>
        </CardBody>
      </Card>
    );
  }
  return null;
}

TeachingCoursesCard.displayName = "TeachingCoursesCard";
