import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ButtonAsLink from "components/ui/ButtonAsLink";

const StandingSubTitle = styled.span`
  font-size: 11px;
`;

const CurrentStanding = ({ learnMoreMessage, standing }) => {
  const [showLearnMore, setShowLearnMore] = useState(false);

  if (standing) {
    return (
      <tbody>
        <tr>
          <td>
            {standing.statusDescription}
            <StandingSubTitle>
              {" "}
              (Updated {standing.effectiveDateFormatted})
            </StandingSubTitle>
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <div>
              <StandingSubTitle>{standing.actionDescription}</StandingSubTitle>
            </div>
            {standing.statusCode !== "GST" && (
              <StandingSubTitle>
                Please consult with your college advisor for guidance.
              </StandingSubTitle>
            )}
            <StandingSubTitle>
              {showLearnMore === false && (
                <ButtonAsLink onClick={() => setShowLearnMore(true)}>
                  Learn More
                </ButtonAsLink>
              )}
              {showLearnMore && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: learnMoreMessage,
                  }}
                />
              )}
            </StandingSubTitle>
          </td>
        </tr>
      </tbody>
    );
  } else {
    return null;
  }
};

CurrentStanding.displayName = "CurrentStanding";
CurrentStanding.propTypes = {
  learnMoreMessage: PropTypes.string,
  standing: PropTypes.object,
};

export default CurrentStanding;
