import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardTitle, CardBody } from "components/ui/Card";
import Spinner from "components/ui/Spinner";
import { dispatchUserSlice } from "components/hooks/dispatchUserSlice";
import { fetchLoanHistoryCumulative } from "data/slices/users/loanHistoryCumulative";
import currentUserState from "utils/currentUserState";
import { Messaging } from "./CumulativeLoanDebtCard/Messaging";
import { Resources } from "./CumulativeLoanDebtCard/Resources";
import { Glossary } from "./CumulativeLoanDebtCard/Glossary";
import { CumulativeLoansOverview } from "./CumulativeLoanDebtCard/CumulativeLoansOverview";

export function CumulativeLoanDebtCard() {
  dispatchUserSlice(fetchLoanHistoryCumulative);

  const { loadState } = useSelector(
    (state) => currentUserState(state).loanHistoryCumulative
  );

  return (
    <Card>
      <CardTitle>
        <h2>Cumulative Loan Debt</h2>
        <strong style={{ fontSize: `14px` }}>
          <Link to="/finances/loan_summary_aid_year">
            Loan Summary by Aid Year <i className="fa fa-arrow-right" />
          </Link>
        </strong>
      </CardTitle>
      <CardBody>
        {loadState === "pending" && <Spinner />}
        {loadState === "success" && (
          <VerticallyGapped>
            <CumulativeLoansOverview />
            <Messaging />
            <Resources />
            <Glossary />
          </VerticallyGapped>
        )}
      </CardBody>
    </Card>
  );
}

const VerticallyGapped = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
