import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import Spinner from "components/ui/Spinner";

import { fetchAdvisingAcademicStandings } from "data/slices/users/advisingAcademicStandings";
import { fetchAdvisingHolds } from "data/slices/users/advisingHolds";
import { fetchAdvisingStudent } from "data/slices/users/advisingStudent";
import { fetchAdvisingStudentProfile } from "data/slices/users/advisingStudentProfile";
import { fetchAdvisingTermRegistrations } from "data/slices/users/advisingTermRegistrations";
import { getMyStatus } from "data/slices/myStatus";
import advisingStudentState from "utils/advisingStudentState";

import { areLoaded } from "data/utils/statusHelpers";

import AcademicStandings, { standingAlertPresent } from "./StatusAndHoldsCard/AcademicStandings";
import TermRegistrationStatuses from "./StatusAndHoldsCard/TermRegistrationStatuses";
import Residency from "./StatusAndHoldsCard/Residency";
import { Holds } from "./StatusAndHoldsCard/Holds";
import { VisaStatus } from "./StatusAndHoldsCard/VisaStatus";

const displayStatusAndHoldsCard = (
  currentUserIsDelegate,
  currentStandings,
  holds,
  roleIsStudent,
  roleIsReleasedAdmit,
  termRegistrations
) => {
  const hasHolds = holds.length > 0;
  const hasTermRegistrations = termRegistrations.length > 0;
  const hasStandingAlert = standingAlertPresent(currentStandings);
  const isReleasedAdmit = !roleIsStudent && roleIsReleasedAdmit;

  return (
    !currentUserIsDelegate &&
    (hasHolds || hasTermRegistrations || hasStandingAlert || isReleasedAdmit)
  );
};

const advisingStatusAndHoldsSelectorFactory = (uid) => {
  const userUID = uid;

  return (state) => {
    const { myStatus: { isDelegateUser: currentUserIsDelegate } = {} } = state;

    const {
      advisingStudent: {
        loadState: advisingStudentLoadState,
        academicRoles: studentAcademicRoles = {},
        residency: { residency = {} } = {},
      } = {},
      advisingStudentProfile: {
        roles: studentRoles = {}
      } = {},
      advisingTermRegistrations: termRegistrationsData = {},
      advisingAcademicStandings: currentStandingsData = {},
      advisingHolds: holdsData = {},
    } = advisingStudentState(state, userUID) || {};

    const { student: roleIsStudent, releasedAdmit: roleIsReleasedAdmit } =
      studentRoles;

    const residencyData = {
      loadState: advisingStudentLoadState,
      residency: residency,
    };

    const {
      current: {
        ugrd: studentIsCurrentUndergraduate,
        ugrdNonDegree: studentIsCurrentUndergraduateNonDegreeSeeker,
      } = {},
    } = studentAcademicRoles || {};

    const { loadState: currentStandingsLoadState, currentStandings = [] } =
      currentStandingsData || {};

    const {
      loadState: termRegistrationsLoadState,
      termRegistrations: termRegistrations = [],
    } = termRegistrationsData || {};

    const { loadState: holdLoadState, feed: { holds = [] } = {} } =
      holdsData || {};

    const { loadState: residencyLoadState } = residencyData || {};

    const loadStates = [
      advisingStudentLoadState,
      currentStandingsLoadState,
      holdLoadState,
      residencyLoadState,
      termRegistrationsLoadState,
    ];

    const displayCard = displayStatusAndHoldsCard(
      currentUserIsDelegate,
      currentStandings,
      holds,
      roleIsStudent,
      roleIsReleasedAdmit,
      termRegistrations
    );

    return {
      currentStandingsData,
      displayCard,
      holdsData,
      loadStates,
      residencyData,
      studentAcademicRoles,
      studentIsCurrentUndergraduate,
      studentIsCurrentUndergraduateNonDegreeSeeker,
      studentRoles,
      termRegistrationsData,
    };
  };
};

const AdvisorStatusAndHoldsCard = () => {
  let { uid } = useParams();

  const {
    currentStandingsData,
    displayCard,
    holdsData,
    loadStates,
    residencyData,
    studentAcademicRoles,
    studentIsCurrentUndergraduate,
    studentIsCurrentUndergraduateNonDegreeSeeker,
    studentRoles,
    termRegistrationsData,
  } = useSelector(advisingStatusAndHoldsSelectorFactory(uid));

  const dispatch = useDispatch();

  useEffect(() => {
    if (uid) {
      dispatch(getMyStatus());
      dispatch(fetchAdvisingAcademicStandings(uid));
      dispatch(fetchAdvisingHolds(uid));
      dispatch(fetchAdvisingTermRegistrations(uid));
      dispatch(fetchAdvisingStudent(uid));
      dispatch(fetchAdvisingStudentProfile(uid))
    }
  }, [dispatch, uid]);

  const requestsFinished = areLoaded(loadStates);

  if (displayCard) {
    return (
      <Card data-testid="advisor-status-and-holds-card">
        <CardTitle>
          <h2>Status and Holds</h2>
        </CardTitle>
        <CardBody>
          {!requestsFinished && <Spinner />}
          {requestsFinished && (
            <>
              {studentIsCurrentUndergraduate &&
                !studentIsCurrentUndergraduateNonDegreeSeeker && (
                  <AcademicStandings standingsData={currentStandingsData} />
                )}
              <TermRegistrationStatuses
                termRegistrationsData={termRegistrationsData}
              />
              <Residency
                studentRoles={studentRoles}
                studentAcademicRoles={studentAcademicRoles}
                residencyData={residencyData}
              />
              <VisaStatus />
              <Holds holdsData={holdsData} />
            </>
          )}
        </CardBody>
      </Card>
    );
  } else {
    return null;
  }
};

export default AdvisorStatusAndHoldsCard;
