import styled from "styled-components";

import pictureUgrd from "assets/images/sir/ugrad_olufemio_ogundele_100x140.jpg";
import pictureGrad from "assets/images/sir/grad_lisa_g_bedolla_100x140.jpg";
import pictureHaas from "assets/images/sir/haas_ann_harrison_100x140.jpg";
import pictureLaw from "assets/images/sir/law_chemerinsky_erwin_100x140.jpg";

const portraits = {
  grad: pictureGrad,
  haasgrad: pictureHaas,
  law: pictureLaw,
  ugrad: pictureUgrd,
};

export const HeaderPortrait = styled.div`
  ${({ $picture }) =>
    `background-image: url('${
      portraits[$picture.replace("cc-widget-sir-picture-", "")]
    }');`}

  background-size: contain;
  border: 4px solid var(--white);
  flex-shrink: 0;
  height: 100px;
  margin: 6px 10px 6px 0;
  width: 74px;
  z-index: 1;
`;
