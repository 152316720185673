import React from "react";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import { MyClassesCard } from "components/cards/MyClassesCard";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const NgMyClassesCard = () => (
  <ReduxProvider>
    <Router>
      <Routes>
        <Route path="/dashboard" element={<MyClassesCard />} />
      </Routes>
    </Router>
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("myClassesCard", react2angular(NgMyClassesCard));
