import React from "react";
import PropTypes from "prop-types";

import { differenceInDays, formatDistanceStrict, parseISO } from "date-fns";

import styled from "styled-components";
const ItemUpdated = ({ item }) => {
  if (item.updated_on === item.posted_on) {
    return null;
  }

  const date = parseISO(item.updated_on);
  const diff = Math.abs(differenceInDays(date, new Date()));

  if (diff === 0) {
    return <ItemUpdate data-testid="item-update">Updated today</ItemUpdate>;
  }

  if (diff > 0 && diff <= 30) {
    const distance = formatDistanceStrict(new Date(), date, { unit: "day" });
    return <ItemUpdate data-testid="item-update">{`Updated ${distance} ago`}</ItemUpdate>;
  }

  if (diff > 30) {
    return null;
  }
};
ItemUpdated.displayName = "ItemUpdated";
ItemUpdated.propTypes = {
  item: PropTypes.object,
};

export default ItemUpdated;
export const ItemUpdate = styled.div`
  color: var(--dove-gray);
`;
