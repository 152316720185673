import React from "react";
import PropTypes from "prop-types";
import { subclassShape } from "components/propTypes/subclassShape";

import styled from "styled-components";
import { UnstyledList } from "components/ui/Lists";
import { Ellipsis } from "components/ui/Ellipsis";
import bCourseIcon from "assets/images/icon_bcourses_256x256.svg";

import { CourseSiteLink } from "./Course";

export function SubClassses({ subclasses }) {
  if (subclasses.length) {
    return (
      <UnstyledList style={{ marginLeft: `var(--sm)`, marginTop: `var(--sm)` }}>
        {subclasses.map((subclass, index) => (
          <SubClass key={index} subclass={subclass} />
        ))}
      </UnstyledList>
    );
  }

  return null;
}

SubClassses.displayName = "SubClassses";
SubClassses.propTypes = {
  subclasses: PropTypes.arrayOf(subclassShape),
};

const SubClassStyles = styled.li`
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;

  padding: ${({ asCourse }) => (asCourse ? `10px 0` : `0`)};
`;

export function SubClass({ subclass }) {
  const { name, shortDescription, site_url } = subclass;

  return (
    <SubClassStyles>
      <img src={bCourseIcon} width="16" height="16" />

      <div style={{ overflow: `hidden` }}>
        <CourseSiteLink name={name} siteUrl={site_url}></CourseSiteLink>
        <Ellipsis style={{ color: `var(--dusty-gray)` }}>
          {shortDescription}
        </Ellipsis>
      </div>
    </SubClassStyles>
  );
}

SubClass.displayName = "SubClass";
SubClass.propTypes = {
  subclass: subclassShape,
};
