import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  ChevronCircleIcon,
  Disclosure,
  DisclosureContainer,
  DisclosureButton,
  DisclosurePanel,
} from "components/ui/ChevronDisclosure";

import StatusDisclosure from "./StatusDisclosure";
import RegistrationStatusIcon from "components/RegistrationStatusIcon";

const StatusItem = ({ status = {} }) => {
  const { message, severity, detailedMessageHTML } = status || {};

  if (message === null) {
    return null;
  }

  if (detailedMessageHTML === null || detailedMessageHTML === "") {
    return (
      <div className="StatusItem">
        <RegistrationStatusIcon severity={severity} />
        {message}
      </div>
    );
  }

  const [isOpen, setOpen] = React.useState(false);

  return (
    <Disclosure isOpen={isOpen} onChange={() => setOpen(!isOpen)}>
      <StatusDisclosureContainer data-testid="status-item">
        {({ hoverActive, setHoverActive }) => (
          <>
            <StatusDisclosureButton setHoverActive={setHoverActive}>
              <div className="header">
                <div className="title">
                  <RegistrationStatusIcon severity={severity} />
                  {message}
                </div>
                <div className="chevron">
                  <ChevronCircleIcon $hoverActive={hoverActive} />
                </div>
              </div>
            </StatusDisclosureButton>
            <DisclosurePanel>
              <StatusDisclosure>
                {typeof detailedMessageHTML === "object" ? (
                  detailedMessageHTML
                ) : (
                  <div
                    dangerouslySetInnerHTML={{ __html: detailedMessageHTML }}
                  />
                )}
              </StatusDisclosure>
            </DisclosurePanel>
          </>
        )}
      </StatusDisclosureContainer>
    </Disclosure>
  );
};

StatusItem.displayName = "StatusItem";
StatusItem.propTypes = {
  status: PropTypes.object,
};

export default StatusItem;

const StatusDisclosureContainer = styled(DisclosureContainer)`
  border-bottom: 1px solid var(--gallery);
  margin: var(--sm) 0;
`;

const StatusDisclosureButton = styled(DisclosureButton)`
  width: 100%;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  .header {
    display: grid;
    div:nth-child(2) {
      text-align: right;
    }
    grid-template-columns: 2fr 10%;
    line-height: 25px;

    .title {
      text-align: left;
    }
  }

  .chevron {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;
