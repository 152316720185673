import React from "react";
import PropTypes from "prop-types";

import { Card, CardTitle, CardBody } from "components/ui/Card";
import { HasGrades } from "./HasGrades";
import { Disclaimer } from "./Disclaimer";

export function FinalGradesCardUI({ semester, sections }) {
  const gradedSections = sections.filter((section) => section.grading?.grade);

  return (
    <Card>
      <CardTitle>
        <h2>Final Grades</h2>
      </CardTitle>
      <CardBody>
        {gradedSections.length > 0 ? (
          <HasGrades semester={semester} sections={sections} />
        ) : (
          <h4>No grade data available for {semester.name}</h4>
        )}

        <Disclaimer />
      </CardBody>
    </Card>
  );
}

FinalGradesCardUI.propTypes = {
  semester: PropTypes.shape({
    name: PropTypes.string,
  }),
  sections: PropTypes.arrayOf(PropTypes.object),
};
