import React from "react";
import { react2angular } from "react2angular";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReduxProvider from "components/ReduxProvider";
import InstructorsCard from "components/cards/InstructorsCard";
import { TeachingInstructorsCard } from "components/cards/TeachingInstructorsCard";
import WithCurrentCourse from "components/data/WithCurrentCourse";
import CourseContext from "components/contexts/CourseContext";

const NgInstructorsCard = () => (
  <ReduxProvider>
    <Router>
      <Routes>
        <Route
          path="academics/teaching-semester/:semesterId/class/:classId"
          element={<TeachingInstructorsCard />}
        />

        <Route
          path="academics/semester/:semesterId/class/:classId"
          element={
            <WithCurrentCourse>
              <CourseContext.Consumer>
                {({ currentCourse }) => (
                  <InstructorsCard course={currentCourse} />
                )}
              </CourseContext.Consumer>
            </WithCurrentCourse>
          }
        />
      </Routes>
    </Router>
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("instructorsCard", react2angular(NgInstructorsCard));
