import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Card, CardBody, CardTitle } from "components/ui/Card";
import { UnstyledList } from "components/ui/Lists";
import { Ellipsis } from "components/ui/Ellipsis";
import { CanvasIcon } from "components/ui/Icons";
import { useCurrentCourses } from "../hooks/useCurrentCourses";

export function CourseSitesCard() {
  const { currentCourse, currentTeachingCourse } = useCurrentCourses();

  const { class_sites: sites = [], title: courseTitle } =
    currentCourse || currentTeachingCourse;

  if (sites.length > 0) {
    return (
      <Card data-testid="course-sites-card">
        <CardTitle>
          <h2>Class Sites</h2>
        </CardTitle>
        <CardBody>
          <SitesList>
            {sites.map((site) => (
              <Site key={site.id} site={site} courseTitle={courseTitle} />
            ))}
          </SitesList>
        </CardBody>
      </Card>
    );
  }

  return null;
}

CourseSitesCard.displayName = "CourseSitesCard";

function Site({ site, courseTitle }) {
  const { name, site_url: url, shortDescription } = site;
  const renderDescription =
    shortDescription !== name && shortDescription !== courseTitle;

  return (
    <SiteItemStyles>
      <CanvasIcon />

      <div>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <strong>{name}</strong>
        </a>

        {renderDescription && (
          <Ellipsis className="shortDescription">{shortDescription}</Ellipsis>
        )}
      </div>
    </SiteItemStyles>
  );
}

Site.displayName = "Site";
Site.propTypes = {
  courseTitle: PropTypes.string,
  site: PropTypes.shape({
    emitter: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    shortDescription: PropTypes.string,
    site_url: PropTypes.string,
    siteType: PropTypes.string,
  }),
};

const SitesList = styled(UnstyledList)`
  display: flex;
  flex-direction: column;
  gap: var(--medium-spacing);
`;

const SiteItemStyles = styled.li`
  display: flex;
  align-items: center;
  gap: var(--medium-spacing);
  overflow: hidden;

  > div:last-child {
    overflow: hidden;
  }

  .shortDescription {
    color: var(--dusty-gray);
  }
`;
