import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector } from "react-redux";
import currentUserState from "utils/currentUserState";
import { UnstyledList } from "components/ui/Lists";
import { MediumSpacer } from "components/ui/VerticalSpacers";
import AccessibleLinkContent from "components/ui/AccessibleLinkContent";
import { renderToString } from "react-dom/server";

export function Glossary() {
  const { glossary = [] } = useSelector(
    (state) => currentUserState(state).loanHistoryCumulative
  );

  return (
    <div>
      <h3>Glossary</h3>
      <GlossaryList>
        {glossary.map((term) => (
          <GlossaryTerm key={term.code} data={term} />
        ))}
      </GlossaryList>
    </div>
  );
}

Glossary.displayName = "Glossary";

function GlossaryTerm({ data }) {
  const { code, term } = data;
  let { definition } = data;

  function isAnchor(str) {
    return /\.*<a.*\>.*\<\/a\>.*/i.test(str);
  }

  if (isAnchor(definition)) {
    definition =
      definition.slice(0, -5) +
      renderToString(
        <AccessibleLinkContent showNewWindow={true}></AccessibleLinkContent>
      ) +
      definition.slice(-5);
  }

  return (
    <li id={code}>
      <div className="message">
        <strong>{term}</strong>
        <MediumSpacer />
        <div dangerouslySetInnerHTML={{ __html: definition }} />
      </div>
    </li>
  );
}

GlossaryTerm.displayName = "GlossaryTerm";
GlossaryTerm.propTypes = {
  data: PropTypes.shape({
    code: PropTypes.string,
    term: PropTypes.string,
    definition: PropTypes.string,
  }),
};

const GlossaryList = styled(UnstyledList)`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
