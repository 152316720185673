import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardTitle, CardBody } from "components/ui/Card";
import Spinner from "components/ui/Spinner";
import { NoClasses } from "./MyClassesCard/NoClasses";
import { Courses } from "./MyClassesCard/Courses";
import { getMyStatus } from "data/slices/myStatus";
import { fetchCourses } from "data/slices/users/courses";
import { activeRoles as activeRoleFilter } from "components/hooks/useRoles";

// Set GRADING_IN_PROGRESS_CLASSES=true in env.local to see classes in the MyClassesCard
// during the time between grading and then end of the semester
export function MyClassesCard() {
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentUID) {
      dispatch(getMyStatus());
      dispatch(fetchCourses(currentUID));
    }
  }, [dispatch, currentUID]);

  const { currentUID, isOnlyApplicant, loadState, currentTerm, classes } = useSelector(
    (state) => {
      const { currentUID, myStatus: {
        roles: userRoles = {}
      } = {} } = state;
      const {
        courses: {
          loadState,
          current_term: currentTerm,
          classes = [],
          gradingInProgressClasses = [],
        } = {},
      } = state.byUser[currentUID] || {};

      const activeRoles = activeRoleFilter(userRoles);
      const isOnlyApplicant = activeRoles.length === 1 && activeRoles[0] === "applicant";

      return {
        currentUID,
        isOnlyApplicant,
        loadState,
        currentTerm,
        classes:
          process.env.GRADING_IN_PROGRESS_CLASSES === "true"
            ? gradingInProgressClasses
            : classes,
      };
    }
  );
  const showCard = !isOnlyApplicant;

  if (showCard) {
    return (
      <Card data-testid="my-classes-card">
        <CardTitle>
          <h2>My Classes {<span>{currentTerm}</span>}</h2>{" "}
        </CardTitle>

        {loadState === "pending" && (
          <CardBody>
            <Spinner />
          </CardBody>
        )}

        {loadState === "success" && classes.length > 0 && <Courses />}
        {loadState === "success" && classes.length === 0 && <NoClasses />}
      </Card>
    );
  }
  return null;
}
MyClassesCard.displayName = "MyClassesCard";
