import React from "react";
import PropTypes from "prop-types";

import formatCurrency from "functions/formatCurrency";
import Spinner from "components/../react/components/Spinner";
import ItemPayment from "./ItemPayment";

import warningIcon from "icons/warning.svg";
import styled from "styled-components";

const ItemPayments = ({ children }) => <LeftOl>{children}</LeftOl>;
ItemPayments.displayName = "ItemPayments";
ItemPayments.propTypes = {
  children: PropTypes.node,
};

const ChargesPaidTab = ({ item }) => {
  const payments = item.payments || [];
  const { isLoadingPayments: loading, loadingPaymentsError: error } = item;

  if (loading) {
    return (
      <ChargePaidTabStyles data-testid="charges-paid-tab">
        <div
          className="ChargesPaid ChargesPaid--loading"
          onClick={(e) => e.stopPropagation()}
        >
          <Spinner />
        </div>
      </ChargePaidTabStyles>
    );
  }

  if (error) {
    return (
      <ChargePaidTabStyles data-testid="charges-paid-tab-error">
        <div
          className="ChargesPaid ChargesPaid--error"
          onClick={(e) => e.stopPropagation()}
        >
          <img src={warningIcon} />
          There is a problem displaying this information. Please try again soon.
        </div>
      </ChargePaidTabStyles>
    );
  }

  return (
    <ChargePaidTabStyles>
      <div className="ChargesPaid" onClick={(e) => e.stopPropagation()}>
        <h3>Charges paid by this transaction</h3>

        {payments.length > 0 ? (
          <ItemPayments>
            {payments.map((payment, index) => (
              <ItemPayment key={index} payment={payment} />
            ))}
          </ItemPayments>
        ) : (
          "No charges have been paid at this time"
        )}

        {item.balance !== 0 && (
          <div style={{ textAlign: `right`, marginTop: `18px` }}>
            Unapplied Balance:
            <span style={{ marginLeft: `5px` }}>
              {formatCurrency(Math.abs(item.balance))}
            </span>
          </div>
        )}
      </div>
    </ChargePaidTabStyles>
  );
};
ChargesPaidTab.displayName = "ChargesPaidTab";
ChargesPaidTab.propTypes = {
  item: PropTypes.object,
};

export default ChargesPaidTab;
export const ChargePaidTabStyles = styled.div`
  padding: 0 0 10px 10px;
  .ChargesPaid {
    grid-column: 1/3;
    cursor: auto;

    h3 {
      font-size: 13px;
    }
  }

  .ChargesPaid--loading {
    grid-column: 1/-1;
  }

  .ChargesPaid--error {
    grid-column: 1/-1;
    display: flex;
  }

  .ChargesPaid h3 {
    font-size: 12px;
    margin-top: 0;
  }

  .ChargesPaid ol {
    list-style-type: decimal;
    margin-bottom: 0;
  }

  .PartiallyPaid {
    color: var(--dove-gray);
  }
`;

export const LeftOl = styled.ol`
  padding-left: 0;
  margin-left: 15px;
`;
