import React from "react";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import GroupsCard from "components/cards/GroupsCard.js";

const NgGroupsCard = () => (
  <ReduxProvider>
      <GroupsCard />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("groupsCard", react2angular(NgGroupsCard));
