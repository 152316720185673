import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useSelector } from "react-redux";
import { haveFailure, areLoaded } from "data/utils/statusHelpers";

import { ErrorMessage } from "components/ui/ErrorMessage";
import WidgetSubtitle from "components/ui/WidgetSubtitle";
import APILink from "components/ui/APILink";

export const ArchiveLink = ({ label }) => {
  const { archiveURL, canSeeCSLinks, errored, isStudent, isApplicant, loaded } =
    useSelector((state) => {
      const {
        myWebMessages: {
          loadState: webMessagesLoadState,
          universityNotifications: { archiveURL } = {},
        },
        myStatus: {
          canSeeCSLinks: canSeeCSLinks,
          loadState: statusLoadState,
          roles: { student: isStudent, applicant: isApplicant },
        },
      } = state;

      const loadStates = [statusLoadState, webMessagesLoadState];
      const loaded = areLoaded(loadStates);
      const errored = haveFailure(loadStates);

      return {
        archiveURL,
        canSeeCSLinks,
        errored,
        isStudent,
        isApplicant,
        loaded,
      };
    });

  if (loaded) {
    return (
      <>
        <WidgetSubtitle>Archive</WidgetSubtitle>

        {errored && (
          <div style={{ padding: `var(--md)` }}>
            <ErrorMessage message="An error occurred while trying to retrieve your data." />
          </div>
        )}

        {!errored && archiveURL && canSeeCSLinks && (
          <ArchiveLinkStyles>
            {(isStudent || isApplicant) && (
              <APILink link={{ ...archiveURL, title: label }} text={label} />
            )}
          </ArchiveLinkStyles>
        )}
      </>
    );
  } else {
    return null;
  }
};
ArchiveLink.displayName = "ArchiveLink";
ArchiveLink.propTypes = {
  label: PropTypes.string,
};

const ArchiveLinkStyles = styled.div`
  text-align: center;
  padding: 15px;
`;
