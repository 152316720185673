import React from "react";

import { useRoles } from "components/hooks/useRoles";
import { SmallSpacer } from "components/ui/VerticalSpacers";

export function Disclaimer() {
  const { exStudent } = useRoles();
  return (
    <>
      <SmallSpacer />
      <p style={{ fontSize: `11px`, margin: 0, padding: 0 }}>
        This unofficial list of grades is based on available data and may not
        reflect your full transcript.
        {exStudent && (
          <>
            {" "}
            Students who have graduated may request transcripts online through
            an{" "}
            <a href="https://www.credentials-inc.com/CGI-BIN/dvcgitp.pgm?ALUMTRO001312">
              alumni service
            </a>
            .
          </>
        )}
      </p>
    </>
  );
}
