import React from "react";
import PropTypes from "prop-types";

import { CreditsContainer } from "./CreditsContainer";
import { TransferCreditTable } from "./TransferCreditTable";

export function GraduateTransferCredit({ transferCredits = {} }) {
  const { summary, detailed } = transferCredits;

  if (summary) {
    const { careerDescr, totalTransferUnits } = summary;
    return (
      <TransferCreditTable>
        <thead>
          <tr>
            <th>Type</th>
            <th>Units</th>
          </tr>
        </thead>
        <CreditsContainer
          title={careerDescr + " Course Units"}
          value={totalTransferUnits.toFixed(3)}
          detailedRows={detailed}
        />
      </TransferCreditTable>
    );
  }

  return null;
}

GraduateTransferCredit.propTypes = {
  transferCredits: PropTypes.object,
};
