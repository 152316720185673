export const isLoaded = (status) =>
  status === "success" || status === "failure";
export const isLoading = (status) => status === "idle" || status === "pending";
export const isSucceeded = (status) => status === "success";
export const isFailed = (status) => status === "failure";

/**
 * Determines if any load states provided indicate failure
 * @param {Array} loadStates
 * @returns Boolean
 */
export const haveFailure = (loadStates) => {
  if (!Array.isArray(loadStates)) {
    throw new TypeError("loadStates argument is not an array");
  }
  return loadStates.find((ls) => isFailed(ls)) !== undefined;
};

/**
 * Determines if all load states provided indicate success or failure
 * @param {Array} loadStates
 * @returns Boolean
 */
export const areLoaded = (loadStates) => {
  if (!Array.isArray(loadStates)) {
    throw new TypeError("loadStates argument is not an array");
  }
  return loadStates.every((ls) => isLoaded(ls));
};
