import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle } from "./Card";

export function ErrorCard({ title }) {
  return (
    <Card data-testid="error-card">
      <CardTitle>
        <h2>{title}</h2>
      </CardTitle>
      <CardBody>There was an error rendering this card.</CardBody>
    </Card>
  );
}

ErrorCard.propTypes = {
  title: PropTypes.string.isRequired,
};
