import React from "react";
import ButtonAsLink from "components/ui/ButtonAsLink";
import InfoIcon from "components/ui/InfoIcon";
import PropTypes from "prop-types";

export default function ColumnTitle({ title, scrollToSection, finding }) {
  function scrollToFinding() {
    scrollToSection(finding);
  }

  return (
    <th>
      {title}{" "}
      <ButtonAsLink onClick={scrollToFinding}>
        <InfoIcon />
      </ButtonAsLink>
    </th>
  );
}

ColumnTitle.displayName = "ColumnTitle";
ColumnTitle.propTypes = {
  title: PropTypes.string.isRequired,
  scrollToSection: PropTypes.func.isRequired,
  finding: PropTypes.string.isRequired,
};
