import React from "react";
import { Card } from "components/ui/Card";
import { StatusHeader } from "./StatusHeader";
import { OPTION_DECLINE, statusProps } from "./statusProps";
import SIRNextSteps from "../../SIRNextSteps";

export function StatusCompleted({ status, messageObj }) {
  const {
    isUndergraduate,
    header,
    sirCompletedAction,
    sirCompletedMessage,
    deposit: { origDepositDue } = {},
    config: { descrProgramLong } = {},
  } = status;

  const notDeclined =
    sirCompletedAction !== OPTION_DECLINE &&
    messageObj.progAction !== OPTION_DECLINE;

  return (
    <Card data-testid="sir-card-status-completed">
      {(isUndergraduate || notDeclined) && <StatusHeader header={header} />}

      {isUndergraduate ? (
        <>
          <div className="cc-widget-text">
            <h3>Offer Accepted Confirmation</h3>
            Welcome! We&apos;re thrilled that you&apos;ve chosen to join UC
            Berkeley! Thank you for accepting your undergraduate offer of
            admission
            {origDepositDue === "0" &&
              " (as a reminder, your deposit requirement was waived)"}
            .<br />
            To view your Student ID, click your name on the top right corner of
            this page.
            <br />
            <h3>Next Steps</h3>
            <SIRNextSteps />
          </div>
        </>
      ) : (
        <>
          {(messageObj?.progAction || sirCompletedAction) && (
            <>
              <div className="cc-widget-title">
                {!notDeclined && <h2>Enrollment Declined</h2>}
              </div>
              <div className="cc-widget-text">
                <h3>{descrProgramLong}</h3>
                {messageObj && <div>{messageObj.messageText}</div>}
                {!messageObj && sirCompletedMessage && (
                  <div>{sirCompletedMessage}</div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </Card>
  );
}
StatusCompleted.propTypes = statusProps;
