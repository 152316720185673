import styled from "styled-components";
import { CCTable } from "components/ui/Table";

export const LoanCategoryTable = styled(CCTable)`
  font-size: 12px;

  thead,
  tbody,
  tfoot {
    tr {
      th,
      td {
        padding: 8px;

        &:first-child {
          width: 50%;
        }

        &:not(:first-child) {
          text-align: right;
          width: 25%;
        }
      }
    }
  }

  tbody,
  tfoot {
    tr {
      &:first-child {
        td {
          border-top: 1px solid var(--gallery);
        }
      }

      td {
        border-bottom: 1px solid var(--gallery);

        &:first-child {
          border-left: 1px solid var(--gallery);
        }

        &:last-child {
          border-right: 1px solid var(--gallery);
        }
      }
    }
  }

  tbody + tfoot {
    tr:first-child td {
      border-top-width: 0;
    }
  }

  ${({ grandTotals }) =>
    grandTotals ? `tfoot tr td { font-size: 14px; }` : null}
`;
