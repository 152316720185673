import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/data/ReduxProvider";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { FinancialAidCommunicationsCard } from "components/cards/FinancialAidCommunicationsCard";

const NgFinancialAidCommunicationsCard = () => (
  <ReduxProvider>
    <Router>
      <Routes>
        <Route
          path="/finances/finaid/:finaidYearId"
          element={<FinancialAidCommunicationsCard />}
        />
      </Routes>
    </Router>
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component(
    "financialAidCommunicationsCard",
    react2angular(NgFinancialAidCommunicationsCard)
  );
