import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import { FacultyHigherDegreeCommitteesCard } from "components/cards/FacultyHigherDegreeCommitteesCard";

const NgFacultyHigherDegreeCommitteesCard = () => {
  return (
    <ReduxProvider>
      <FacultyHigherDegreeCommitteesCard />
    </ReduxProvider>
  );
};

angular
  .module("calcentral.react")
  .component(
    "facultyHigherDegreeCommitteesCard",
    react2angular(NgFacultyHigherDegreeCommitteesCard)
  );
