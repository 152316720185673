import React from "react";

import { useParams } from "react-router-dom";
import getUserLinks from "../../data/actions/getUserLinks";
import { fetchHolds } from "../../data/slices/users/holds";
import { fetchWebMessages } from "../../data/actions/webMessagesActions";
import { getAgreements } from "../../data/slices/users/agreements";
import { getChecklistItems } from "../../data/slices/users/checklistItems";
import { getMyStatus } from "../../data/slices/myStatus";

import { Card, CardBody, CardTitle } from "../ui/Card";
import Spinner from "../ui/Spinner";

import { Holds } from "./FinancialAidCommunicationsCard/Holds";
import { Tasks } from "./FinancialAidCommunicationsCard/Tasks";
import { Messages } from "./FinancialAidCommunicationsCard/Messages";
import { ArchiveLink } from "./FinancialAidCommunicationsCard/ArchiveLink";
import { useSlice } from "../hooks/useSlice";
import { loadStateForSlices } from "../../utils/loadStateForSlices";
import { dispatchUserSlice } from "../hooks/dispatchUserSlice";
import { useUser } from "../hooks/useUser";
import { dispatchSlice } from "../hooks/dispatchSlice.js";

export const FinancialAidCommunicationsCard = () => {
  dispatchSlice(getMyStatus);
  dispatchUserSlice([
    fetchHolds,
    getAgreements,
    getChecklistItems,
    fetchWebMessages,
    getUserLinks,
  ]);

  const { finaidYearId } = useParams();

  const apiLinks = useSlice("apiLinks");
  const myStatus = useSlice("myStatus");
  const myWebMessages = useSlice("myWebMessages");
  const { holds, agreements, checklistItems } = useUser((user) => user);

  const loadState = loadStateForSlices([
    apiLinks,
    myStatus,
    myWebMessages,
    holds,
    agreements,
    checklistItems,
  ]);

  return (
    <Card data-testid="finaid-scholarships-communications-card">
      <CardTitle>
        <h2>Communications</h2>
      </CardTitle>

      {loadState === "pending" && (
        <CardBody>
          <Spinner />
        </CardBody>
      )}

      {loadState === "success" && (
        <div>
          <Holds />
          <Tasks />
          <Messages year={finaidYearId} />
          <ArchiveLink label="Archive of Official Communications" />
        </div>
      )}
    </Card>
  );
};
FinancialAidCommunicationsCard.displayName = "FinancialAidCommunicationsCard";
