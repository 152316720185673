import React from "react";
import { react2angular } from "react2angular";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ReduxProvider } from "components/data/ReduxProvider";
import { FinalGradesCard } from "components/cards/FinalGradesCard";

const NgFinalGradesCard = () => (
  <ReduxProvider>
    <Router>
      <Routes>
        <Route
          path="academics/teaching-semester/:semesterId"
          element={<FinalGradesCard />}
        />

        <Route
          path="academics/semester/:semesterId"
          element={<FinalGradesCard />}
        />
      </Routes>
    </Router>
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("finalGradesCard", react2angular(NgFinalGradesCard));
