import React, { useState } from "react";
import PropTypes from "prop-types";

import photoUnavailable from "assets/images/photo_unavailable_official_72x96.svg";

export function MemberPhoto({ photo, name }) {
  const [error, setError] = useState(false);

  if (error || !photo) {
    return (
      <div>
        <img src={photoUnavailable} alt="No Official Photo is Available" />
      </div>
    );
  }

  return (
    <img
      width="72"
      height="96"
      onError={() => {
        setError(true);
      }}
      src={photo}
      alt={"Photo of " + name}
    />
  );
}

MemberPhoto.propTypes = {
  photo: PropTypes.string,
  name: PropTypes.string,
};
