import React from "react";
import { react2angular } from "react2angular";

import ReduxProvider from "components/ReduxProvider";
import { WithdrawnAdmissionCard } from "../cards/WithdrawnAdmissionCard";
import { BrowserRouter as Router } from "react-router-dom";

function NgWithdrawnAdmissionCard() {
  return (
    <ReduxProvider>
      <Router>
        <WithdrawnAdmissionCard />
      </Router>
    </ReduxProvider>
  );
}

angular
  .module("calcentral.react")
  .component("withdrawnAdmissionCard", react2angular(NgWithdrawnAdmissionCard));
