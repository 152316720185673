import React from "react";
import PropTypes from "prop-types";
import { LargeSpacer, MediumSpacer } from "components/ui/VerticalSpacers";
import { includes } from "lodash";
import formatCurrency from "functions/formatCurrency";
import { LoanCategoryTable } from "./LoanCategoryTable";
import ColumnTitle from "./ColumnTitle";

export function LoanCategory({ loanCategory }) {
  const {
    category,
    loans = [],
    totals: { amountOwed, estMonthlyPayment } = {},
  } = loanCategory;

  let { descr: loanCategoryDescription } = loanCategory;

  const scrollToSection = (finding) => {
    const headers = document.querySelectorAll("h3");
    const targetHeader = Array.from(headers).find((header) =>
      header.textContent.includes(finding)
    );

    if (targetHeader) {
      targetHeader.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const downcased = category.toLowerCase();

  const showLoanList =
    !includes(downcased, "perkins") &&
    !includes(downcased, "private") &&
    !!loans.find((loan) => loan.amountOwed > 0);

  const showAmountOwedVerbiage =
    !includes(downcased, "state") && !includes(downcased, "private");

  function isAnchor(str) {
    return /\.*<a.*\>.*\<\/a\>.*/i.test(str);
  }

  if (isAnchor(loanCategoryDescription)) {
    loanCategoryDescription =
      loanCategoryDescription.slice(0, -5) + loanCategoryDescription.slice(-5);
  }

  return (
    <>
      <h3>{category}</h3>
      <div dangerouslySetInnerHTML={{ __html: loanCategoryDescription }} />

      <MediumSpacer />
      <LoanCategoryTable>
        <thead>
          <tr>
            <th>Loan Name</th>
            <ColumnTitle
              title={showAmountOwedVerbiage ? "Amount Owed" : "Borrowed"}
              scrollToSection={scrollToSection}
              finding={"Glossary"}
            />
            <ColumnTitle
              title={"Est. Monthly Payment"}
              scrollToSection={scrollToSection}
              finding={"Estimated Payment Disclaimer"}
            />
          </tr>
        </thead>

        {showLoanList && (
          <tbody>
            {loans.map((loan) => (
              <Loan key={loan.sequence} loan={loan} />
            ))}
          </tbody>
        )}

        <tfoot>
          <tr>
            <td>Total {category}</td>
            <td>{formatCurrency(amountOwed)}</td>
            <td>{formatCurrency(estMonthlyPayment)}</td>
          </tr>
        </tfoot>
      </LoanCategoryTable>
      <LargeSpacer />
      <LargeSpacer />
    </>
  );
}

LoanCategory.displayName = "LoanCategory";
LoanCategory.propTypes = {
  loanCategory: PropTypes.shape({
    descr: PropTypes.string,
    category: PropTypes.string,
    loans: PropTypes.arrayOf(PropTypes.object),
    totals: PropTypes.shape({
      amountOwed: PropTypes.number,
      estMonthlyPayment: PropTypes.number,
    }),
  }),
};

function Loan({ loan }) {
  const { amountOwed, loanType, estMonthlyPayment } = loan;

  if (amountOwed > 0) {
    return (
      <tr>
        <td>{loanType}</td>
        <td>{formatCurrency(amountOwed)}</td>
        <td>{formatCurrency(estMonthlyPayment)}</td>
      </tr>
    );
  }

  return null;
}

Loan.displayName = "Loan";
Loan.propTypes = {
  loan: PropTypes.shape({
    amountOwed: PropTypes.number,
    loanType: PropTypes.string,
    estMonthlyPayment: PropTypes.number,
  }),
};
