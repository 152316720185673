import React from "react";
import PropTypes from "prop-types";

import { useParams } from "react-router-dom";

import { useSelector } from "react-redux";
import { isLoaded } from "data/utils/statusHelpers";

import { ListBullets } from "components/ui/Lists";
import WithAPILink from "components/ui/WithAPILink";

export const TaskLinks = ({ canSeeCSLinks }) => {
  const { finaidYearId } = useParams();

  const {
    finaidFafsaLink,
    finaidFormsLink,
    finaidFormUploadLink,
    linksLoaded,
  } = useSelector((state) => {
    const {
      apiLinks: { loadState: apiLinksLoadState },
    } = state;

    const linksLoaded = isLoaded(apiLinksLoadState);

    return {
      finaidFafsaLink,
      finaidFormsLink,
      finaidFormUploadLink,
      linksLoaded,
    };
  });

  const urlParameters = {
    AID_YEAR: finaidYearId,
  };

  if (linksLoaded) {
    return (
      <div className="cc-widget-text">
        <hr />
        <ListBullets>
          <ul>
            {canSeeCSLinks && (
              <li>
                Financial Aid <WithAPILink urlID={"UC_CX_FA_COMM_FAFSA"} />
              </li>
            )}
            <li>
              Financial Aid <WithAPILink urlID={"UC_CX_FA_COMM_FORMS"} />
            </li>
            {canSeeCSLinks && (
              <li>
                Financial Aid{" "}
                <WithAPILink
                  urlID={"UC_CX_FA_FORM_UPLOAD"}
                  urlParameters={urlParameters}
                />
              </li>
            )}
          </ul>
        </ListBullets>
      </div>
    );
  }

  return null;
};
TaskLinks.propTypes = {
  canSeeCSLinks: PropTypes.bool,
};
