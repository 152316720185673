import React from "react";
import { RequireDelegate } from "components/RequireDelegate";
import { BulletList } from "components/ui/Lists";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import WithAPILink from "components/ui/WithAPILink";

import {
  UC_CC_DA_DD_ACAD_CAL,
  UC_CC_DA_DD_GRAD_DEADLINES
} from "functions/urlIds";

export function AcademicDatesCard() {
  return (
    <RequireDelegate>
      <Card data-testid="academic-dates-card">
        <CardTitle>
          <h2>Academic Dates &amp; Deadlines</h2>
        </CardTitle>
        <CardBody>
          <BulletList>
            <li>
              <WithAPILink urlID={UC_CC_DA_DD_ACAD_CAL} />
            </li>
            <li>
              <WithAPILink urlID={UC_CC_DA_DD_GRAD_DEADLINES} />
            </li>
          </BulletList>
        </CardBody>
      </Card>
    </RequireDelegate>
  );
}
