import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CCTable } from "components/ui/Table";
import PastStanding from "./PastStanding";

import {
  Disclosure,
  TextDisclosureButton,
  DisclosurePanel,
} from "components/ui/Disclosure";

export default function StandingsHistory({ standingsHistory }) {
  const [isOpen, setOpen] = React.useState(false);

  if (standingsHistory.length) {
    return (
      <Disclosure open={isOpen} onChange={() => setOpen(!isOpen)}>
        {!isOpen && <TextDisclosureButton>Show History</TextDisclosureButton>}

        <DisclosurePanel>
          <StandingsHistoryTable standingsHistory={standingsHistory} />
        </DisclosurePanel>
      </Disclosure>
    );
  }

  return null;
}

StandingsHistory.propTypes = {
  standingsHistory: PropTypes.array,
};

const HistoryTable = styled(CCTable)`
  margin: 2px 0;

  thead th {
    font-weight: bold;
  }

  tbody,
  thead {
    tr {
      th,
      td {
        padding: 2px 2px 2px 0;
        vertical-align: top;
      }
    }
  }
`;

const StandingsHistoryTable = ({ standingsHistory }) => {
  if (standingsHistory.length > 0) {
    return (
      <div
        style={{
          paddingBottom: "5px",
          paddingTop: "5px",
          backgroundColor: "var(--wild-sand)",
        }}
      >
        <HistoryTable>
          <thead>
            <tr>
              <th
                scope="col"
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  color: "var(--dove-gray)",
                }}
              >
                Status History
              </th>
              <td
                scope="col"
                style={{
                  textAlign: "right",
                  fontWeight: "bold",
                  color: "var(--dove-gray)",
                }}
              >
                Term Ending
              </td>
            </tr>
          </thead>
          <tbody>
            {standingsHistory.map((standing, standingHistoryIndex) => (
              <PastStanding standing={standing} key={standingHistoryIndex} />
            ))}
          </tbody>
        </HistoryTable>
      </div>
    );
  } else {
    return null;
  }
};

StandingsHistoryTable.displayName = "StandingsHistoryTable";
StandingsHistoryTable.propTypes = {
  standingsHistory: PropTypes.array,
};
