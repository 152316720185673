import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import { fetchCourseTextbooks } from "data/slices/courses/courseTextbooks";
import { Spinner } from "components/ui/Spinner";
import { CourseBooks } from "./TextbooksCard/CourseBooks";

export function BookListCard({ semester }) {
  const dispatch = useDispatch();

  const allLaw = semester.classes.every((course) =>
    course.courseCareerCode.includes("LAW")
  );

  useEffect(() => {
    if (semester) {
      semester.classes.map((course) => {
        if (!course.courseCareerCode.includes("LAW")) {
          dispatch(fetchCourseTextbooks(course, semester));
        }
      });
    }
  }, [dispatch, semester]);

  const courses = useSelector((state) => {
    return semester.classes.map((course) => ({
      ...course,
      courseCode: course.course_code,
      textbooks: state.byCourse[course.course_id]?.courseTextbooks,
      isLawCourse: course.courseCareerCode.includes("LAW"),
    }));
  });

  const loadedBooks = courses.every(
    (course) =>
      course.textbooks?.loadState === "success" ||
      course.courseCareerCode.includes("LAW")
  );

  const isReady = courses.length && (loadedBooks || allLaw);

  return (
    <Card data-testid="book-list-card">
      <CardTitle>
        <h2>Book List » {semester.name}</h2>
      </CardTitle>
      <CardBody>
        {isReady && (
          <>
            {courses.map((course, index) => (
              <CourseBooks key={index} course={course} />
            ))}
          </>
        )}

        {!isReady && <Spinner />}
      </CardBody>
    </Card>
  );
}

BookListCard.displayName = "BookListCard";
BookListCard.propTypes = {
  semester: PropTypes.object,
};
