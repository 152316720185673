import React from "react";
import PropTypes from "prop-types";

import { AwardRow, SnapshotItem } from "./AwardRow";

const matchingItems = (data, awardType) => {
  const { awardTypes = [] } = data || {};
  return awardTypes.find((type) => type.type === awardType.type)?.items;
};

export function AwardType({
  awardType,
  currentComparison,
  currentSnapshot = {},
}) {
  const comparisonItems = matchingItems(currentComparison, awardType) || [];
  const snapshotItems = matchingItems(currentSnapshot, awardType) || [];
  const comparisonDescriptions = comparisonItems.map(
    (item) => item.description
  );

  const itemsUniqueToSnapshot = snapshotItems.reduce((accum, current) => {
    if (comparisonDescriptions.includes(current.description)) {
      return accum;
    }

    return [...accum, current];
  }, []);

  return (
    <>
      <tr className="typeRow">
        <th className="typeTitle" scope="row">
          {awardType.description}
        </th>
        <td></td>
        <td></td>
      </tr>

      <>
        {comparisonItems.map((award) => (
          <AwardRow
            key={award.description}
            award={award}
            snapshotItems={snapshotItems}
          />
        ))}

        {itemsUniqueToSnapshot.map((award) => (
          <SnapshotItem key={award.description} award={award} />
        ))}
      </>
    </>
  );
}

AwardType.displayName = "AwardType";
AwardType.propTypes = {
  awardType: PropTypes.shape({
    type: PropTypes.string,
    description: PropTypes.string,
  }),
  currentComparison: PropTypes.object,
  currentSnapshot: PropTypes.object,
};
