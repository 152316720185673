import styled from "styled-components";

export const BasicTable = styled.table`
  border-spacing: 0;
  width: 100%;

  thead,
  tbody {
    th,
    td {
      padding: inherit;
    }
  }
`;
BasicTable.displayName = "BasicTable";

export const CCTable = styled(BasicTable)`
  thead tr th,
  tbody tr th {
    text-align: left;
    color: var(--dove-gray);
  }

  thead,
  tbody {
    tr {
      th,
      td {
        padding-top: var(--tiny);
        padding-bottom: var(--tiny);
        vertical-align: top;

        &.text-center {
          text-align: center;
        }
      }
    }
  }
`;
CCTable.displayName = "CCTable";

export const Table = styled.table`
  background: none;
  font-size: var(--base-font-size);
  margin: 0;
  border: none;
  border-spacing: 0;
  width: 100%;

  thead {
    background: none;
  }

  tr:nth-of-type(2n) {
    background: none;
  }

  tr td,
  tr th {
    font-size: var(--base-font-size);
    line-height: inherit;
    background: none;
    padding: 0;
  }

  thead,
  tfoot,
  tbody {
    tr td,
    tr th {
      font-size: var(--base-font-size);
      line-height: inherit;
      background: none;
      padding: 0;
    }
  }

  tbody tr {
    th,
    td {
      padding: 5px 0;
      border-bottom: 1px solid #ccc;
    }
  }
`;
Table.displayName = "Table";
export default Table;

export const TableHead = styled.thead`
  tr {
    th {
      text-align: left;
      vertical-align: top;
    }
  }
`;

TableHead.displayName = "TableHead";

export const HeaderRow = styled.tr`
  th {
    text-align: left;
  }
`;
HeaderRow.displayName = "HeaderRow";

export const UnderlinedRow = styled.tr`
  td {
    padding: 5px 0 !important;
    border-bottom: 1px solid #ccc;
  }
`;
UnderlinedRow.displayName = "UnderlinedRow";
