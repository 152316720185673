import styled, { css } from "styled-components";

export const TextEllipsisOverflowStyles = css`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Ellipsis = styled.div`
  ${TextEllipsisOverflowStyles}
`;
Ellipsis.displayName = "Ellipsis";

export const OverflowListItem = styled.li`
  ${TextEllipsisOverflowStyles}
`;
OverflowListItem.displayName = "OverflowListItem";

export default Ellipsis;
