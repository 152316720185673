import {
  FETCH_STATUS_AND_HOLDS_START,
  FETCH_STATUS_AND_HOLDS_SUCCESS,
  FETCH_STATUS_AND_HOLDS_FAILURE,
} from "../action-types";

export const fetchStatusAndHoldsStart = () => ({
  type: FETCH_STATUS_AND_HOLDS_START,
});

export const fetchStatusAndHoldsSuccess = (data) => ({
  type: FETCH_STATUS_AND_HOLDS_SUCCESS,
  value: data,
});

export const fetchStatusAndHoldsFailure = (error) => ({
  type: FETCH_STATUS_AND_HOLDS_FAILURE,
  value: error,
});

export const fetchStatusAndHolds = () => {
  return (dispatch, getState) => {
    const { myStatusAndHolds } = getState();

    if (myStatusAndHolds.loaded || myStatusAndHolds.isLoading) {
      return new Promise((resolve, _reject) => resolve(myStatusAndHolds));
    } else {
      dispatch(fetchStatusAndHoldsStart());

      const url = "/api/my/academics/status_and_holds";
      fetch(url, { method: "GET" })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            const failure = {
              status: "There was a problem",
              statusText: "Please check again later",
            };
            dispatch(fetchStatusAndHoldsFailure(failure));
          }
        })
        .then((data) => {
          dispatch(fetchStatusAndHoldsSuccess(data));
        })
        .catch((error) => {
          if (error.response) {
            const failure = {
              status: error.response.status,
              statusText: error.response.statusText,
            };
            dispatch(fetchStatusAndHoldsFailure(failure));
          }
        });
    }
  };
};
