import React from "react";
import PropTypes from "prop-types";
import LightText from "../../ui/LightText";

import {
  ChevronCircleIcon,
  Disclosure,
  DisclosureContainer,
  DisclosureButton,
  DisclosurePanel,
} from "components/ui/ChevronDisclosure";

import styled from "styled-components";
import { FacultyCommitteeMember } from "./FacultyCommitteeMember";
import { useState } from "react";
import { MailtoLink } from "components/ui/MailtoLink";

export const FacultyCommittee = ({ committee, isActive }) => {
  const {
    student,
    statusMessage,
    milestoneAttempts,
    serviceRange,
    committeeType,
    program,
    committeeMembers,
  } = committee;

  const { photo, name, email } = student;

  const {
    chair,
    coChair,
    academicSenate,
    insideMembers,
    outsideMembers,
    additionalReps,
  } = committeeMembers;

  const [isOpen, setOpen] = useState(false);
  const [isPhotoError, setPhotoError] = useState(false);

  return (
    <Disclosure open={isOpen} onChange={() => setOpen(!isOpen)}>
      <FacultyCommitteeDisclosureContainer>
        {({ setHoverActive }) => (
          <>
            <FacultyCommitteeDisclosureButton setHoverActive={setHoverActive}>
              <ButtonTitle>
                <div className="committees-picture">
                  {photo && !isPhotoError && (
                    <img
                      src={photo}
                      onError={() => {
                        setPhotoError(true);
                      }}
                    />
                  )}
                  {((photo && isPhotoError) || !photo) && (
                    <div
                      className="committees-image-unavailable"
                      title="Photo unavailable"
                    >
                      <span className="visuallyhidden">
                        No Official Photo is Available
                      </span>
                    </div>
                  )}
                </div>
                <div className="committees-content">
                  <strong>
                    <span>{name}</span>
                  </strong>
                  <div>
                    <MailtoLink emailAddress={email}>{email}</MailtoLink>
                  </div>
                  <div>
                    <span>{program}</span>
                  </div>
                  <LightText>
                    {milestoneAttempts.length > 0 && (
                      <LegendIcon
                        name="check"
                        style={{ color: `var(--jewel)` }}
                      />
                    )}
                    {committeeType}
                  </LightText>
                  <LightText>
                    {milestoneAttempts.length === 0 && !isActive && (
                      <LegendIcon
                        name="check"
                        style={{ color: `var(--jewel)` }}
                      />
                    )}
                    {milestoneAttempts.length === 0 && (
                      <strong>
                        <span>{statusMessage}</span>
                      </strong>
                    )}
                  </LightText>
                  <ul>
                    {milestoneAttempts.map((attempt, index) => (
                      <LightText key={index}>
                        <strong>
                          <span>{attempt.display}</span>
                        </strong>
                      </LightText>
                    ))}
                    <LightText>Service: {serviceRange}</LightText>
                  </ul>
                </div>
                <ChevronCircleIcon className="chevron-icon" />
              </ButtonTitle>

              <FacultyDisclosurePanel>
                {chair && chair.length > 0 && (
                  <div>
                    <table>
                      <thead>
                        <tr>
                          <th
                            style={{
                              textAlign: `left`,
                              paddingBottom: `var(--sm)`,
                            }}
                          >
                            Chair
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {chair.map((member, index) => (
                          <FacultyCommitteeMember key={index} member={member} />
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}

                {coChair && coChair.length > 0 && (
                  <div>
                    <table>
                      <thead>
                        <tr>
                          <th
                            style={{
                              textAlign: `left`,
                              paddingBottom: `var(--sm)`,
                            }}
                          >
                            Co-Chair
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {coChair.map((member, index) => (
                          <FacultyCommitteeMember key={index} member={member} />
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}

                {academicSenate && academicSenate.length > 0 && (
                  <div>
                    <table>
                      <thead>
                        <tr>
                          <th
                            style={{
                              textAlign: `left`,
                              paddingBottom: `var(--sm)`,
                            }}
                          >
                            Academic Senate Representative
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {academicSenate.map((member, index) => (
                          <FacultyCommitteeMember key={index} member={member} />
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}

                {insideMembers && insideMembers.length > 0 && (
                  <div>
                    <table>
                      <thead>
                        <tr>
                          <th
                            style={{
                              textAlign: `left`,
                              paddingBottom: `var(--sm)`,
                            }}
                          >
                            Inside Member
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {insideMembers.map((member, index) => (
                          <FacultyCommitteeMember key={index} member={member} />
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}

                {outsideMembers && insideMembers.length > 0 && (
                  <div>
                    <table>
                      <thead>
                        <tr>
                          <th
                            style={{
                              textAlign: `left`,
                              paddingBottom: `var(--sm)`,
                            }}
                          >
                            Outside Member
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {outsideMembers.map((member, index) => (
                          <FacultyCommitteeMember key={index} member={member} />
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}

                {additionalReps && additionalReps.length > 0 && (
                  <div>
                    <table>
                      <thead>
                        <tr>
                          <th
                            style={{
                              textAlign: `left`,
                              paddingBottom: `var(--sm)`,
                            }}
                          >
                            Additional Representatives
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {additionalReps.map((member, index) => (
                          <FacultyCommitteeMember key={index} member={member} />
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </FacultyDisclosurePanel>
            </FacultyCommitteeDisclosureButton>
          </>
        )}
      </FacultyCommitteeDisclosureContainer>
    </Disclosure>
  );
};

const ButtonTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

const FacultyDisclosurePanel = styled(DisclosurePanel)`
  width: 100%;
`;

const FacultyCommitteeDisclosureButton = styled(DisclosureButton)`
  position: relative;
  width: 100%;
  cursor: pointer;

  background-color: transparent;
  border: 0;
  margin: 0;
  padding: var(--md) 0;

  .header {
    display: grid;
    div:nth-child(2) {
      text-align: right;
    }
    grid-template-columns: auto auto;
    h3,
    h4 {
      font-weight: bold;
      text-align: left;
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const FacultyCommitteeDisclosureContainer = styled(DisclosureContainer)`
  border-bottom: 1px solid var(--gallery);
  display: block;

  button
`;

FacultyCommittee.displayName = "FacultyCommittee";
FacultyCommittee.propTypes = {
  committee: PropTypes.object,
  isActive: PropTypes.bool,
};

function LegendIcon({ name, style = {} }) {
  return (
    <i className={`fa fa-${name}`} aria-hidden={true} style={{ ...style }}></i>
  );
}

LegendIcon.propTypes = {
  name: PropTypes.string,
  style: PropTypes.object,
};
