import { useParams } from "react-router-dom";
import { useFilteredNav } from "./useFilteredNav";

export function useTopCategory() {
  const { categoryId } = useParams();
  const filteredNav = useFilteredNav();

  if (filteredNav) {
    return findTopCategory(filteredNav, categoryId);
  }
}

function findTopCategory(filteredNav, categoryId) {
  if (filteredNav.length > 0 && categoryId === undefined) {
    return filteredNav[0].categories[0];
  }

  return filteredNav
    .map((item) =>
      item.categories.find((category) => {
        return category.id === categoryId;
      })
    )
    .find((item) => item !== undefined);
}
