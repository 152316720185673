import React, { useState } from "react";

import NoBConnected from "../NoBconnected"

import { useDispatch, useSelector } from "react-redux";
import { slice as statusSlice } from "data/slices/myStatus";

import styled from "styled-components";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import { BCalIcon, BDriveIcon, BMailIcon } from "components/ui/SVGIcons";
import { BaseButton } from "components/ui/Buttons";
import { BlueButtonLink } from "components/ui/ButtonLink";
import { ErrorMessage } from "components/ui/ErrorMessage";

export const BConnectedSetupCard = () => {
  const [dismissalError, setDismissalError] = useState(false);

  const {
    csrfToken,
    hasGoogleAccessToken,
    isActingAs,
    isGoogleReminderDismissed,
    officialBmailAddress,
  } = useSelector((state) => {
    const {
      csrfToken,
      myStatus: {
        hasGoogleAccessToken,
        isActingAs,
        isGoogleReminderDismissed,
        officialBmailAddress,
      } = {},
    } = state;

    return {
      csrfToken,
      hasGoogleAccessToken,
      isActingAs,
      isGoogleReminderDismissed,
      officialBmailAddress,
    };
  });

  const showCard =
    officialBmailAddress && !hasGoogleAccessToken && !isGoogleReminderDismissed;

  if (showCard) {
    return (
      <Card data-testid="section-overview-card">
        <CardTitle>
          <h2>Setup</h2>
        </CardTitle>
        <CardBody>
          <BConnectedSetupCardContainer>
            {dismissalError && (
              <div className="error-container">
                <ErrorMessage message="An error occurred when attempting to dismiss this notice." />
              </div>
            )}
            <div className="sub-header">
              <h3>Connect to bConnected</h3>
              <TailoredBMailIcon />
              <TailoredBCalIcon />
              <TailoredBDriveIcon />
            </div>
            <NoBConnected mode="main" />
            <div
              data-testid="bconnected-setup-card-content-buttons"
              className="content-buttons"
            >
              <BlueButtonLink
                style={{ marginRight: "4px" }}
                onClick={() => window.location="/api/google/request_authorization"}
                disabled={isActingAs}
              >
                Connect
              </BlueButtonLink>
              <BaseButton
                onClick={dismissReminderFactory(csrfToken, setDismissalError)}
              >
                Dismiss
              </BaseButton>
            </div>
          </BConnectedSetupCardContainer>
        </CardBody>
      </Card>
    );
  } else {
    return null;
  }
};

BConnectedSetupCard.displayName = "BConnectedSetupCard";

const dismissReminderFactory = function (csrfToken, setDismissalError) {
  const dispatch = useDispatch();
  const setError = setDismissalError;
  const token = csrfToken;
  return async () => {
    try {
      const response = await fetch("/api/google/dismiss_reminder", {
        method: "POST",
        headers: { "X-CSRF-Token": token },
      });
      if (response.ok) {
        const updateData = { isGoogleReminderDismissed: true };
        dispatch(statusSlice.actions.success(updateData));
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    }
  };
};

const BConnectedSetupCardContainer = styled.div`
  div.sub-header {
    display: flex;
    flex-direction: row;
  }

  div.content-buttons {
    margin-top: 15px;

    .button {
      font-size: 12px;
      font-weight: bold;
      margin-right: 10px;
    }
  }

  div.error-container {
    margin: 0 0 var(--md) 0;
  }

  div.no-bconnected {
    a {
      font-weight: bold;
    }
    p {
      margin-bottom: 10px;
    }
  }
`;

const TailoredBCalIcon = styled(BCalIcon)`
  margin-left: var(--lg);
  width: 22px;
`;

const TailoredBDriveIcon = styled(BDriveIcon)`
  margin-left: var(--lg);
  width: 22px;
`;

const TailoredBMailIcon = styled(BMailIcon)`
  margin-left: var(--lg);
  width: 22px;
`;
