import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import { Spinner } from "components/ui/Spinner";
import { MediumSpacer } from "components/ui/VerticalSpacers";

import { GraduateTransferCredit } from "./GraduateTransferCredit";
import { UgdTransferCredit } from "./UgdTransferCredit";
import { LawTransferCredit } from "./LawTransferCredit";

import { TransferCreditReportLink } from "./TransferCreditReportLink";

export function TransferCreditCardUI({ transferCredit = {} }) {
  const { hasUnits, loadState, law, graduate, undergraduate } = transferCredit;

  if (hasUnits) {
    return (
      <Card data-testid="transfer-credit-card">
        <CardTitle>
          <h2>Transfer Credit</h2>
        </CardTitle>
        <CardBody>
          {loadState === "pending" && <Spinner />}
          {loadState === "success" && (
            <>
              <TransferCreditReportLink />
              <MediumSpacer />
              <LawTransferCredit transferCredits={law} />
              <GraduateTransferCredit transferCredits={graduate} />
              <UgdTransferCredit transferCredits={undergraduate} />
            </>
          )}
        </CardBody>
      </Card>
    );
  }

  return null;
}

TransferCreditCardUI.propTypes = {
  transferCredit: PropTypes.object,
};
