// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".messageHeader___NWbba{display:flex;font-size:12px}.messageHeader___NWbba .icon___Swyr3{font-size:20px;min-width:30px;color:var(--casper)}", "",{"version":3,"sources":["webpack://./src/components/cards/NotificationsCard/MessageHeader.module.scss"],"names":[],"mappings":"AAEA,uBACE,YAAA,CACA,cAAA,CAEA,qCACE,cAAA,CACA,cAAA,CACA,mBAAA","sourcesContent":["@import \"src/react/stylesheets/variables\";\n\n.messageHeader {\n  display: flex;\n  font-size: 12px;\n\n  .icon {\n    font-size: 20px;\n    min-width: 30px;\n    color: var(--casper);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageHeader": "messageHeader___NWbba",
	"icon": "icon___Swyr3"
};
export default ___CSS_LOADER_EXPORT___;
