import React from "react";
import { semesterShape } from "components/propTypes/semesterShape";

const leftColumnPercentage = "28%";
const rightColumnPercentage = "72%";

const headerStyle = {
  margin: "10px 0",
  padding: "0",
};

const examLocationStyle = function (examLocation) {
  if (
    examLocation === "Exam Location TBD" ||
    examLocation === "Exam Information not available at this time."
  ) {
    return {
      fontStyle: "italic",
    };
  } else {
    return null;
  }
};

const styleScheduleItem = function (schedule) {
  if (schedule.timeConflict) {
    return {
      backgroundColor: "var(--bianca)",
      borderLeft: "5px solid var(--buttercup)",
      marginLeft: "-15px",
      padding: "5px 5px 5px 18px",
    };
  } else {
    return {
      padding: "5px 10px 0",
    };
  }
};

export default function StudentFinalExamSemester({ examSemester }) {
  if (examSemester) {
    return (
      <div data-testid="student-final-exam-semester">
        <h3 style={headerStyle}>
          <span>{examSemester.name} Final Exams</span>
        </h3>

        {examSemester.exams &&
          examSemester.exams.schedule &&
          examSemester.exams.schedule.length > 0 && (
            <ol
              data-testid="final-exam-schedule-list"
              style={{ listStyleType: "none", padding: "0" }}
            >
              {examSemester.exams.schedule.map((schedule, scheduleIndex) => (
                <li
                  data-testid="final-exam-schedule-item"
                  key={scheduleIndex}
                  style={styleScheduleItem(schedule)}
                >
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div style={{ width: leftColumnPercentage }}>
                      {schedule.examDate}
                    </div>
                    <div style={{ width: rightColumnPercentage }}>
                      <span style={{ fontWeight: "bold" }}>
                        {schedule.name}
                        {schedule.displaySectionLabel
                          ? " " + schedule.sectionLabel
                          : ""}
                      </span>
                      {schedule.waitlisted && <span> (waitlist)</span>}
                    </div>
                  </div>
                  {schedule.examLocations.map(
                    (examLocation, examLocationIndex) => (
                      <div
                        data-testid="final-exam-schedule-item-location"
                        key={examLocationIndex}
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          color: "var(--dove-gray)",
                          fontSize: "12px",
                        }}
                      >
                        <div style={{ width: leftColumnPercentage }}>
                          {schedule.examTime}
                        </div>
                        <div style={{ width: rightColumnPercentage }}>
                          <span style={examLocationStyle(examLocation)}>
                            {examLocation}
                          </span>
                        </div>
                      </div>
                    )
                  )}
                </li>
              ))}
            </ol>
          )}
      </div>
    );
  } else {
    return null;
  }
}

StudentFinalExamSemester.displayName = "StudentFinalExamSemester";
StudentFinalExamSemester.propTypes = {
  examSemester: semesterShape,
};
