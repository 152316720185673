import React, { useState } from "react";
import PropTypes from "prop-types";
import { BasicTable } from "components/ui/Table";
import { RedWarning } from "components/ui/Icons";
import {
  TextDisclosureButton,
  Disclosure,
  DisclosurePanel,
} from "components/ui/Disclosure";
import CurrentStanding from "./CurrentStanding";
import StandingsHistory from "./StandingsHistory";
import { isLoaded } from "data/utils/statusHelpers";

const standingAlertPresent = (currentStandings) => {
  if (currentStandings && currentStandings.length) {
    return (
      currentStandings.length > 0 && currentStandings[0].statusCode !== "GST"
    );
  } else {
    return false;
  }
};

const AcademicStandings = ({ standingsData }) => {
  const {
    loadState = "idle",
    currentStandings = [],
    learnMoreMessage,
    standingsHistory = [],
  } = standingsData || {};

  const [isOpen, setOpen] = useState(false);

  const hasStandingAlert = standingAlertPresent(currentStandings);
  const standingsLoaded = isLoaded(loadState);
  const hasStandings = currentStandings.length > 0;

  if (standingsLoaded) {
    return (
      <div data-testid="academic-standings">
        <h3 style={{ paddingBottom: "var(--medium-spacing)" }}>Status</h3>

        <Disclosure open={isOpen} onChange={() => setOpen(!isOpen)}>
          <h4>
            {hasStandingAlert && <RedWarning />}
            Academic Status{" "}
            <TextDisclosureButton>
              {isOpen ? "Hide Status" : "Show Status"}
            </TextDisclosureButton>
          </h4>

          <DisclosurePanel>
            {hasStandings ? (
              <>
                <BasicTable>
                  {currentStandings.map((standing, standingIndex) => (
                    <CurrentStanding
                      key={standingIndex}
                      standing={standing}
                      learnMoreMessage={learnMoreMessage}
                    />
                  ))}
                </BasicTable>

                <StandingsHistory standingsHistory={standingsHistory} />
              </>
            ) : (
              <div>You have no academic standing at this time.</div>
            )}
          </DisclosurePanel>
        </Disclosure>
      </div>
    );
  }

  return null;
};

AcademicStandings.displayName = "AcademicStandings";
AcademicStandings.propTypes = {
  standingsData: PropTypes.object,
};

export { AcademicStandings as default, standingAlertPresent };
