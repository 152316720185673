import styled from "styled-components";
import { BasicTable } from "components/ui/Table";

export const HousingTable = styled(BasicTable)`
  td,
  th {
    text-align: left;
    line-height: 1.75;

    &:last-child {
      text-align: right;
    }
  }
`;
