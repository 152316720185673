import React from "react";
import { Link } from "react-router-dom";

import { Page, PageHeader } from "components/ui/Page";
import { MediumSpacer, LargeSpacer } from "components/ui/VerticalSpacers";
import WithMyAcademics from "components/data/WithMyAcademics";
import WithSemesters from "components/data/WithSemesters";
import SemestersContext from "components/contexts/SemestersContext";
import SemesterLink from "components/SemesterLink";
import Spinner from "components/ui/Spinner";
import { BookListCard } from "components/cards/BookListCard";

export function BookListPage() {
  return (
    <Page>
      <WithMyAcademics>
        <WithSemesters>
          <SemestersContext.Consumer>
            {({ currentSemester, loadState }) => {
              if (["pending", "idle"].includes(loadState)) {
                return (
                  <>
                    <MediumSpacer />
                    <Spinner />
                  </>
                );
              }

              if (currentSemester) {
                return (
                  <>
                    <MediumSpacer />
                    <PageHeader style={{ paddingTop: `0` }}>
                      <Link to="/academics">My Academics</Link>
                      <> » </>
                      <SemesterLink />
                      <> » </>
                      Book List
                    </PageHeader>
                    <LargeSpacer />
                    <BookListCard semester={currentSemester} />
                  </>
                );
              }

              return null;
            }}
          </SemestersContext.Consumer>
        </WithSemesters>
      </WithMyAcademics>
    </Page>
  );
}
