'use strict';

angular.module('calcentral.services').factory('httpErrorInterceptorService', function($q) {
  return {
    // Basic idea from http://stackoverflow.com/questions/11971213

    response: function(response) {
      return response;
    },

    responseError: function(response) {
      return $q.reject(response);
    }
  };
});
