import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/data/ReduxProvider";
import AdvisorStatusAndHoldsCard from "components/cards/AdvisorStatusAndHoldsCard";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const NgAdvisorStatusAndHoldsCard = () => (
  <ReduxProvider>
    <Router>
      <Routes>
        <Route path="/user/overview/:uid" element={<AdvisorStatusAndHoldsCard />} />
      </Routes>
    </Router>
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component(
    "advisorStatusAndHolds",
    react2angular(NgAdvisorStatusAndHoldsCard)
  );
