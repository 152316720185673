import React from "react";
import PropTypes from "prop-types";
import { CCTable } from "components/ui/Table";
import { SectionRow } from "./SectionRow";

export function HasGrades({ semester, sections }) {
  return (
    <>
      <h4>Grades for {semester.name}</h4>

      <CCTable>
        <thead>
          <tr>
            <th style={{ width: `60%` }}>Class</th>
            <th style={{ paddingRight: `var(--lg)`, textAlign: `right` }}>
              Units
            </th>
            <th>Grade</th>
          </tr>
        </thead>
        <tbody>
          {sections.map((section) => (
            <SectionRow key={section.ccn} section={section} />
          ))}
        </tbody>
      </CCTable>
    </>
  );
}

HasGrades.propTypes = {
  semester: PropTypes.shape({
    name: PropTypes.string,
  }),
  sections: PropTypes.arrayOf(PropTypes.object),
};
