import { useParams } from "react-router-dom";
import { useFilteredNav } from "./useFilteredNav";

export function useMatchingCategory() {
  const { categoryId } = useParams();
  const filteredNav = useFilteredNav();

  if (filteredNav) {
    return findMatchingCategory(filteredNav, categoryId);
  }
}

function findMatchingCategory(filteredNav, categoryId) {
  if (filteredNav) {
    return filteredNav
      .map((item) =>
        item.categories.find((category) => {
          return category.id === categoryId;
        })
      )
      .find((item) => item !== undefined);
  }

  return false;
}
