import { useCourseParams } from "./useCourseParams";
import { useMyAcademics } from "./useMyAcademics";

const matchingSemesterId = (semesterId) => (semester) =>
  semester.slug === semesterId;

export function useCurrentSemesters() {
  const { semesterId } = useCourseParams();

  const {
    loadState,
    semesters = [],
    teachingSemesters = [],
  } = useMyAcademics();

  return {
    loadState,
    semester: semesters.find(matchingSemesterId(semesterId)),
    teachingSemester: teachingSemesters.find(matchingSemesterId(semesterId)),
  };
}
