import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { isLoaded } from "data/utils/statusHelpers";

import { RedWarning } from "components/ui/Icons";
import { parseISO } from "date-fns";
import { format } from "date-fns-tz";
import Amount from "components/ui/Amount";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  ChevronCircleIcon,
} from "components/ui/ChevronAccordion";

export function Holds({ holdsData, showHeader = true }) {
  const {
    loadState,
    errored = false,
    feed: { holds = [] } = {},
  } = holdsData || {};

  const dataLoaded = isLoaded(loadState);

  const [indices, setIndices] = React.useState([]);

  function toggleItem(toggledIndex) {
    if (indices.includes(toggledIndex)) {
      setIndices(
        indices.filter((currentIndex) => currentIndex !== toggledIndex)
      );
    } else {
      setIndices([...indices, toggledIndex].sort());
    }
  }

  if (dataLoaded) {
    return (
      <div data-testid="holds">
        {showHeader && <h3>Active Holds</h3>}

        {!errored && holds.length > 0 && (
          <>
            <HoldAccordionColumnLabels aria-hidden="true">
              <div className="reason">Reason</div>
              <div className="applied">Applied</div>
              <div className="button-spacer"></div>
            </HoldAccordionColumnLabels>
            <Accordion
              collapsible
              multiple
              index={indices}
              onChange={toggleItem}
            >
              {holds.map((hold, index) => (
                <Hold hold={hold} indices={indices} index={index} key={index} />
              ))}
            </Accordion>
          </>
        )}

        {!errored && holds.length === 0 && (
          <div>You have no active holds at this time.</div>
        )}

        {errored && (
          <div>
            <RedWarning />
            Holds are currently unavailable.
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
}
Holds.displayName = "Holds";
Holds.propTypes = {
  holdsData: PropTypes.object,
  showHeader: PropTypes.bool,
};

function Hold({ hold, indices, index }) {
  if (hold) {
    return (
      <HoldAccordionItem data-testid="hold-accordion-item" role="row">
        {({ hoverActive, setHoverActive }) => (
          <>
            <div className="header" role="heading">
              <HoldAccordionButton
                data-testid="hold-accordion-button"
                hoverActive={hoverActive}
                setHoverActive={setHoverActive}
              >
                <div>
                  <RedWarning />
                  <span className="title">
                    <span style={{ display: "none" }}>Reason: </span>
                    {hold.reason.description}
                  </span>
                </div>
                <div className="date">
                  <span style={{ display: "none" }}>Date Applied: </span>
                  {hold.fromDate
                    ? format(parseISO(hold.fromDate), "MM/dd/yy")
                    : hold.fromTerm.name}
                </div>
                <div>
                  <ChevronCircleIcon
                    $hoverActive={hoverActive}
                    $isOpen={indices.includes(index)}
                  />
                </div>
              </HoldAccordionButton>
            </div>
            <HoldAccordionPanel>
              {hold.reason.formalDescription && (
                <div style={{ whiteSpace: "pre-line" }}>
                  {hold.reason.formalDescription}
                </div>
              )}

              {hold.amountRequired > 0 && (
                <div>
                  <strong>Amount:</strong>{" "}
                  <Amount removeColor={true} value={hold.amountRequired} />
                </div>
              )}

              {hold.fromTerm && hold.fromTerm.name && (
                <div>
                  <strong>Start Term:</strong> {hold.fromTerm.name}
                </div>
              )}

              {hold.contact &&
                hold.contact.description &&
                hold.contact.description.trim().length > 0 && (
                  <div>
                    <strong>Contact:</strong>
                    {hold.contact.description}
                  </div>
                )}
            </HoldAccordionPanel>
          </>
        )}
      </HoldAccordionItem>
    );
  }

  return null;
}

Hold.displayName = "Hold";
Hold.propTypes = {
  hold: PropTypes.object,
  indices: PropTypes.arrayOf(PropTypes.number),
  index: PropTypes.number,
};

const HoldAccordionPanel = styled(AccordionPanel)`
  padding: 0 10px;

  > div {
    padding: 5px 0;
  }
`;

const HoldAccordionItem = styled(AccordionItem)`
  .header {
    button {
      display: grid;
      div:nth-child(3) {
        text-align: right;
      }
      grid-template-columns: 2fr 1fr 20px;
      margin: 0;
      padding: 0;

      .title {
        padding-left: var(--sm);
      }
      .date {
        padding-right: var(--sm);
        text-align: right;
      }
    }
  }

  & + & {
    border-top: none;
  }
`;

const HoldAccordionButton = styled(AccordionButton)`
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 0;
  padding-right: 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const HoldAccordionColumnLabels = styled.div`
  color: var(--dove-gray);
  display: grid;
  div:nth-child(3) {
    text-align: right;
  }
  grid-template-columns: 2fr 1fr 20px;
  margin: 0;
  padding: 0;

  .reason {
    font-weight: bold;
  }

  .applied {
    font-weight: bold;
    padding-right: var(--sm);
    text-align: right;
  }
`;
