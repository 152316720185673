import {
  FETCH_SIR_STATUS_START,
  FETCH_SIR_STATUS_SUCCESS,
  FETCH_SIR_STATUS_FAILURE,
} from "../action-types";

export const fetchSirStatusStart = () => ({
  type: FETCH_SIR_STATUS_START,
});

export const fetchSirStatusSuccess = (sirStatus) => ({
  type: FETCH_SIR_STATUS_SUCCESS,
  value: sirStatus,
});

export const fetchSirStatusFailure = (error) => ({
  type: FETCH_SIR_STATUS_FAILURE,
  value: error,
});

export const fetchSirStatus = () => {
  return (dispatch, getState) => {
    const { sirStatus } = getState();

    if (sirStatus.loaded || sirStatus.isLoading) {
      return new Promise((resolve, _reject) => resolve(sirStatus));
    } else {
      dispatch(fetchSirStatusStart());

      const url = "/api/my/sir_statuses";
      // const url = "/dummy/json/sir_statuses_initiated.json";
      // const url = "/dummy/json/sir_statuses_received.json";
      // const url = "/dummy/json/sir_statuses_completed.json";
      return fetch(url, { method: "GET" })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            const failure = {
              status: "There was a problem",
              statusText: "Please check again later",
            };
            dispatch(fetchSirStatusFailure(failure));
          }
        })
        .then((data) => {
          dispatch(fetchSirStatusSuccess(data));
        })
        .catch((error) => {
          if (error.response) {
            const failure = {
              status: error.response.status,
              statusText: error.response.statusText,
            };
            dispatch(fetchSirStatusFailure(failure));
          }
        });
    }
  };
};
