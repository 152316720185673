import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import StatusAndHoldsCard from "components/cards/StatusAndHoldsCard";

const NgStatusAndHoldsCard = () => (
  <ReduxProvider>
    <StatusAndHoldsCard />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component(
    "statusAndHolds",
    react2angular(NgStatusAndHoldsCard)
  );
