import { useState } from "react";
import React from "react";
import PropTypes from "prop-types";
import LightText from "../../ui/LightText";
import { MailtoLink } from "components/ui/MailtoLink";

export const FacultyCommitteeMember = ({
  member: { email, name, photo, primaryDepartment, serviceRange } = {},
}) => {
  const [isPhotoError, setPhotoError] = useState(false);

  return (
    <tr>
      <td>
        <div style={{ display: `flex` }}>
          <div style={{ width: `90px` }}>
            <div className="committees-picture">
              {photo && !isPhotoError && (
                <img
                  src={photo}
                  onError={() => {
                    setPhotoError(true);
                  }}
                />
              )}
              {((photo && isPhotoError) || !photo) && (
                <div
                  className="committees-image-unavailable"
                  title="Photo unavailable"
                >
                  <span className="visuallyhidden">
                    No Official Photo is Available
                  </span>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              flexGrow: `1`,
              paddingLeft: `var(--sm)`,
              marginBottom: `var(--lg)`,
              textAlign: `left`,
            }}
          >
            <div>
              <strong>
                <span>{name}</span>
              </strong>
              <div>
                <MailtoLink emailAddress={email}>{email}</MailtoLink>
              </div>
              <div>
                <span>{primaryDepartment}</span>
              </div>
              {serviceRange && (
                <LightText style={{ paddingBottom: "var(--xl-spacing)" }}>
                  Service:&nbsp;{serviceRange}
                </LightText>
              )}
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

FacultyCommitteeMember.displayName = "FacultyCommitteeMember";
FacultyCommitteeMember.propTypes = {
  member: PropTypes.object,
};
