import React from "react";
import styled from "styled-components";

import { DisclosureButton } from "@reach/disclosure";
import { ChevronCircleIcon } from "components/ui/ChevronCircleIcon";

export const ChevronCircleButton = () => (
  <ChevronCircleButtonStyles>
    <ChevronCircleIcon />
  </ChevronCircleButtonStyles>
);

const ChevronCircleButtonStyles = styled(DisclosureButton)`
  display: inline-flex;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
`;
