import React from "react";
import PropTypes from "prop-types";

import {
  ChevronCircleIcon,
  Disclosure,
  DisclosureContainer,
  DisclosureButton,
  DisclosurePanel,
} from "components/ui/ChevronDisclosure";

import styled from "styled-components";
import APILink from "components/ui/APILink";
import WithAPILinkData from "components/ui/WithAPILinkData";

import { isLoaded } from "data/utils/statusHelpers";

import ResidencyIcon from "./ResidencyIcon";

const Residency = ({ studentAcademicRoles, studentRoles, residencyData }) => {
  const { student: isStudent } = studentRoles || {};

  const {
    current: {
      summerVisitor: isCurrentSummerVisitor,
      haasMastersFinEng: isCurrentHaasMasterFinEng,
      haasExecMba: isCurrentHaasExecMba,
      haasEveningWeekendMba: isCurrentHaasEveningWeekendMba,
    } = {},
    historical: {
      summerVisitor: isHistoricalSummerVisitor,
      degreeSeeking: isHistoricalDegreeSeeking,
    } = {},
  } = studentAcademicRoles || {};

  const { loadState, residency = {} } = residencyData || {};

  const dataLoaded = isLoaded(loadState);

  const isBlacklistedAcademicRole =
    isCurrentHaasEveningWeekendMba ||
    isCurrentHaasExecMba ||
    isCurrentHaasMasterFinEng ||
    isCurrentSummerVisitor;

  const isNonDegreeSeekingSummerVisitor =
    isHistoricalSummerVisitor && !isHistoricalDegreeSeeking;
  const showResidency =
    isStudent && !isNonDegreeSeekingSummerVisitor && !isBlacklistedAcademicRole;

  const {
    official: { description: officialDescription, code: officialCode } = {},
    fromTerm: { label: fromTermLabel } = {},
    tuitionException: { description: tuitionExceptionDescription } = {},
    statementOfLegalResidenceStatus: { code: slrStatusCode } = {},
    message: { description: messageDescription } = {},
  } = residency;

  const [isOpen, setOpen] = React.useState(false);

  if (dataLoaded && showResidency) {
    return (
      <div data-testid="residency" style={{ marginBottom: "16px" }}>
        {officialDescription && (
          <>
            <h4>California Residency</h4>

            <Disclosure isOpen={isOpen} onChange={() => setOpen(!isOpen)}>
              <ResidencyDisclosureContainer>
                {({ hoverActive, setHoverActive }) => (
                  <>
                    <ResidencyDisclosureButton setHoverActive={setHoverActive}>
                      <div className="header">
                        <div className="title">
                          <ResidencyIcon officialCode={officialCode} />
                          <span data-testid="residency-official-description">
                            {officialDescription}
                          </span>
                        </div>
                        <div className="chevron">
                          <ChevronCircleIcon $hoverActive={hoverActive} />
                        </div>
                      </div>
                    </ResidencyDisclosureButton>

                    <DisclosurePanel>
                      <div style={{ margin: "10px 0 4px" }}>
                        <div style={{ marginBottom: "8px" }}></div>

                        <ExpandedText className="expanded-text">
                          {fromTermLabel && (
                            <div>Term applied: {fromTermLabel}</div>
                          )}
                          {tuitionExceptionDescription && (
                            <div>Exemption: {tuitionExceptionDescription}</div>
                          )}
                          {slrStatusCode === "N" && (
                            <WithAPILinkData urlID="UC_SR_SLR_STUDENT_INFO">
                              {(link) => (
                                <div style={{ padding: "5px 0" }}>
                                  <APILink
                                    link={{
                                      ...link,
                                      name: "Submit Statement of Legal Residence",
                                    }}
                                  />
                                </div>
                              )}
                            </WithAPILinkData>
                          )}
                          {slrStatusCode !== "N" && (
                            <WithAPILinkData urlID="UC_SR_SLR_STUDENT_INFO">
                              {(link) => (
                                <div style={{ padding: "5px 0" }}>
                                  <APILink
                                    link={{
                                      ...link,
                                      name: "Access Statement of Legal Residence",
                                    }}
                                  />
                                </div>
                              )}
                            </WithAPILinkData>
                          )}
                          {messageDescription && (
                            <div
                              style={{ padding: "5px 0" }}
                              dangerouslySetInnerHTML={{
                                __html: messageDescription,
                              }}
                            ></div>
                          )}
                        </ExpandedText>
                      </div>
                    </DisclosurePanel>
                  </>
                )}
              </ResidencyDisclosureContainer>
            </Disclosure>
          </>
        )}
      </div>
    );
  } else {
    return null;
  }
};

const ResidencyDisclosureButton = styled(DisclosureButton)`
  width: 100%;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;

  &:hover, &:focus {
    text-decoration: none;
  }

  .header {
    display: grid;
    div:nth-child(2) {
      text-align: right;
    }
    grid-template-columns: 2fr 1fr;
    line-height: 25px;

    .title {
      text-align: left;
    }
  }

  .chevron {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const ResidencyDisclosureContainer = styled(DisclosureContainer)`
  border-bottom: 1px solid var(--gallery);
  margin: var(--sm) 0;
`;

const ExpandedText = styled.div`
  margin-left: 10px;
`;

Residency.displayName = "Residency";
Residency.propTypes = {
  residencyData: PropTypes.object,
  studentAcademicRoles: PropTypes.object,
  studentRoles: PropTypes.object,
};

export { Residency as default };
