// Provides career codes from all courses within all semesters
const collectSemesterCourseAcademicCareerCodes = function (semesters) {
  var allCourseCareerCodes = [];
  semesters.forEach((semester) => {
    if (semester.exams && semester.exams.courseCareerCodes) {
      allCourseCareerCodes = allCourseCareerCodes.concat(
        semester.exams.courseCareerCodes
      );
    }
  });
  allCourseCareerCodes = allCourseCareerCodes.flat();
  const uniqueCourseCareerCodes = [...new Set(allCourseCareerCodes)];
  return uniqueCourseCareerCodes;
};

// used to filter a list of semesters to only current or future
// non-Summer semesters
const excludePastOrSummerSemesters = function (semesters) {
  return semesters.filter((semester) => {
    return semester.termCode !== "C" && semester.timeBucket !== "past";
  });
};

// returns true if any exam schedules indicate a time conflict
export const detectSemesterTimeConflicts = function (semesters) {
  const conflictFound = semesters.find((semester) => {
    if (semester && semester.exams && semester.exams.schedule) {
      return semester.exams.schedule.find((exam) => {
        return exam.timeConflict;
      });
    }
    return false;
  });
  return !!conflictFound;
};

// returns only semesters with exam schedules present
export const filterExamSemesters = function (semesters) {
  return semesters.filter((semester) => {
    if (semester && semester.exams && semester.exams.schedule) {
      return semester.exams.schedule.length > 0;
    } else {
      return false;
    }
  });
};

// provides object with booleans indicating the presence of courses, in addition
// to certain course careers (e.g. Undergraduate, Law, Grad)
export const getCourseCareerStatuses = function (semesters) {
  const currentAndFutureSemesters = excludePastOrSummerSemesters(semesters);
  const academicCareerCodes = collectSemesterCourseAcademicCareerCodes(
    currentAndFutureSemesters
  );
  const ugrdPresent = academicCareerCodes.includes("UGRD");
  const lawPresent = academicCareerCodes.includes("LAW");
  const gradPresent = academicCareerCodes.includes("GRAD");

  return {
    ugrdCoursesPresent: ugrdPresent,
    lawCoursesPresent: lawPresent,
    gradCoursesPresent: gradPresent,
    gradCoursesOnlyPresent: gradPresent && !lawPresent && !ugrdPresent,
    coursesPresent: ugrdPresent || gradPresent || lawPresent,
  };
};

export const sortSemesters = function (semesters) {
  return semesters.slice().sort((a, b) => {
    if (a.termId === b.termId) {
      return 0;
    }
    return a.termId > b.termId ? 1 : -1;
  });
};
