import React from "react";
import PropTypes from "prop-types";
import { Disclosure, DisclosurePanel } from "@reach/disclosure";
import { ChevronCircleButton } from "components/ui/Disclosure/ChevronCircleButton";

export const CreditsContainer = ({
  title,
  detailedRows = [],
  value,
  nonAdjusted = null,
}) => {
  const rows = detailedRows.filter((detailedRow) => detailedRow?.units !== 0.0);

  return (
    <Disclosure>
      <tbody>
        <tr>
          <td>{title}</td>
          <td
            style={{
              display: `flex`,
              justifyContent: `flex-end`,
              gap: `var(--tiny)`,
            }}
          >
            {rows.length > 0 ? (
              <>
                {value}
                <ChevronCircleButton />
              </>
            ) : (
              <span style={{ paddingRight: `22px` }}>{value}</span>
            )}
          </td>
        </tr>

        {rows.map((detail, index) => {
          if (!detail.exam) {
            return (
              <DisclosurePanel as="tr" key={index}>
                <td>
                  <i>
                    <span>{detail.school}</span>
                    {detail.requirementDesignation && (
                      <span>{detail.requirementDesignation}</span>
                    )}
                  </i>
                </td>
                <td align="right">{detail.units.toFixed(3)}</td>
              </DisclosurePanel>
            );
          } else {
            return (
              <DisclosurePanel as="tr" key={index}>
                <td>
                  <i>{detail.exam}</i>
                </td>
                <td align="right">{detail.units.toFixed(3)}</td>
              </DisclosurePanel>
            );
          }
        })}

        {nonAdjusted > 0 && (
          <DisclosurePanel as="tr">
            <td colSpan={2} align="right">
              <i>
                {"(Non-Adjusted Course Units: " + nonAdjusted.toFixed(3) + ")"}
              </i>
            </td>
          </DisclosurePanel>
        )}
      </tbody>
    </Disclosure>
  );
};

CreditsContainer.displayName = "CreditsContainer";
CreditsContainer.propTypes = {
  title: PropTypes.string,
  detailedRows: PropTypes.array,
  nonAdjusted: PropTypes.number,
  value: PropTypes.string,
};
