import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { SubClassses } from "./SubClasses";
import { listingShape } from "../../propTypes/listingShape";
import { courseShape } from "../../propTypes/courseShape";

export function CourseListing({ course, listing }) {
  const { course_code, courseCodeSection } = listing;
  const { site_url, name, subclasses, waitlisted, waitlistPosition } = course;

  return (
    <ListingsContainer>
      <Link to={site_url}>
        <strong style={{ fontStyle: waitlisted ? "italic" : "inherit" }}>
          {course_code}
          {courseCodeSection}
        </strong>
      </Link>

      {waitlisted && waitlistPosition && (
        <div style={{ color: `var(--dusty-gray)` }}>
          - #{waitlistPosition} on the wait list
        </div>
      )}

      <div
        style={{
          fontSize: `12px`,
          color: `var(--dusty-gray)`,
          fontStyle: waitlisted ? "italic" : "inherit",
        }}
      >
        {name}
      </div>

      <SubClassses subclasses={subclasses} />
    </ListingsContainer>
  );
}

CourseListing.displayName = "CourseListing";
CourseListing.propTypes = {
  course: courseShape,
  listing: listingShape,
};

export const ListingsContainer = styled.div`
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }

  a {
    font-style: ${({ waitlisted }) => (waitlisted ? "italic" : "inherit")};
  }
`;
