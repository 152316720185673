import React from "react";
import PropTypes from "prop-types";

import TransactionHistory from "./TransactionHistory";
import MoreDetails from "./MoreDetails";

import styled from "styled-components";

const ItemDetails = ({ item }) => {
  return (
    <ItemDetailsStyled
      data-testid="item-details"
      onClick={(e) => e.stopPropagation()}
    >
      <TransactionHistory item={item} />
      <MoreDetails item={item} />
    </ItemDetailsStyled>
  );
};
ItemDetails.displayName = "ItemDetails";
ItemDetails.propTypes = {
  item: PropTypes.object,
};

export default ItemDetails;
export const ItemDetailsStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`;
