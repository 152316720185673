import styled from "styled-components";
import { CCTable } from "components/ui/Table";

export const TransferCreditTable = styled(CCTable)`
  & + & {
    border-top: 1px solid var(--gallery);
    margin-top: var(--lg);
    padding-top: var(--lg);
  }

  thead tr th:last-child {
    padding-right: 22px;
    text-align: right;
  }

  tbody {
    &:hover {
      background-color: var(--foam);
    }

    & + tbody {
      tr:first-child td {
        border-top: 1px solid var(--gallery);
      }
    }

    tr[data-reach-disclosure-panel] td:last-child {
      padding-right: 22px;
    }
  }
`;
