import React from "react";
import { RequireDelegate } from "components/RequireDelegate";
import { BulletList } from "components/ui/Lists";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import WithAPILink from "components/ui/WithAPILink";

import {
  UC_CC_DA_QL_DELEG_GUIDE,
  UC_CC_DA_QL_CAL_PARENTS,
  UC_CC_DA_QL_IMPORT_DATES,
  UC_CC_DA_QL_VISIT_CAMPUS,
  UC_CC_DA_QL_JOBS_CAREERS,
  UC_CC_DA_QL_HOUSING,
  UC_CC_DA_QL_FINANCIAL_INFO,
  UC_CC_DA_QL_ACADEMICS,
  UC_CC_DA_QL_ACADEMIC_CAL,
  UC_CC_DA_QL_NEWSCENTER,
  UC_CC_DA_QL_DAILY_CAL
} from "functions/urlIds";

export function DelegateQuickLinksCard() {
  return (
    <RequireDelegate>
      <Card data-testid="delegate-quick-links-card">
        <CardTitle>
          <h2>Quick Links</h2>
        </CardTitle>
        <CardBody>
          <h3>Reference Websites</h3>
          <BulletList>
            <li>
              <WithAPILink urlID={UC_CC_DA_QL_DELEG_GUIDE} />
            </li>
            <li>
              <WithAPILink urlID={UC_CC_DA_QL_CAL_PARENTS} />
              <BulletList>
                <li>
                  <WithAPILink urlID={UC_CC_DA_QL_IMPORT_DATES} />
                </li>
                <li>
                  <WithAPILink urlID={UC_CC_DA_QL_VISIT_CAMPUS} />
                </li>
                <li>
                  <WithAPILink urlID={UC_CC_DA_QL_JOBS_CAREERS} />
                </li>
                <li>
                  <WithAPILink urlID={UC_CC_DA_QL_HOUSING} />
                </li>
                <li>
                  <WithAPILink urlID={UC_CC_DA_QL_FINANCIAL_INFO} />
                </li>
                <li>
                  <WithAPILink urlID={UC_CC_DA_QL_ACADEMICS} />
                </li>
              </BulletList>
            </li>
            <li>
              <WithAPILink urlID={UC_CC_DA_QL_ACADEMIC_CAL} />
            </li>
            <li>
              <WithAPILink urlID={UC_CC_DA_QL_NEWSCENTER} />
            </li>
            <li>
              <WithAPILink urlID={UC_CC_DA_QL_DAILY_CAL} />
            </li>
          </BulletList>
        </CardBody>
      </Card>
    </RequireDelegate>
  );
}
