import React from "react";
import { useSelector } from "react-redux";
import { HashLink as Link } from "react-router-hash-link";
import { LoanCategory } from "./LoanCategory";
import { LoanCategoryTable } from "./LoanCategoryTable";
import currentUserState from "utils/currentUserState";
import formatCurrency from "functions/formatCurrency";
import ColumnTitle from "./ColumnTitle";
export function CumulativeLoansOverview() {
  const { loans, loansSummary: { amountOwed, estMonthlyPayment } = {} } =
    useSelector((state) => currentUserState(state).loanHistoryCumulative);
  const scrollToSection = (finding) => {
    const headers = document.querySelectorAll("h3");
    const targetHeader = Array.from(headers).find((header) =>
      header.textContent.includes(finding)
    );

    if (targetHeader) {
      targetHeader.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <div>
      <p style={{ marginTop: 0 }}>
        To see definitions for financial terms, see the{" "}
        <Link to="#resources">Resources</Link> section at the bottom of this
        card.
      </p>

      {loans.map((loanCategory) => (
        <LoanCategory key={loanCategory.category} loanCategory={loanCategory} />
      ))}

      <h3>Grand Total</h3>
      <LoanCategoryTable grandTotals={true}>
        <thead>
          <tr>
            <th></th>
            <th>Total Calculated Debt</th>
            <ColumnTitle
              title={"Est. Monthly Payment"}
              scrollToSection={scrollToSection}
              finding={"Estimated Payment Disclaimer"}
            />
          </tr>
        </thead>
        <tfoot>
          <tr>
            <td>Calculated Cumulative Debt</td>
            <td>{formatCurrency(amountOwed)}</td>
            <td>{formatCurrency(estMonthlyPayment)}</td>
          </tr>
        </tfoot>
      </LoanCategoryTable>
    </div>
  );
}
