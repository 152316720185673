import React from "react";
import { useSelector } from "react-redux";
import { HashLink as Link } from "react-router-hash-link";
import currentUserState from "utils/currentUserState";
import formatCurrency from "functions/formatCurrency";
import styled from "styled-components";

export function AidYearOverview() {
  const { aidYears } = useSelector(
    (state) => currentUserState(state).loanHistoryAidYears
  );

  return (
    <div>
      <p style={{ marginTop: 0 }}>
        To see definitions for financial terms, see the{" "}
        <Link to="#resources">Resources</Link> section at the bottom of this
        card.
      </p>

      {aidYears &&
        aidYears.map((aidYear) => (
          <AidYearOverviewStyles key={aidYear.aidYearFormatted}>
            <div className="loan-category-description">
              <span>
                <h3 className="aid-year-header">
                  {aidYear.aidYearFormatted} Aid Year
                </h3>
              </span>
              {aidYear.loans.length === 0 && (
                <p>
                  No loan information for the {aidYear.aidYearFormatted} aid
                  year.
                </p>
              )}
            </div>
            {aidYear.loans.length > 0 && (
              <div>
                <table className="table hide-for-small">
                  <thead>
                    <tr>
                      <th>Loan Type</th>
                      <th>Loan Name</th>
                      <th className="text-right">
                        <span>Borrowed</span>
                      </th>
                      <th className="text-right">
                        <span>Interest Rate</span>
                      </th>
                      <th className="text-right">
                        <span>Est. Monthly Payment</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {aidYear.loans.map((loan, index) => (
                      <tr key={index}>
                        <td>{loan.loanCategory}</td>
                        <td>{loan.loanDescr}</td>
                        <td className="text-right">
                          {formatCurrency(loan.amountOwed)}
                        </td>
                        <td className="text-right">
                          {loan.interestRate
                            ? `${loan.interestRate} %`
                            : "Unknown"}
                        </td>
                        <td className="text-right">
                          {formatCurrency(loan.estMonthlyPayment)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="2">
                        <h4>{aidYear.aidYearFormatted} Total</h4>
                      </td>
                      <td className="text-right">
                        <h4>{formatCurrency(aidYear.totalAmountOwed)}</h4>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
                
                <div className="mobile-loans show-for-small-only">
                  {aidYear.loans.map((loan, index) => (
                    <div className="mobile-loan-item" key={index}>
                      <div className="mobile-loan-row">
                        <div className="mobile-loan-label">Loan Type</div>
                        <div className="mobile-loan-value">{loan.loanCategory}</div>
                      </div>
                      <div className="mobile-loan-row">
                        <div className="mobile-loan-label">Loan Name</div>
                        <div className="mobile-loan-value">{loan.loanDescr}</div>
                      </div>
                      <div className="mobile-loan-row">
                        <div className="mobile-loan-label">Borrowed</div>
                        <div className="mobile-loan-value">{formatCurrency(loan.amountOwed)}</div>
                      </div>
                      <div className="mobile-loan-row">
                        <div className="mobile-loan-label">Interest Rate</div>
                        <div className="mobile-loan-value">
                          {loan.interestRate ? `${loan.interestRate} %` : "Unknown"}
                        </div>
                      </div>
                      <div className="mobile-loan-row">
                        <div className="mobile-loan-label">Est. Monthly Payment</div>
                        <div className="mobile-loan-value">{formatCurrency(loan.estMonthlyPayment)}</div>
                      </div>
                    </div>
                  ))}
                  
                  <div className="mobile-loan-total">
                    <div className="mobile-loan-row">
                      <div className="mobile-loan-label">
                        <h4>{aidYear.aidYearFormatted} Total</h4>
                      </div>
                      <div className="mobile-loan-value">
                        <h4>{formatCurrency(aidYear.totalAmountOwed)}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </AidYearOverviewStyles>
        ))}
    </div>
  );
}

const AidYearOverviewStyles = styled.div`
  .aid-year-header {
    display: inline-block;
    margin-bottom: 8px;
  }

  .loan-category-description {
    padding: 0 0px;
  }

  .text-right {
    text-align: right;
  }

  table {
    padding: 0 15px;
    table-layout: fixed;

    h4 {
      margin: 0;
    }

    td,
    th {
      border-bottom: 1px solid var(--gallery);
      font-size: 12px;
      vertical-align: middle;
      text-align: left;
      white-space: nowrap;

      &:nth-child(1),
      &:nth-child(4) {
        width: 15%;
      }
      &:nth-child(2) {
        width: 35%;
      }
      &:nth-child(3) {
        width: 20%;
      }
      &:nth-child(4) {
        min-width: 105px;
      }
      &:nth-child(5) {
        width: 15%;
      }
    }
    
    tr {
      height: 30px;
    }
  }

  .mobile-loans {
    margin: 0 15px;
  }
  
  .mobile-loan-item {
    margin-bottom: 30px;
    border-bottom: 1px solid var(--gallery);
    padding-bottom: 15px;
  }
  
  .mobile-loan-row {
    display: flex;
    margin-bottom: 0;
    border-bottom: 1px solid var(--gallery);
    padding: 12px 0;
  }
  
  .mobile-loan-label {
    font-weight: 500;
    flex: 0 0 50%;
    padding-right: 10px;
  }
  
  .mobile-loan-value {
    flex: 0 0 50%;
  }
  
  .mobile-loan-total {
    margin-top: 20px;
    border-top: 2px solid var(--gallery);
    padding-top: 15px;
  }
  
  .mobile-loan-total .mobile-loan-row {
    border-bottom: none;
  }
  
  .show-for-small-only {
    @media (min-width: 768px) {
      display: none;
    }
  }
`;
