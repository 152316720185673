import React from "react";

import { fetchTransferCredit } from "data/actions/transferCreditActions";
import { fetchAdvisingTransferCredit } from "data/slices/users/advisingTransferCredit";
import { useSlice } from "components/hooks/useSlice";
import { useAdvisingStudent } from "components/hooks/useAdvisingStudent";
import { dispatchFunction } from "components/hooks/dispatchFunction";
import { dispatchAdvisingStudentSlice } from "components/hooks/dispatchAdvisingStudentSlice";
import { TransferCreditCardUI } from "./TransferCreditCard/TransferCreditCardUI";

export function TransferCreditCard() {
  dispatchFunction(fetchTransferCredit);
  const myTransferCredit = useSlice("myTransferCredit");

  return <TransferCreditCardUI transferCredit={myTransferCredit} />;
}

export function AdvisingTransferCreditCard() {
  dispatchAdvisingStudentSlice(fetchAdvisingTransferCredit);

  const transferCredit = useAdvisingStudent(
    ({ advisingTransferCredit }) => advisingTransferCredit
  );

  return <TransferCreditCardUI transferCredit={transferCredit} />;
}
