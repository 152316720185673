import React from "react";
import PropTypes from "prop-types";
import { DisclosurePanel } from "components/ui/ChevronDisclosure";
import { DisclosureContents } from "./DisclosureContents";

export const CommitteeMembersDisclosure = ({ committeeMembers }) => {
  const {
    chair,
    coChairs,
    academicSenate,
    insideMembers,
    outsideMembers,
    additionalReps,
  } = committeeMembers;

  return (
    <DisclosurePanel>
      <div
        style={{ display: `flex`, flexDirection: `column`, gap: `var(--md)` }}
      >
        <DisclosureContents label="Chair" members={chair} />
        <DisclosureContents label="Co-Chair" members={coChairs} />
        <DisclosureContents
          label="Academic Senate Representative"
          members={academicSenate}
        />
        <DisclosureContents label="Inside Member" members={insideMembers} />
        <DisclosureContents label="Outside Member" members={outsideMembers} />
        <DisclosureContents
          label="Additional Representatives"
          members={additionalReps}
        />
      </div>
    </DisclosurePanel>
  );
};

CommitteeMembersDisclosure.propTypes = {
  committeeMembers: PropTypes.object,
};
