import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import { fetchAcademicHigherDegreeCommittees } from "data/slices/users/academicHigherDegreeCommittees";
import currentUserState from "utils/currentUserState";
import { CommitteeToggle } from "./FacultyHigherDegreeCommitteesCard/CommitteeToggle";
import { FacultyCommittee } from "./FacultyHigherDegreeCommitteesCard/FacultyCommittee";

import styled from "styled-components";
import photoUnavailableOfficial from "assets/images/photo_unavailable_official_72x96.svg";
import { useFeatures } from "../hooks/useFeatures";
import { useSlice } from "../hooks/useSlice";
import { useRoles } from "../hooks/useRoles";

export function FacultyHigherDegreeCommitteesCard() {
  const { csCommittees } = useFeatures();
  const { delegateActingAsUid } = useSlice("myStatus");
  const { faculty } = useRoles();

  const dispatch = useDispatch();

  const currentUID = useSelector((state) => state.currentUID);
  const academicHigherDegreeCommittees = useSelector(
    (state) => currentUserState(state).academicHigherDegreeCommittees
  );

  useEffect(() => {
    if (currentUID) {
      dispatch(fetchAcademicHigherDegreeCommittees(currentUID));
    }
  }, [dispatch, currentUID]);

  const { loadState, facultyCommittees } = academicHigherDegreeCommittees || {};
  const [isActive, setActive] = useState(true);

  if (loadState !== "success") {
    return null;
  }

  const active = facultyCommittees.active;
  const completed = facultyCommittees.completed;
  const numberOfActiveCommittees = active.length;
  const numberOfCompletedCommittees = completed.length;
  const numberOfCommittees =
    numberOfActiveCommittees + numberOfCompletedCommittees;
  let cardTitle =
    numberOfCommittees === 0 || numberOfCommittees === 1
      ? "Higher Degree Committee"
      : "Higher Degree Committees";

  const showCard =
    csCommittees && faculty && !delegateActingAsUid && numberOfCommittees > 0;

  if (showCard) {
    return (
      <Card data-testid="faculty-higher-degree-committees-card">
        <CardTitle>
          <h2>{cardTitle}</h2>
        </CardTitle>
        <CardBody style={{ padding: "0" }}>
          <FacultyHigherDegreeCommitteesStyles>
            <CommitteeToggle
              isActive={isActive}
              setActive={setActive}
              numberOfActiveCommittees={numberOfActiveCommittees}
              numberOfCompletedCommittees={numberOfCompletedCommittees}
            />
            <FacultyCommittees
              committeesGroup={active}
              display={isActive}
              isActive={isActive}
            />
            <FacultyCommittees
              committeesGroup={completed}
              display={!isActive}
              isActive={isActive}
            />
          </FacultyHigherDegreeCommitteesStyles>
        </CardBody>
      </Card>
    );
  }

  return null;
}

function FacultyCommittees({ committeesGroup, display, isActive }) {
  return (
    <div
      className="widget-padding"
      style={{ display: display ? "block" : "none" }}
    >
      <div className="committees-section">
        <ul className="committees-list">
          {committeesGroup.map((committee, index) => (
            <FacultyCommittee
              key={index}
              committee={committee}
              isActive={isActive}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

FacultyCommittees.propTypes = {
  committeesGroup: PropTypes.array,
  display: PropTypes.bool,
  isActive: PropTypes.bool,
};

function LegendIcon({ name, style = {} }) {
  return (
    <i className={`fa fa-${name}`} aria-hidden={true} style={{ ...style }}></i>
  );
}

LegendIcon.propTypes = {
  name: PropTypes.string,
  style: PropTypes.object,
};

const FacultyHigherDegreeCommitteesStyles = styled.div`
  .chevron-icon {
    margin-left: auto;
    margin-bottom: auto;
  }

  .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .committees-section {
    margin-bottom: 16px;
  }

  .committees-list {
    > li {
      border-bottom: 1px solid var(--gallery);
      padding: 0;

      ul.cc-milestone-attempts {
        margin-left: 0;
      }
    }
  }

  ul {
    padding-left: 0;
  }

  .widget-padding {
    padding: 0 var(--lg) 0 var(--lg);
  }

  .committees-content {
    text-align: left;
    line-height: 1.5;
  }

  .committees-picture {
    margin-right: var(--md);
    padding: 0 0 var(--sm) 0;
    img {
      height: 96px;
      width: 72px;
    }
  }

  .committees-image-unavailable {
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;

    background-image: url("${photoUnavailableOfficial}");
    height: 96px;
    margin: 0 auto;
    width: 72px;
  }

  .toggle-link {
    cursor: pointer;
    font-weight: bold;
    padding: var(--lg);
    padding-bottom: 10px;
    padding-top: 10px;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;
