import React from "react";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import { SplashPageResourceLinks } from "components/pages/SplashPage/SplashPageResourceLinks";

function NgSplashPageResourceLinks() {
  return (
    <ReduxProvider>
      <SplashPageResourceLinks />
    </ReduxProvider>
  );
}

angular
  .module("calcentral.react")
  .component("splashPageResourceLinks", react2angular(NgSplashPageResourceLinks));


