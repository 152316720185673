import React, { useEffect } from "react";
import fetchServiceAlerts from "data/actions/serviceAlertsActions";

import SplashContainer from "components/ui/SplashContainer";
import SplashAlert from "components/ServiceAlerts/SplashAlert";
import SpinnerIf from "components/ui/SpinnerIf";

import { useDispatch, useSelector } from "react-redux";

export const SplashPageAlerts = () => {
  const { serviceAlerts, loadState, csrfToken } = useSelector((state) => {
    const {
      csrfToken,
      serviceAlerts: { data = [], loadState = "pending" },
    } = state;

    return {
      serviceAlerts: data,
      loadState,
      csrfToken,
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (csrfToken) {
      dispatch(fetchServiceAlerts(csrfToken));
    }
  }, [dispatch, csrfToken]);

  const splashAlerts = serviceAlerts.filter((alert) => alert.splash);

  return (
    <SpinnerIf loading={loadState === "pending"}>
      {splashAlerts.length > 0 && (
        <SplashContainer data-testid="splash-page-alerts">
          <div className="cc-splash-page-note-title">CalCentral News</div>
          {splashAlerts.map((alert) => (
            <SplashAlert key={alert.id} serviceAlert={alert} />
          ))}
        </SplashContainer>
      )}
    </SpinnerIf>
  );
};

SplashPageAlerts.displayName = "SplashPageAlerts";
