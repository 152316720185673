import React from "react";
import PropTypes from "prop-types";
import { semesterShape } from "components/propTypes/semesterShape";

import { ClockIcon } from "components/ui/Icons";
import { detectSemesterTimeConflicts } from "./shared";

export const TimeConflictNotice = ({ examSemesters }) => {
  const timeConflictFound = detectSemesterTimeConflicts(examSemesters);

  if (timeConflictFound) {
    return (
      <div
        data-testid="time-conflict-notice"
        style={{
          padding: "var(--sm) 0",
          color: "var(--dove-gray)",
        }}
      >
        <ClockIcon />
        Note: Exam time overlap
      </div>
    );
  }
  return null;
};
TimeConflictNotice.displayName = "TimeConflictNotice";

TimeConflictNotice.propTypes = {
  examSemesters: PropTypes.arrayOf(semesterShape),
};
