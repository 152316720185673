import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchBillingItems } from "data/actions/billingActions";

import {
  BILLING_VIEW_ALL,
  BILLING_VIEW_UNPAID,
  BILLING_VIEW_PAYMENTS_AID,
} from "./TransactionsCard/billingItemViews";

import { smallBreakpoint } from "config/media";

import { Card, CardBody, CardTitle } from "components/ui/Card";

import { chargeTypes, paymentTypes } from "./TransactionsCard/types";

import UnappliedPaymentsInfo from "./TransactionsCard/UnappliedPaymentsInfo";
import BillingItemFilters from "./TransactionsCard/Filters/BillingItemFilters";
import BillingItemsTable from "./TransactionsCard/BillingItemsTable/BillingItemsTable";
import DownloadButton from "./TransactionsCard/DownloadButton";
import styled from "styled-components";

const billingItemForTab = (tab) => {
  return (billingItem) => {
    switch (tab) {
      case BILLING_VIEW_ALL:
        return true;
      case BILLING_VIEW_UNPAID:
        return chargeTypes.has(billingItem.type) && billingItem.amount_due > 0;
      case BILLING_VIEW_PAYMENTS_AID:
        return paymentTypes.has(billingItem.type);
    }
  };
};

const billingItemForTermId = (termId) => {
  return (billingItem) => {
    return termId === "all" ? true : billingItem.term_id === termId;
  };
};

const billingItemTermIds = (billingItems) => {
  return [
    ...new Set(
      billingItems
        .map((item) => item.term_id)
        .filter((item) => item !== null)
        .sort((a, b) => parseInt(b) - parseInt(a))
    ),
  ];
};

const billingItemsMatchingString = (search) => {
  return (item) => {
    if (search === "") {
      return true;
    }

    const corpus = `${item.description} ${item.type}`.toLowerCase();
    return corpus.includes(search.toLowerCase());
  };
};

export const TransactionsCard = ({ dispatch, billingItems }) => {
  useEffect(() => {
    dispatch(fetchBillingItems());
  }, []);

  const [expanded, setExpanded] = useState(null);
  const [search, setSearch] = useState("");
  const [tab, setTab] = useState(BILLING_VIEW_UNPAID);
  const [termId, setTermId] = useState("all");

  const { items = [], error: billingItemsError = false } = billingItems;

  const filteredItems = items
    .filter(billingItemForTab(tab))
    .filter(billingItemForTermId(termId))
    .filter(billingItemsMatchingString(search));

  const termIds = billingItemTermIds(items);
  const hasActiveFilters = termId !== "all" || search !== "";

  const sum = (item, previous) => item + previous;
  const unappliedBalance =
    tab === BILLING_VIEW_PAYMENTS_AID
      ? Math.abs(filteredItems.map((item) => item.balance).reduce(sum, 0))
      : 0;

  const error = billingItemsError
    ? {
        message:
          "There is a problem displaying your billing information. Please try again soon.",
      }
    : false;

  const node = useRef();
  const clickHandler = (e) => {
    if (node.current && !node.current.contains(e.target)) {
      setExpanded(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickHandler);
    return () => removeEventListener("mousedown", clickHandler);
  }, []);

  return (
    <StyledTransactionsCard error={error} data-testid="transactions-card">
      <CardTitle>
        <h2>Transactions</h2>
        <DownloadButton />
      </CardTitle>
      <CardBody>
        <div ref={node}>
          <div className="TransactionCard__pretable">
            <BillingItemFilters
              tab={tab}
              setTab={setTab}
              termIds={termIds}
              termId={termId}
              setTermId={setTermId}
              search={search}
              setSearch={setSearch}
              setExpanded={setExpanded}
            />
            <UnappliedPaymentsInfo
              tab={tab}
              unappliedBalance={unappliedBalance}
            />
          </div>

          <BillingItemsTable
            items={filteredItems}
            tab={tab}
            hasActiveFilters={hasActiveFilters}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        </div>
      </CardBody>
    </StyledTransactionsCard>
  );
};
TransactionsCard.displayName = "TransactionsCard";
TransactionsCard.propTypes = {
  dispatch: PropTypes.func,
  billingItems: PropTypes.object,
};

const mapStateToProps = ({ billingItems }) => ({
  billingItems,
});

export default connect(mapStateToProps)(TransactionsCard);
export const StyledTransactionsCard = styled(Card)`
  color: var(--tundora);
  font-size: var(--base-font-size);
  min-height: 350px;

  .transform-y {
    transform: scaleY(-1);
  }

  .TransactionCard__pretable {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media only screen and (min-width: ${smallBreakpoint}) {
    .TransactionCard__pretable {
      margin: 15px 0;
      padding: 0;
      border-bottom-width: 0;
    }
  }
`;
