import React from "react";
import styled from "styled-components";
import { isFailed } from "data/utils/statusHelpers";

import WidgetSubtitle from "components/ui/WidgetSubtitle";
import { Holds as StatusAndHoldsCardHolds } from "components/cards/StatusAndHoldsCard/Holds";
import { ErrorMessage } from "components/ui/ErrorMessage";
import { useUser } from "components/hooks/useUser";

export function Holds() {
  const holdsData = useUser((user) => user.holds || {});
  const { loadState: holdsLoadState } = holdsData;
  const errored = isFailed(holdsLoadState);

  return (
    <>
      <WidgetSubtitle>Holds</WidgetSubtitle>
      <HoldsContainer>
        {errored && (
          <ErrorMessage message="An error occurred while trying to retrieve your data." />
        )}
        {!errored && (
          <StatusAndHoldsCardHolds holdsData={holdsData} showHeader={false} />
        )}
      </HoldsContainer>
    </>
  );
}
Holds.displayName = "FinancialAidCommunicationHolds";

const HoldsContainer = styled.div`
  padding: 15px;
`;
