import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import Spinner from "components/ui/Spinner";

import { getMyStatus } from "data/slices/myStatus";
import { fetchStatusAndHolds } from "data/actions/statusActions";
import { fetchAcademicStandings } from "data/slices/users/academicStandings";
import { fetchHolds } from "data/slices/users/holds";
import { fetchResidency } from "data/slices/users/residency";
import { areLoaded } from "data/utils/statusHelpers";
import currentUserState from "utils/currentUserState";

import AcademicStandings, {
  standingAlertPresent,
} from "./StatusAndHoldsCard/AcademicStandings";
import TermRegistrationStatuses from "./StatusAndHoldsCard/TermRegistrationStatuses";
import Residency from "./StatusAndHoldsCard/Residency";
import { Holds } from "./StatusAndHoldsCard/Holds";

const displayStatusAndHoldsCard = (
  currentUserIsDelegate,
  currentStandings,
  holds,
  studentRoles,
  termRegistrations
) => {
  const { student: roleIsStudent, releasedAdmit: roleIsReleasedAdmit } =
    studentRoles || {};

  const hasHolds = holds.length > 0;
  const hasTermRegistrations = termRegistrations.length > 0;
  const hasStandingAlert = standingAlertPresent(currentStandings);
  const isReleasedAdmit = !roleIsStudent && roleIsReleasedAdmit;

  return (
    !currentUserIsDelegate &&
    (hasHolds || hasTermRegistrations || hasStandingAlert || isReleasedAdmit)
  );
};

const statusAndHoldsSelector = (state) => {
  const { currentUID, myStatus, myStatusAndHolds } = state;

  const {
    academicRoles: studentAcademicRoles = {},
    roles: studentRoles = {},
    isDelegateUser: currentUserIsDelegate,
  } = myStatus;

  const showStandings =
    studentAcademicRoles &&
    studentAcademicRoles.current &&
    studentAcademicRoles.current.ugrd &&
    !studentAcademicRoles.current.ugrdNonDegree;

  const termRegistrationsData = myStatusAndHolds;

  const {
    academicStandings: standingsData,
    holds: holdsData = {},
    residency: residencyData = {},
  } = currentUserState(state) || {};

  const { loadState: currentStandingsLoadState, currentStandings = [] } =
    standingsData || {};
  const { loadState: holdLoadState, holds = [] } = holdsData || {};
  const { loadState: residencyLoadState } = residencyData || {};
  const {
    loadState: termRegistrationsLoadState,
    termRegistrations: termRegistrations = [],
  } = termRegistrationsData || {};

  const loadStates = [
    currentStandingsLoadState,
    holdLoadState,
    residencyLoadState,
    termRegistrationsLoadState,
  ];

  const displayCard = displayStatusAndHoldsCard(
    currentUserIsDelegate,
    currentStandings,
    holds,
    studentRoles,
    termRegistrations
  );

  return {
    currentUID,
    displayCard,
    holdsData,
    loadStates,
    residencyData,
    showStandings,
    standingsData,
    studentAcademicRoles,
    studentRoles,
    termRegistrationsData,
  };
};

const StatusAndHoldsCard = () => {
  const dispatch = useDispatch();

  const {
    currentUID,
    displayCard,
    holdsData,
    loadStates,
    residencyData,
    showStandings,
    standingsData,
    studentAcademicRoles,
    studentRoles,
    termRegistrationsData,
  } = useSelector(statusAndHoldsSelector);

  useEffect(() => {
    if (currentUID) {
      dispatch(getMyStatus());
      dispatch(fetchAcademicStandings(currentUID));
      dispatch(fetchStatusAndHolds());
      dispatch(fetchResidency(currentUID));
      dispatch(fetchHolds(currentUID));
    }
  }, [currentUID]);

  const requestsFinished = areLoaded(loadStates);

  if (displayCard) {
    return (
      <Card data-testid="status-and-holds-card">
        <CardTitle>
          <h2>Status and Holds</h2>
        </CardTitle>
        <CardBody>
          {!requestsFinished && <Spinner />}
          {requestsFinished && (
            <>
              {showStandings && (
                <AcademicStandings standingsData={standingsData} />
              )}
              <TermRegistrationStatuses
                termRegistrationsData={termRegistrationsData}
              />
              <Residency
                studentRoles={studentRoles}
                studentAcademicRoles={studentAcademicRoles}
                residencyData={residencyData}
              />
              <Holds holdsData={holdsData} />
            </>
          )}
        </CardBody>
      </Card>
    );
  } else {
    return null;
  }
};

export default StatusAndHoldsCard;
