import React, { useState } from "react";
import { useSelector } from "react-redux";

import ButtonAsLink from "components/ui/ButtonAsLink";

export const FinalExamChangeNotice = () => {
  const [displayNotice, setDisplayNotice] = useState(false);

  const { finalExamChangeNotice } = useSelector((state) => {
    const { myAcademics: { examMessage: finalExamChangeNotice } = {} } = state;
    return {
      finalExamChangeNotice,
    };
  });

  return (
    <div data-testid="ugrd-change-notice" style={{ padding: "var(--sm) 0" }}>
      Exam information is subject to change.{" "}
      <ButtonAsLink onClick={() => setDisplayNotice(!displayNotice)}>
        Learn more
      </ButtonAsLink>
      {displayNotice && (
        <div
          style={{ fontSize: "12px", margin: "10px 0" }}
          dangerouslySetInnerHTML={{
            __html: finalExamChangeNotice,
          }}
        ></div>
      )}
    </div>
  );
};

FinalExamChangeNotice.displayName = "FinalExamChangeNotice";
