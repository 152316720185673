import React from "react";
import { APILink } from "components/ui/APILink";
import { useSlice } from "components/hooks/useSlice";
import { useAdvisingStudentUID } from "components/hooks/useAdvisingStudentUID";

export function TransferCreditReportLink() {
  const isAdvising = !!useAdvisingStudentUID();
  const link = isAdvising
    ? useSlice(
        "advising",
        (slice) => slice?.academics?.advisorLinks?.tcReportLink
      )
    : useSlice("apiLinks", (slice) => slice?.UC_CX_XFER_CREDIT_REPORT_STDNT);

  return <APILink link={link} text="Transfer Credit Report" />;
}
