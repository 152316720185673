import React from "react";
import { react2angular } from "react2angular";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReduxProvider from "components/ReduxProvider";
import { ClassInformationCard } from "components/cards/ClassInformationCard";

const NgClassInformationCard = () => (
  <ReduxProvider>
    <Router>
      <Routes>
        <Route
          path="academics/teaching-semester/:semesterId/class/:classId"
          element={<ClassInformationCard />}
        />

        <Route
          path="academics/semester/:semesterId/class/:classId"
          element={<ClassInformationCard />}
        />
      </Routes>
    </Router>
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("classInformationCard", react2angular(NgClassInformationCard));
