import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { Card, CardTitle, CardBody } from "components/ui/Card";
import Spinner from "components/ui/Spinner";
import { UnstyledList } from "components/ui/Lists";
import bCourseIcon from "assets/images/icon_bcourses_256x256.svg";
import calLinkIcon from "assets/images/icon_callink_256x256.svg";
import getGroups from "../../data/actions/getGroups";

export default function GroupsCard() {
  const dispatch = useDispatch();

  const { loadState, groups, isLoggedIn } = useSelector(
    ({ myStatus, myGroups }) => {
      const { isLoggedIn } = myStatus;
      const { loadState = "pending", groups } = myGroups;
      return {
        loadState,
        groups,
        isLoggedIn,
      };
    }
  );

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getGroups());
    }
  }, [dispatch, isLoggedIn]);

  return (
    <Card data-testid="my-groups-card">
      <CardTitle>
        <h2>My Groups</h2>
      </CardTitle>

      {loadState === "pending" && (
        <CardBody>
          <Spinner />
        </CardBody>
      )}

      {loadState === "success" &&
        (groups.length ? (
          <UnstyledList style={{ margin: `0 var(--lg)` }}>
            {groups.map((group, index) => (
              <GroupItemStyles data-testid="group-item" key={index}>
                <a href={group.site_url} target="_blank" rel="noopener noreferrer">
                  {group.emitter === "bCourses" && <BCourseIcon />}
                  {group.emitter === "CalLink" && <CalLinkIcon />}{" "}
                  <strong>{group.name}</strong>
                </a>
              </GroupItemStyles>
            ))}
          </UnstyledList>
        ) : (
          <div data-testid="no-groups-notice" style={{ padding: `var(--lg)` }}>
            There are currently no groups available.
          </div>
        ))}
    </Card>
  );
}
GroupsCard.displayName = "GroupsCard";

const GroupItemStyles = styled.li`
  a {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid var(--gallery);

    &:hover {
      text-decoration: none;
      background-color: var(--foam);
    }
  }

  strong {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:last-child a {
    border-bottom: none;
  }
`;

const BCourseIcon = styled.div`
  background-image: url("${bCourseIcon}");
  background-repeat: no-repeat;
  background-size: 16px;
  height: 16px;
  min-width: 16px;
  margin-right: 10px;
`;

const CalLinkIcon = styled.div`
  background-image: url("${calLinkIcon}");
  background-repeat: no-repeat;
  background-size: 16px;
  height: 16px;
  min-width: 16px;
  margin-right: 10px;
`;
