import React from "react";
import PropTypes from "prop-types";
import Spinner from "components/ui/Spinner";
import { Card, CardBody, CardTitle } from "../ui/Card";
import WithCurrentCourse from "components/data/WithCurrentCourse";
import CourseContext from "components/contexts/CourseContext";
import { TextbooksCardBody } from "./TextbooksCard/TextbooksCardBody";

export function TextbooksCard({ semesterId, courseId }) {
  return (
    <WithCurrentCourse semeseterId={semesterId} courseId={courseId}>
      <CourseContext.Consumer>
        {({ currentCourse: course, currentSemester: semester, loadState }) => {
          if (semester && ["current", "future"].includes(semester.timeBucket)) {
            return (
              <Card data-testid="textbooks-card">
                <CardTitle>
                  <h2>Textbooks</h2>
                </CardTitle>
                <CardBody>
                  {loadState === "pending" && <Spinner />}
                  {loadState === "success" && (
                    <TextbooksCardBody
                      currentSemester={semester}
                      currentCourse={course}
                    />
                  )}
                </CardBody>
              </Card>
            );
          }

          return null;
        }}
      </CourseContext.Consumer>
    </WithCurrentCourse>
  );
}

TextbooksCard.displayName = "TextbooksCard";
TextbooksCard.propTypes = {
  semesterId: PropTypes.string,
  courseId: PropTypes.string,
};
