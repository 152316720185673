import React from "react";
import PropTypes from "prop-types";

import {
  GreenCheckCircleIcon,
  RedWarning,
  YellowWarning,
} from "components/ui/Icons";

const ResidencyIcon = ({ officialCode }) => {
  return (
    <>
      {officialCode === "" && <RedWarning />}
      {officialCode === "PEND" && <YellowWarning />}
      {officialCode === "NON" && <GreenCheckCircleIcon />}
      {officialCode === "RES" && <GreenCheckCircleIcon />}
    </>
  );
};

ResidencyIcon.displayName = "ResidencyIcon";
ResidencyIcon.propTypes = {
  officialCode: PropTypes.string,
};

export default ResidencyIcon;
