import React from "react";
import PropTypes from "prop-types";
import { UnstyledList } from "components/ui/Lists";
import { CategoryTitle } from "./UI";
import { Course } from "./Course";
import { courseShape } from "../../propTypes/courseShape";

export function CourseCategory({ courses, title, onlyStudentInstructor }) {
  if (courses.length > 0) {
    return (
      <div data-testid="course-category">
        {!onlyStudentInstructor && <CategoryTitle>{title}</CategoryTitle>}
        <UnstyledList style={{ padding: `0 var(--lg)` }}>
          {courses.map((course, index) => (
            <Course key={index} course={course} />
          ))}
        </UnstyledList>
      </div>
    );
  }

  return null;
}

CourseCategory.displayName = "CourseCategory";
CourseCategory.propTypes = {
  title: PropTypes.string,
  onlyStudentInstructor: PropTypes.bool,
  courses: PropTypes.arrayOf(courseShape),
};
