import {
  GET_GROUPS_START,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAILURE,
} from "../action-types";

export default function Groups(state = {}, action) {
  switch (action.type) {
    case GET_GROUPS_START:
      return { ...state, loadState: "pending" };
    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        ...action.value,
        loadState: "success",
      };
    case GET_GROUPS_FAILURE:
      return { ...state, loadState: "failure", err: action.err };
    default:
      return state;
  }
}