import React from "react";
import { parseISO } from "date-fns";
import { Card } from "components/ui/Card";
import { statusProps, statusShape } from "./statusProps";
import { StatusHeader } from "./StatusHeader";
import { ResponseForm } from "./ResponseForm";
import { longDate } from "functions/formatDate";
import { formatCurrency } from "./formatCurrency";

export function StatusInitiated({ status, setMessageObj }) {
  const {
    studentResponse,
    isUndergraduate,
    config: { descrProgramLong, descrLong },
  } = status;

  return (
    <Card data-testid="sir-card-status-initiated">
      <StatusHeader header={status.header} />

      <div className="cc-widget-text">
        {studentResponse && <div>{studentResponse.option.messageText}</div>}
        {!studentResponse && (
          <>
            {descrProgramLong && (
              <div
                className="cc-widget-sir-program-description"
                style={{ marginBottom: `var(--lg)` }}
              >
                <strong>{descrProgramLong}</strong>
              </div>
            )}

            {descrLong && <div>{descrLong}</div>}
            {isUndergraduate ? (
              <UndergraduateInstructions status={status} />
            ) : (
              <NonUndergradInstructions status={status} />
            )}
          </>
        )}
      </div>

      <ResponseForm status={status} setMessageObj={setMessageObj} />
    </Card>
  );
}
StatusInitiated.propTypes = statusProps;

function UndergraduateInstructions({ status }) {
  const { deposit: { required: depositRequired, dueAmt, dueDt } = {} } = status;

  return (
    <div>
      <br />
      <div>
        To reserve your seat, accept your undergraduate offer of admission
        {depositRequired && (
          <>
            {" "}
            and pay the non-refundable deposit of{" "}
            <strong>{formatCurrency(dueAmt)}</strong>
          </>
        )}{" "}
        by {longDate(parseISO(dueDt))} (11:59pm PST) and you&apos;ll be on your
        way to becoming a Golden Bear!
      </div>

      {!depositRequired && (
        <div>
          <br />
          <div>You are not required to pay a deposit.</div>
        </div>
      )}
    </div>
  );
}
UndergraduateInstructions.propTypes = {
  status: statusShape,
};

function NonUndergradInstructions({ status }) {
  const { dueDt } = status;

  return (
    <div>
      <hr aria-hidden="true" />
      <h3>Next Steps</h3>

      <div>
        The next step for you to take is formal acceptance of our admission
        offer. Let us know your decision by {longDate(parseISO(dueDt))}.
      </div>
    </div>
  );
}
NonUndergradInstructions.propTypes = {
  status: statusShape,
};
