import React, { useContext } from "react";
import { fetchHousing } from "../../data/slices/users/finaid/housing";

import { Card, CardBody, CardTitle } from "components/ui/Card";
import Spinner from "components/ui/Spinner";
import WithFinaidYears from "../data/WithFinaidYears";
import FinancialAidYearContext from "../contexts/FinancialAidYearContext";
import APILink from "components/ui/APILink";
import AccessibleLinkContent from "../ui/AccessibleLinkContent";
import { LargeSpacer } from "components/ui/VerticalSpacers";
import { HairlineRule } from "components/ui/HairlineRule";
import { useFinaidYear } from "components/hooks/useFinaidYear";
import { HousingTable } from "./FinancialAidHousingCard/HousingTable";
import { dispatchUserFinaidSlice } from "components/hooks/dispatchUserFinaidSlice";
import { ErrorBoundary } from "components/ui/ErrorBoundary";

export function FinancialAidHousingCard() {
  return (
    <ErrorBoundary title="Housing">
      <WithFinaidYears>
        <HousingCardUI />
      </WithFinaidYears>
    </ErrorBoundary>
  );
}

function HousingCardUI() {
  dispatchUserFinaidSlice(fetchHousing);
  const housing = useFinaidYear(({ housing }) => housing);

  const {
    pathwayMessage,
    isFallPathway,
    terms = [],
    links: { pathwayFinaid, updateHousing } = {},
    instruction,
    loadState,
  } = housing || {};

  const { aidYear } = useContext(FinancialAidYearContext);

  return (
    <Card data-testid="housing-card">
      <CardTitle>
        <h2>{isFallPathway ? "Housing or Pathway" : "Housing"}</h2>

        {updateHousing && (
          <strong>
            <APILink link={{ ...updateHousing, ucUpdateCache: "finaid" }}>
              {({ props, text, showNewWindow }) => (
                <a {...props}>
                  <AccessibleLinkContent showNewWindow={showNewWindow}>
                    {text}{" "}
                    <i className="fa fa-arrow-right" aria-hidden="true" />
                  </AccessibleLinkContent>
                </a>
              )}
            </APILink>
          </strong>
        )}
      </CardTitle>
      <CardBody>
        {loadState === "pending" && <Spinner />}
        {loadState === "success" && (
          <>
            {terms.length === 0 && (
              <>You do not have Housing information for {aidYear.name}</>
            )}

            {terms.length > 0 && (
              <>
                <HousingTable>
                  <thead>
                    <tr>
                      <th>Term</th>
                      <th>Housing Option</th>
                    </tr>
                  </thead>
                  <tbody>
                    {terms.map((term, index) => (
                      <tr key={index}>
                        <td>{term.termDescr}</td>
                        <td>{term.housingOption}</td>
                      </tr>
                    ))}
                  </tbody>
                </HousingTable>

                {instruction && (
                  <div>
                    <HairlineRule />
                    {instruction}
                  </div>
                )}

                {pathwayMessage && pathwayFinaid && (
                  <div>
                    <LargeSpacer />
                    <span>{pathwayMessage}</span>{" "}
                    <APILink
                      link={pathwayFinaid}
                      text="Estimate Fall's cost of attendance"
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
}

FinancialAidHousingCard.displayName = "FinancialAidHousingCard";
