import React from "react";
import { react2angular } from "react2angular";

import { ReduxProvider } from "components/data/ReduxProvider";
import { SplashPageAlerts } from "components/pages/SplashPage/SplashPageAlerts";

function NgServiceAlert() {
  return (
    <ReduxProvider>
      <SplashPageAlerts />
    </ReduxProvider>
  );
}

angular
  .module("calcentral.react")
  .component("serviceAlert", react2angular(NgServiceAlert));
