import { useEffect } from "react";
import { dispatchUserSlice } from "components/hooks/dispatchUserSlice";
import { fetchCampusLinks } from "data/slices/users/campusLinks";
import { useTopCategory } from "./useTopCategory";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "components/hooks/useUser";

import { useFilteredNav } from "./useFilteredNav";
import { useMatchingCategory } from "./useMatchingCategory";

export function CampusRedirect() {
  dispatchUserSlice(fetchCampusLinks);

  const navigate = useNavigate();
  const { loadState } = useUser((user) => user.campusLinks) || {};
  const { categoryId } = useParams();
  const filteredNav = useFilteredNav();
  const topCategory = useTopCategory();
  const matchingCategory = useMatchingCategory();

  useEffect(() => {
    if (loadState === "success" && filteredNav) {
      if (categoryId === undefined && topCategory !== undefined) {
        // redirect to the top category, no categoryId param was present
        navigate(`/campus/${topCategory.id}`);
      }

      if (categoryId !== undefined && matchingCategory === undefined) {
        // redirect the the root, no matching category was found
        navigate(`/campus`);
      }
    }
  }, [
    navigate,
    loadState,
    categoryId,
    filteredNav,
    matchingCategory,
    topCategory,
  ]);

  return null;
}
