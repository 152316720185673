import { useSlice } from "./useSlice";

export function useRoles(callback = (roles) => roles) {
  const { loadState, roles = {} } = useSlice("myStatus");

  return callback({ ...roles, loadState });
}

export function useActiveRoles() {
  // eslint-disable-next-line no-unused-vars
  const { loadState, ...roles } = useRoles();
  return activeRoles(roles);
}

/**
 * Takes roles object and returns array of role strings representing
 * those that are active
 */
export function activeRoles(roles) {
  var activeRoles = [];
  if (roles && roles.length > 0) {
    roles.forEach(function (value, key) {
      if (value) {
        activeRoles.push(key);
      }
    });
  }
  return activeRoles;
}
