import { useParams } from "react-router-dom";
import { useCurrentSemesters } from "./useCurrentSemesters";

export function useCurrentCourses() {
  const { classId } = useParams();
  const { loadState, teachingSemester, semester } = useCurrentSemesters();

  return {
    loadState,
    currentCourse: semester?.classes?.find(
      (course) => course.course_id === classId
    ),
    currentTeachingCourse: teachingSemester?.classes?.find(
      (course) => course.course_id === classId
    ),
  };
}
