import React from "react";
import PropTypes from "prop-types";
import { MailtoLink } from "components/ui/MailtoLink";
import { MemberPhoto } from "./MemberPhoto";

export const HigherDegreeCommitteeMember = ({
  member: { email, name, photo, primaryDepartment, serviceRange } = {},
}) => {
  return (
    <tr>
      <td>
        <div style={{ display: `flex`, gap: `var(--lg)` }}>
          <MemberPhoto photo={photo} name={name} />

          <div>
            <div>
              <strong>{name}</strong>
            </div>
            <div>
              <MailtoLink emailAddress={email}>{email}</MailtoLink>
              <div>{primaryDepartment}</div>
              {serviceRange && (
                <div style={{ color: `var(--dove-gray)` }}>
                  Service:&nbsp;<span>{serviceRange}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

HigherDegreeCommitteeMember.displayName = "HigherDegreeCommitteeMember";
HigherDegreeCommitteeMember.propTypes = {
  member: PropTypes.object,
};
