import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  Disclosure as ReachDisclosure,
  DisclosureButton as ReachDisclosureButton,
  DisclosurePanel as ReachDisclosurePanel,
} from "@reach/disclosure";
import { ChevronCircleIcon as SharedChevronCircleIcon } from "components/ui/ChevronCircleIcon";

export const Disclosure = styled(ReachDisclosure)``;
export const ChevronCircleIcon = SharedChevronCircleIcon;

export function DisclosureContainer({ children, ...props }) {
  // This state will end up on a DOM element as an attribute ("hover") that
  // React doesn't understand as a boolean, so we use string values for the
  // state to prevent getting yelled at by the console
  const [hoverActive, setHoverActive] = useState("false");

  return (
    <DisclosureContainerStyled
      {...props}
      $hover={hoverActive}
      data-testid="chevron-disclosure-container"
    >
      {children({ hoverActive, setHoverActive })}
    </DisclosureContainerStyled>
  );
}
DisclosureContainer.propTypes = {
  children: PropTypes.func,
};

export const DisclosureContainerStyled = styled.div`
  background-color: ${({ $hover }) =>
    $hover === "true" ? `var(--foam)` : `white`};
`;

export const DisclosurePanel = styled(ReachDisclosurePanel)``;

export const DisclosureButton = ({
  children,
  className = "",
  setHoverActive,
}) => {
  return (
    <ReachDisclosureButton
      className={className}
      onFocus={() => setHoverActive("true")}
      onBlur={() => setHoverActive("false")}
      onMouseEnter={() => setHoverActive("true")}
      onMouseLeave={() => setHoverActive("false")}
    >
      {children}
    </ReachDisclosureButton>
  );
};

DisclosureButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  setHoverActive: PropTypes.func,
};
