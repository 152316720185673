import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import { FinancialAidHousingCard } from "../cards/FinancialAidHousingCard";

const NgFinancialAidHousingCard = () => (
  <ReduxProvider>
    <FinancialAidHousingCard/>
  </ReduxProvider>
);


angular
  .module("calcentral.react")
  .component(
    "financialAidHousingCard",
    react2angular(NgFinancialAidHousingCard)
  );
