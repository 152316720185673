import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  GreenCheckCircleIcon,
  RedWarning,
  YellowWarning,
} from "components/ui/Icons";

const YellowWarningSpaced = styled(YellowWarning)`
  margin-right: 4px;
`;

const GreenCheckCircleSpaced = styled(GreenCheckCircleIcon)`
  margin-right: 5px;
`;

const RedWarningSpaced = styled(RedWarning)`
  margin-right: 5px;
`;

const RegistrationStatusIcon = ({ severity }) => {
  switch (severity) {
    case "normal":
      return <GreenCheckCircleSpaced />;
    case "notice":
      return <YellowWarningSpaced />;
    case "warning":
      return <RedWarningSpaced />;
    default:
      return null;
  }
};

RegistrationStatusIcon.propTypes = {
  severity: PropTypes.string,
};

export default RegistrationStatusIcon;
