import { useParams } from "react-router-dom";
import { useReact } from "./useReact";

export function useCourseParams() {
  const isReactOnly = useReact();

  if (isReactOnly) {
    return { ...useParams(), isReactOnly };
  }

  const path = window.location.pathname;

  if (
    path.match("/academics/teaching-semester") ||
    path.match("/academics/semester/")
  ) {
    const segments = path.split("/");
    return {
      semesterId: segments[3],
      classId: segments[5],
      isInstructor: segments[2] === "teaching-semester",
      isReactOnly,
    };
  }

  return {};
}
