import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import Card from "components/../react/components/Card";

import { LargeSpacer } from "components/ui/VerticalSpacers";

import { validationSchema } from "./userAuth.module.js";
import { createUserAuth } from "functions/requests";
import { UserAuthForm } from "./UserAuthForm.js";

export function NewUserAuth() {
  const navigate = useNavigate();
  const initialValues = {
    uid: "",
    is_active: true,
    is_viewer: false,
    is_author: false,
    is_superuser: false,
  };

  const onSubmit = (values, { setErrors }) => {
    createUserAuth(values)
      .then((response) => {
        return response.json().then((data) => ({
          data,
          response,
        }));
      })
      .then(({ data, response }) => {
        if (response.ok) {
          navigate(`/api/service_alerts/${data.id}`);
        } else if (response.status === 422) {
          setErrors(data);
        }
      });
  };

  return (
    <Card title="New User Auth" data-testid="new-user-auth">
      <LargeSpacer />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <UserAuthForm />
      </Formik>
    </Card>
  );
}
NewUserAuth.displayName = "NewUserAuth";
