import React from "react";
import PropTypes from "prop-types";

import { CreditsContainer } from "./CreditsContainer";
import { TransferCreditTable } from "./TransferCreditTable";

export function LawTransferCredit({ transferCredits = {} }) {
  const { summary, detailed } = transferCredits;

  if (summary) {
    const { totalTransferUnitsLaw = 0, careerDescr } = summary;
    return (
      <>
        <TransferCreditTable>
          <thead>
            <tr>
              <th>Type</th>
              <th>Law Units</th>
            </tr>
          </thead>
          <CreditsContainer
            title={careerDescr + " Transfer Course Units"}
            value={totalTransferUnitsLaw.toFixed(3)}
            detailedRows={detailed}
          />
        </TransferCreditTable>
      </>
    );
  }

  return null;
}

LawTransferCredit.propTypes = {
  transferCredits: PropTypes.object,
};

export { LawTransferCredit as default };
