import React, { useContext } from "react";
import PropTypes from "prop-types";
import SelectedDateContext from "./SelectedDateContext";
import OrangeChangedIcon from "../../Icon/OrangeChangedIcon";

import formatCurrency from "functions/formatCurrency";

export function AwardRow({
  award: { description, value: currentValue } = {},
  snapshotItems = [],
}) {
  const previousValue = snapshotItems.find(
    (item) => item.description === description
  )?.value;

  return (
    <tr className="awardRow">
      <th scope="row" className="valueDescription">
        {description}
      </th>

      <PreviousCell currentValue={currentValue} previousValue={previousValue} />
      <CurrentCell currentValue={currentValue} previousValue={previousValue} />
    </tr>
  );
}

AwardRow.propTypes = {
  award: PropTypes.object,
  snapshotItems: PropTypes.array,
};

export function SnapshotItem({
  award: { description, value: prevValue } = {},
}) {
  return (
    <tr className="awardRow">
      <th scope="row">{description}</th>
      <td className="valueCell">{formatCurrency(prevValue)}</td>
      <td className="valueCell valueCellChanged valueCellIcon noWrap">
        <div>
          <OrangeChangedIcon className="hideSmallFormFactor icon" />
          N/A
        </div>
      </td>
    </tr>
  );
}

SnapshotItem.propTypes = {
  award: PropTypes.object,
};

function CurrentCell({ currentValue, previousValue }) {
  const { selectedDate } = useContext(SelectedDateContext);

  if (selectedDate === "X") {
    return <td className="valueCell">{formatCurrency(currentValue)}</td>;
  }

  if (currentValue === previousValue) {
    return <td className="valueCell">{formatCurrency(currentValue)}</td>;
  }

  return (
    <td className="valueCell valueCellChanged valueCellIcon">
      <div>
        <OrangeChangedIcon className="hideSmallFormFactor icon" />

        {formatCurrency(currentValue)}
      </div>
    </td>
  );
}
CurrentCell.propTypes = {
  currentValue: PropTypes.number,
  previousValue: PropTypes.number,
};

function PreviousCell({ currentValue, previousValue }) {
  const { selectedDate } = useContext(SelectedDateContext);

  if (selectedDate === "X") {
    return <td className="valueCell"></td>;
  }

  if (previousValue === undefined && currentValue) {
    return <td className="valueCell">N/A</td>;
  }

  if (previousValue !== currentValue) {
    return (
      <td className="valueCell valueCellChanged">
        {formatCurrency(previousValue)}
      </td>
    );
  }

  return <td className="valueCell">{formatCurrency(previousValue)}</td>;
}
PreviousCell.propTypes = {
  currentValue: PropTypes.number,
  previousValue: PropTypes.number,
};
