import React from "react";
import { semesterShape } from "components/propTypes/semesterShape";
import styled from "styled-components";
import Table from "components/ui/Table";
import { ClockIcon } from "components/ui/Icons";

const headerStyle = {
  margin: "10px 0",
  padding: "0",
};

const headerGreyStyle = {
  color: "var(--dusty-gray)",
  fontWeight: "normal",
};

const SemesterTableStyles = styled.div`
  overflow-x: auto;
  padding: 15px 0;

  table thead tr th {
    color: var(--dove-grey);
    text-align: left;
  }

  table tbody tr {
    td {
      border: 0;
    }
    td.courseNames {
      vertical-align: top;
    }
    td.examLocations {
      color: var(--dove-gray);
      font-size: 12px;
    }
  }

  table tbody tr:nth-child(even) {
    background: var(--gallery);
  }

  @media only screen and (max-width: 767px) {
    overflow: auto;
  }
`;

// provides grey italic styling when location is not available
const locationStyle = function (location) {
  if (
    location === "Exam Location TBD" ||
    location === "Exam Information not available at this time."
  ) {
    return {
      color: "var(--dusty-gray)",
      fontStyle: "italic",
    };
  } else {
    return null;
  }
};

// returns true when a semester includes at least one finalized exam schedule
export const areSemesterExamsFinalized = function (semester) {
  if (semester && semester.exams && semester.exams.schedule) {
    const finalizedFound = semester.exams.schedule.find((exam) => {
      return exam.finalized === "Y";
    });
    return finalizedFound !== undefined;
  } else {
    return false;
  }
};

export function InstructorFinalExamSemester({ examSemester }) {
  const schedulesAreFinalized = areSemesterExamsFinalized(examSemester);

  return (
    <div data-testid="instructor-final-exam-semester">
      <h3 style={headerStyle}>
        <span>{examSemester.name} Final Exams</span>
        {!schedulesAreFinalized && (
          <span style={headerGreyStyle}> (Projected)</span>
        )}
      </h3>

      {examSemester.exams &&
        examSemester.exams.schedule &&
        examSemester.exams.schedule.length > 0 && (
          <SemesterTableStyles>
            <Table>
              <thead>
                <tr>
                  <th style={{ width: "38%" }} scope="col">
                    Class
                  </th>
                  <th scope="col">Exam Schedule</th>
                </tr>
              </thead>
              <tbody>
                {examSemester.exams.schedule.map((schedule, index) => (
                  <tr
                    key={index}
                    data-testid="instructor-final-exam-semester-exam"
                  >
                    <td
                      className="courseNames"
                      data-testid="instructor-final-exam-course-names"
                    >
                      {schedule.crossListedCourseNames.map(
                        (courseName, courseNameIndex) => (
                          <div key={courseNameIndex}>
                            {courseName}{" "}
                            {schedule.displaySectionLabel && (
                              <span>{schedule.sectionLabel}</span>
                            )}
                          </div>
                        )
                      )}
                    </td>

                    <td className="examLocations">
                      {schedule.examLocations.map((location, index) => (
                        <div
                          data-testid="instructor-final-exam-semester-exam-details"
                          key={index}
                        >
                          {schedule.timeConflict && <ClockIcon />}
                          {schedule.examDateInstructor && (
                            <span data-testid="instructor-final-exam-date">
                              {schedule.examDateInstructor}
                            </span>
                          )}
                          {(schedule.examDateInstructor ||
                            schedule.examTime) && (', ')}
                          {schedule.examTime && (
                            <span data-testid="instructor-final-exam-time">
                              {schedule.examTime}
                            </span>
                          )}
                          {(schedule.examDateInstructor ||
                            schedule.examTime) && <span> | </span>}
                          <span
                            data-testid="instructor-final-exam-location"
                            style={locationStyle(location)}
                          >
                            {location}
                          </span>
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </SemesterTableStyles>
        )}
    </div>
  );
}

InstructorFinalExamSemester.displayName = "InstructorFinalExamSemester";
InstructorFinalExamSemester.propTypes = {
  examSemester: semesterShape,
};
