import React from "react";
import PropTypes from "prop-types";

import {
  ChevronCircleIcon,
  Disclosure,
  DisclosureContainer,
  DisclosureButton,
} from "components/ui/ChevronDisclosure";

import styled from "styled-components";
import { CommitteeMembersDisclosure } from "./CommitteeMembersDisclosure";
import { useState } from "react";

export const HigherDegreeCommittee = ({
  committeeType,
  program,
  committeeMembers,
}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Disclosure open={isOpen} onChange={() => setOpen(!isOpen)}>
      <HigherDegreeCommitteeDisclosureContainer>
        {({ setHoverActive }) => (
          <>
            <HigherDegreeCommitteeDisclosureButton
              setHoverActive={setHoverActive}
            >
              <div className="header">
                <h3>{committeeType}</h3>
                <div>
                  <ChevronCircleIcon />
                </div>
                <h4>{program}</h4>
              </div>
            </HigherDegreeCommitteeDisclosureButton>
            <CommitteeMembersDisclosure committeeMembers={committeeMembers} />
          </>
        )}
      </HigherDegreeCommitteeDisclosureContainer>
    </Disclosure>
  );
};

HigherDegreeCommittee.displayName = "HigherDegreeCommittee";
HigherDegreeCommittee.propTypes = {
  committeeType: PropTypes.string,
  program: PropTypes.string,
  committeeMembers: PropTypes.object,
};

const HigherDegreeCommitteeDisclosureButton = styled(DisclosureButton)`
  width: 100%;

  background-color: transparent;
  border: 0;
  margin: 0;
  padding: var(--md) 0;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  .header {
    display: grid;
    div:nth-child(2) {
      text-align: right;
    }
    grid-template-columns: auto auto;

    h3,
    h4 {
      font-weight: bold;
      text-align: left;
    }
  }
`;

const HigherDegreeCommitteeDisclosureContainer = styled(DisclosureContainer)`
  border-bottom: 1px solid var(--gallery);
`;
