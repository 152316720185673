import React from "react";
import { react2angular } from "react2angular";

import { ReduxProvider } from "components/data/ReduxProvider";
import HigherDegreeCommitteesCard from "components/cards/HigherDegreeCommitteesCard";

const NgGraduateHigherDegreeCommitteesCard = () => (
  <ReduxProvider>
    <HigherDegreeCommitteesCard />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component(
    "graduateHigherDegreeCommitteesCard",
    react2angular(NgGraduateHigherDegreeCommitteesCard)
  );
