import React from "react";
import PropTypes from "prop-types";
import { isLoaded } from "data/utils/statusHelpers";
import { semesterShape } from "components/propTypes/semesterShape";
import { useSelector } from "react-redux";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import Spinner from "components/ui/Spinner";

import { FinalExamChangeNotice } from "./FinalExamScheduleCard/FinalExamChangeNotice";
import { TimeConflictNotice } from "./FinalExamScheduleCard/TimeConflictNotice";
import { InstructorFinalExamSemester } from "./FinalExamScheduleCard/InstructorFinalExamSemester";

import {
  filterExamSemesters,
  getCourseCareerStatuses,
  sortSemesters,
} from "./FinalExamScheduleCard/shared";

export function InstructorFinalExamScheduleCard({
  currentTeachingSemester,
  loadState,
  teachingSemesters,
}) {
  const { featureEnabled } = useSelector((state) => {
    const {
      myStatus: { features },
    } = state;

    const featureEnabled = features && features["finalExamScheduleInstructor"];

    return {
      featureEnabled,
    };
  });

  let unfilteredExamSemesters = [];
  if (currentTeachingSemester) {
    unfilteredExamSemesters = [currentTeachingSemester];
  } else {
    unfilteredExamSemesters = teachingSemesters;
  }
  const filteredExamSemesters = filterExamSemesters(unfilteredExamSemesters);
  const examSemesters = sortSemesters(filteredExamSemesters);

  const {
    coursesPresent,
    ugrdCoursesPresent,
    lawCoursesPresent,
    gradCoursesPresent,
    gradCoursesOnlyPresent,
  } = getCourseCareerStatuses(unfilteredExamSemesters);

  const dataLoaded = isLoaded(loadState);

  const showCard = featureEnabled && !gradCoursesOnlyPresent && coursesPresent;

  if (showCard) {
    return (
      <Card data-testid="instructor-final-exam-schedule-card">
        <CardTitle>
          <h2>
            Final Exams <span>TEACHING</span>
          </h2>
        </CardTitle>

        {dataLoaded ? (
          <CardBody>
            {lawCoursesPresent && <LawFinalExamScheduleLink />}

            {ugrdCoursesPresent && (
              <>
                <FinalExamChangeNotice />
                <TimeConflictNotice examSemesters={examSemesters} />
              </>
            )}

            <div>
              {examSemesters.map((examSemester, index) => (
                <div key={index}>
                  <InstructorFinalExamSemester examSemester={examSemester} />
                </div>
              ))}
            </div>

            {gradCoursesPresent && ugrdCoursesPresent && (
              <GraduateCourseExamNotice />
            )}
          </CardBody>
        ) : (
          <CardBody>
            <Spinner />
          </CardBody>
        )}
      </Card>
    );
  } else {
    return null;
  }
}

InstructorFinalExamScheduleCard.displayName = "InstructorFinalExamScheduleCard";
InstructorFinalExamScheduleCard.propTypes = {
  currentTeachingSemester: semesterShape,
  loadState: PropTypes.string,
  teachingSemesters: PropTypes.arrayOf(semesterShape),
};

export const LawFinalExamScheduleLink = () => {
  const lawExamLink =
    "https://www.law.berkeley.edu/php-programs/students/exams/examTimesList.php";

  return (
    <div>
      <a href={lawExamLink} target="_blank" rel="noopener noreferrer">
        Law Final Exam Schedule
      </a>
    </div>
  );
};
LawFinalExamScheduleLink.displayName = "LawFinalExamScheduleLink";

export const GraduateCourseExamNotice = () => {
  return (
    <div
      data-testid="graduate-course-exam-notice"
      style={{ color: "var(--dove-gray)", padding: "var(--lg) 0" }}
    >
      Note: Exams for graduate level courses are not represented here.
    </div>
  );
};
GraduateCourseExamNotice.displayName = "GraduateCourseExamNotice";
