import PropTypes from "prop-types";

export const rolesShape = PropTypes.shape({
  advisor: PropTypes.bool,
  alumniFormer: PropTypes.bool,
  applicant: PropTypes.bool,
  concurrentEnrollmentStudent: PropTypes.bool,
  confidential: PropTypes.bool,
  exStudent: PropTypes.bool,
  faculty: PropTypes.bool,
  graduate: PropTypes.bool,
  law: PropTypes.bool,
  postSir: PropTypes.bool,
  preSir: PropTypes.bool,
  registered: PropTypes.bool,
  releasedAdmit: PropTypes.bool,
  staff: PropTypes.bool,
  student: PropTypes.bool,
  undergrad: PropTypes.bool,
  withdrawnAdmit: PropTypes.bool,
});
