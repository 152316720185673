import React from "react";
import PropTypes from "prop-types";

const PastStanding = ({ standing }) => {
  if (standing) {
    return (
      <tr>
        <td>
          {standing.statusDescription}{" "}
          <span style={{ fontSize: "11px" }}>
            (Updated {standing.effectiveDateFormatted})
          </span>
          <br />
          {standing.statusCode !== "GST" && (
            <span style={{ fontSize: "11px" }}>
              {standing.actionDescription}
            </span>
          )}
        </td>
        <td style={{ textAlign: "right" }}>{standing.termName}</td>
      </tr>
    );
  } else {
    return null;
  }
};

PastStanding.displayName = "PastStanding";
PastStanding.propTypes = {
  standing: PropTypes.object,
};

export default PastStanding;
