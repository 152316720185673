import React, { useState } from "react";
import PropTypes from "prop-types";

import { Tabs, TabPanels, TabPanel } from "@reach/tabs";
import "@reach/tabs/styles.css";

import { Accordion } from "components/ui/Accordion";
import { CardBody } from "components/ui/Card";
import ButtonAsLink from "components/ui/ButtonAsLink";

import {
  CompletedTask,
  Task,
  CompletedAgreement,
} from "components/cards/TasksCard/Task";
import { TasksWrapper } from "components/cards/TasksCard/CategoryUI";

export const TasksForYear = ({ completedTasks, finaidYearId, tasks }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const onClick = () => setTabIndex((prev) => (prev === 0 ? 1 : 0));

  // Prevents a warning about setting `onChange` on the `Tabs` component
  // We use a no-op because we are giving control of the tab panel to
  // a button with custom behavior.
  const noOp = () => {};

  if (tasks.length === 0 && completedTasks.length === 0) {
    return (
      <CardBody data-testid="no-financial-tasks-notice">
        You have no tasks for {parseInt(finaidYearId) - 1}-{finaidYearId}.
      </CardBody>
    );
  }

  if (tasks.length > 0 || completedTasks.length > 0) {
    return (
      <Tabs index={tabIndex} onChange={noOp}>
        <TabPanels>
          <TabPanel>
            <Accordion collapsible>
              <TasksWrapper>
                {tasks.map((task, index) => (
                  <Task key={index} task={task} />
                ))}
              </TasksWrapper>
            </Accordion>
          </TabPanel>
          <TabPanel>
            <Accordion collapsible>
              <TasksWrapper>
                {completedTasks.map((task, index) => {
                  if (task.type === "CompletedAgreement") {
                    return <CompletedAgreement key={index} task={task} />;
                  }
                  return <CompletedTask key={index} task={task} />;
                })}
              </TasksWrapper>
            </Accordion>
          </TabPanel>
        </TabPanels>
        <ButtonAsLink
          onClick={onClick}
          style={{ padding: `var(--large-spacing)`, width: `100%` }}
        >
          {tabIndex === 0 && <>Show Completed ({completedTasks.length})</>}
          {tabIndex === 1 && <>Show Uncompleted ({tasks.length})</>}
        </ButtonAsLink>
      </Tabs>
    );
  }

  return null;
};
TasksForYear.displayName = "FinancialAidTasksForYear";
TasksForYear.propTypes = {
  completedTasks: PropTypes.array,
  finaidYearId: PropTypes.string,
  tasks: PropTypes.array,
};
