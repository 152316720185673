import React from "react";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import WithMyAcademics from "components/data/WithMyAcademics";
import WithSemesters from "components/data/WithSemesters";
import SemestersContext from "components/contexts/SemestersContext";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import StudentFinalExamScheduleCard from "components/cards/StudentFinalExamScheduleCard";

const WrappedStudentFinalExamScheduleCard = () => (
  <WithMyAcademics>
    <WithSemesters>
      <SemestersContext.Consumer>
        {({ currentStudentSemester, semesters, loadState }) => {
          return (
            <StudentFinalExamScheduleCard
              currentSemester={currentStudentSemester}
              semesters={semesters}
              loadState={loadState}
            />
          );
        }}
      </SemestersContext.Consumer>
    </WithSemesters>
  </WithMyAcademics>
);

const NgStudentFinalExamScheduleCard = () => (
  <ReduxProvider>
    <Router>
      <Routes>
        <Route
          path="academics/semester/:semesterId"
          element={<WrappedStudentFinalExamScheduleCard />}
        />
        <Route
          path="academics"
          element={<WrappedStudentFinalExamScheduleCard />}
        />
      </Routes>
    </Router>
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component(
    "studentFinalExamScheduleCard",
    react2angular(NgStudentFinalExamScheduleCard)
  );
