import React from "react";
import PropTypes from "prop-types";

import TermRegistrationStatus from "./TermRegistrationStatus";
import { isLoaded } from "data/utils/statusHelpers";

const TermRegistrationStatuses = ({ termRegistrationsData }) => {
  const { loadState = "idle", termRegistrations = [] } =
    termRegistrationsData || {};

  const termRegistrationsLoaded = isLoaded(loadState);

  if (termRegistrationsLoaded) {
    return (
      <div
        data-testid="term-registration-statuses"
        className="TermRegistrationStatuses"
        style={{ marginBottom: "var(--large-spacing)" }}
      >
        {termRegistrations.map((reg) => (
          <TermRegistrationStatus key={reg.termId} termRegistration={reg} />
        ))}
      </div>
    );
  } else {
    return null;
  }
};

TermRegistrationStatuses.displayName = "TermRegistrationStatuses";
TermRegistrationStatuses.propTypes = {
  termRegistrationsData: PropTypes.object,
};

export default TermRegistrationStatuses;
