import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import SpinnerIf from "components/ui/SpinnerIf";
import VerticallySpaced from "components/ui/VerticallySpaced";

export default function ActiveServiceAlerts() {
  const [serviceAlerts, setServiceAlerts] = useState([]);
  const [loadState, setLoadState] = useState("pending");

  useEffect(() => {
    fetch("/api/service_alerts?active=true")
      .then((res) => res.json())
      .then((data) => {
        setServiceAlerts(data.service_alerts);
        setLoadState("success");
      });
  }, []);

  return (
    <Card data-testid="active-service-alerts">
      <CardTitle>
        <h2>Active Service Alerts</h2>
        <Link to="/service_alerts">All Service Alerts</Link>
      </CardTitle>
      <CardBody>
        <SpinnerIf loading={loadState === "pending"}>
          {serviceAlerts.length === 0 ? (
            <p>No active service alerts</p>
          ) : (
            <VerticallySpaced>
              {serviceAlerts.map((alert) => (
                <Link key={alert.id} to={`/service_alerts/${alert.id}/edit`}>
                  {alert.title}
                </Link>
              ))}
            </VerticallySpaced>
          )}
        </SpinnerIf>
      </CardBody>
    </Card>
  );
}
ActiveServiceAlerts.displayName = "ActiveServiceAlerts";
