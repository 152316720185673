import React from "react";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import { AdvisingTransferCreditCard } from "components/cards/TransferCreditCard";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
const NgAdvisorTransferCreditCard = () => (
  <ReduxProvider>
    <Router>
      <Routes>
        <Route
          path="/user/overview/:uid"
          element={<AdvisingTransferCreditCard />}
        />
      </Routes>
    </Router>
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component(
    "advisorTransferCreditCard",
    react2angular(NgAdvisorTransferCreditCard)
  );
