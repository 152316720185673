import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import Spinner from "components/ui/Spinner";
import { HigherDegreeCommittee } from "./HigherDegreeCommitteesCard/HigherDegreeCommittee";
import { fetchAcademicHigherDegreeCommittees } from "data/slices/users/academicHigherDegreeCommittees";

import { useFeatures } from "components/hooks/useFeatures";
import { useUser } from "components/hooks/useUser";
import { useCurrentUID } from "components/hooks/useCurrentUID";
import { useSlice } from "components/hooks/useSlice";
import { useRoles } from "components/hooks/useRoles";

export default function HigherDegreeCommitteesCard() {
  const dispatch = useDispatch();

  const currentUID = useCurrentUID();
  const { delegateActingAsUid } = useSlice("myStatus");
  const { student, exStudent } = useRoles();
  const { csCommittees: hasCsCommittees } = useFeatures();
  const { studentCommittees: studentCommittees = [], loadState } = useUser(
    (state) => state.academicHigherDegreeCommittees
  );

  const canViewHigherDegreeCard =
    studentCommittees.length &&
    hasCsCommittees &&
    !delegateActingAsUid &&
    (student || exStudent);

  useEffect(() => {
    if (currentUID) {
      dispatch(fetchAcademicHigherDegreeCommittees(currentUID));
    }
  }, [dispatch, currentUID]);

  if (canViewHigherDegreeCard && studentCommittees.length) {
    return (
      <Card data-testid="higher-degree-committees-card">
        <CardTitle>
          <h2>
            {studentCommittees.length >= 2
              ? "Higher Degree Committees"
              : "Higher Degree Committee"}
          </h2>
        </CardTitle>
        <CardBody>
          {loadState === "pending" && <Spinner />}

          {loadState === "success" && (
            <>
              {studentCommittees.map(
                ({ committeeType, program, committeeMembers }, index) => (
                  <HigherDegreeCommittee
                    key={index}
                    committeeType={committeeType}
                    program={program}
                    committeeMembers={committeeMembers}
                  />
                )
              )}
            </>
          )}
        </CardBody>
      </Card>
    );
  }
  return null;
}
