import React, { useState } from "react";
import PropTypes from "prop-types";
import { semesterShape } from "components/propTypes/semesterShape";
import { useSelector } from "react-redux";
import { Card, CardBody, CardTitle } from "components/ui/Card";

import Spinner from "components/ui/Spinner";
import ButtonAsLink from "components/ui/ButtonAsLink";
import StudentFinalExamSemester from "./FinalExamScheduleCard/StudentFinalExamSemester";
import { YellowWarning } from "components/ui/Icons";

import { dispatchSlice } from "components/hooks/dispatchSlice";
import { getMyStatus } from "data/slices/myStatus";
import { fetchMyAcademics } from "data/actions/myAcademicsActions";

import {
  detectSemesterTimeConflicts,
  filterExamSemesters,
  getCourseCareerStatuses,
  sortSemesters,
} from "./FinalExamScheduleCard/shared";

export default function StudentFinalExamScheduleCard({
  currentSemester,
  semesters,
}) {
  dispatchSlice(getMyStatus);
  dispatchSlice(fetchMyAcademics);

  const {
    coursesPresent,
    featureEnabled,
    finalExamChangeNotice,
    gradCoursesPresent,
    gradCoursesOnlyPresent,
    isLoaded,
    lawCoursesPresent,
    sortedExamSemesters,
    studentCanViewFinalExamSchedule,
    timeConflictFound,
    ugrdCoursesPresent,
  } = prepareData(currentSemester, semesters);

  const [displayChangeNotice, setDisplayChangeNotice] = useState(false);

  const showCard =
    featureEnabled &&
    studentCanViewFinalExamSchedule &&
    !gradCoursesOnlyPresent &&
    coursesPresent;

  const lawExamLink =
    "https://www.law.berkeley.edu/php-programs/students/exams/examTimesList.php";
  const guidelinesLink =
    "https://registrar.berkeley.edu/scheduling/academic-scheduling/academic-scheduling-final-exam-guide-and-schedules/";

  if (showCard) {
    return (
      <Card
        data-testid="student-final-exam-schedule-card"
        id={"final-exam-schedule"}
      >
        <CardTitle>
          <h2>Final Exam Schedule</h2>
        </CardTitle>

        {isLoaded ? (
          <CardBody>
            {lawCoursesPresent && (
              <div>
                <a href={lawExamLink} target="_blank" rel="noopener noreferrer">
                  Law Final Exam Schedule
                </a>
              </div>
            )}

            {ugrdCoursesPresent && (
              <>
                <div
                  data-testid="exam-schedule-change-notice"
                  style={{ padding: "var(--sm) 0" }}
                >
                  Exam information is subject to change.{" "}
                  <ButtonAsLink
                    onClick={() => setDisplayChangeNotice(!displayChangeNotice)}
                  >
                    Learn more
                  </ButtonAsLink>
                  {displayChangeNotice && (
                    <div
                      style={{ fontSize: "12px", margin: "10px 0" }}
                      dangerouslySetInnerHTML={{
                        __html: finalExamChangeNotice,
                      }}
                    ></div>
                  )}
                </div>

                {timeConflictFound && (
                  <div
                    data-testid="exam-schedule-time-conflict"
                    style={{
                      backgroundColor: "var(--bianca)",
                      borderLeft: "5px solid var(--buttercup)",
                      marginLeft: "-15px",
                      padding: "var(--sm) var(--lg)",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>
                      <YellowWarning />
                      Time Conflict Detected
                    </div>
                    <div>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={guidelinesLink}
                        title="Learn More about Final Exam Schedule Conflicts"
                      >
                        Read Guidelines and Expectations
                      </a>
                    </div>
                  </div>
                )}
              </>
            )}

            <div>
              {sortedExamSemesters.map((examSemester, index) => (
                <div key={index}>
                  <StudentFinalExamSemester examSemester={examSemester} />
                </div>
              ))}
            </div>

            {gradCoursesPresent && ugrdCoursesPresent && (
              <div
                style={{ color: "var(--dove-gray)", padding: "var(--lg) 0" }}
              >
                Note: Exams for graduate level courses are not represented here.
              </div>
            )}
          </CardBody>
        ) : (
          <CardBody>
            <Spinner />
          </CardBody>
        )}
      </Card>
    );
  } else {
    return null;
  }
}

StudentFinalExamScheduleCard.displayName = "StudentFinalExamScheduleCard";
StudentFinalExamScheduleCard.propTypes = {
  currentSemester: semesterShape,
  semesters: PropTypes.arrayOf(semesterShape),
};

const prepareData = (currentSemester, semesters) => {
  const {
    featureEnabled,
    finalExamChangeNotice,
    isLoaded,
    studentCanViewFinalExamSchedule,
  } = useSelector((state) => {
    const {
      myAcademics: {
        isLoading: myAcademicsLoading = true,
        examMessage: finalExamChangeNotice,
      } = {},
      myStatus: {
        academicRoles: {
          current: { summerVisitor: userIsCurrentSummerVisitor } = {},
        } = {},
        delegateActingAsUid,
        features = {},
        roles: { student: userIsStudent } = {},
      },
    } = state;

    const isLoaded = myAcademicsLoading === false;
    const featureEnabled = features["finalExamScheduleStudent"];
    const studentCanViewFinalExamSchedule =
      userIsStudent && !delegateActingAsUid && !userIsCurrentSummerVisitor;

    return {
      delegateActingAsUid,
      featureEnabled,
      finalExamChangeNotice,
      isLoaded,
      studentCanViewFinalExamSchedule,
    };
  });

  let unfilteredExamSemesters = [];
  if (currentSemester) {
    unfilteredExamSemesters = [currentSemester];
  } else {
    unfilteredExamSemesters = semesters;
  }
  const filteredSemesters = filterExamSemesters(unfilteredExamSemesters);
  const sortedExamSemesters = sortSemesters(filteredSemesters);
  const timeConflictFound = detectSemesterTimeConflicts(sortedExamSemesters);
  const {
    coursesPresent,
    ugrdCoursesPresent,
    lawCoursesPresent,
    gradCoursesPresent,
    gradCoursesOnlyPresent,
  } = getCourseCareerStatuses(unfilteredExamSemesters);

  return {
    coursesPresent,
    featureEnabled,
    finalExamChangeNotice,
    gradCoursesPresent,
    gradCoursesOnlyPresent,
    isLoaded,
    lawCoursesPresent,
    sortedExamSemesters,
    studentCanViewFinalExamSchedule,
    timeConflictFound,
    ugrdCoursesPresent,
  };
};
