import React from "react";
import PropTypes from "prop-types";
import { HigherDegreeCommitteeMember } from "./HigherDegreeCommitteeMember";

export const DisclosureContents = ({ label, members = [] }) => {
  if (members.length > 0) {
    return (
      <table>
        <thead>
          <tr>
            <th style={{ textAlign: `left`, color: `var(--dove-gray)` }}>
              {label}
            </th>
          </tr>
        </thead>
        <tbody>
          {members.map((member, index) => (
            <HigherDegreeCommitteeMember key={index} member={member} />
          ))}
        </tbody>
      </table>
    );
  }

  return null;
};

DisclosureContents.propTypes = {
  label: PropTypes.string,
  members: PropTypes.array,
};
