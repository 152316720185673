import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import { CCTable } from "components/ui/Table";
import AccessibleLinkContent from "components/ui/AccessibleLinkContent";
import { rolesShape } from "../propTypes/rolesShape";
import { sectionShape } from "../propTypes/sectionShape";

import { useSlice } from "../hooks/useSlice";
import { useCurrentCourses } from "../hooks/useCurrentCourses";
import { Spinner } from "components/ui/Spinner";

const StyledTd = styled.td`
  text-align: center;
`;

const GradingLegendContainer = styled.div`
  border-top: 1px solid var(--gallery);
  margin-top: 15px;
  padding-top: 10px;
`;

const IconContainer = styled.span`
  float: right;
`;

export const GradingLegend = ({ isInstructor }) => {
  if (isInstructor) {
    return (
      <GradingLegendContainer>
        <h3>Grading Legend</h3>
        <span>
          <i className="fa fa-list-ul"></i>
          <span> Can enter grades</span>
        </span>
        <IconContainer>
          <i className="fa fa-check"></i>
          <span> Can enter and approve grades</span>
        </IconContainer>
      </GradingLegendContainer>
    );
  }
  return null;
};

GradingLegend.displayName = "GradingLegend";
GradingLegend.propTypes = {
  isInstructor: PropTypes.bool,
};

export const FacultyGrading = ({ isInstructor, gradingAccess }) => {
  if (!isInstructor) return null;
  let icon;

  if (gradingAccess === "approveGrades") {
    icon = <i className="fa fa-check"></i>;
  }

  if (gradingAccess === "enterGrades") {
    icon = <i className="fa fa-list-ul"></i>;
  }
  if (icon) {
    return <StyledTd>{icon}</StyledTd>;
  }
  return null;
};

FacultyGrading.displayName = "FacultyGrading";
FacultyGrading.propTypes = {
  isInstructor: PropTypes.bool,
  gradingAccess: PropTypes.string,
};

export const GradingHeader = ({ roles, index }) => {
  if (roles.faculty && index === 0) {
    return (
      <th width="20%">
        <h3>Grading</h3>
      </th>
    );
  }
  return null;
};

GradingHeader.displayName = "GradingHeader";
GradingHeader.propTypes = {
  roles: rolesShape,
  index: PropTypes.number,
};

export const TeachingInstructorsCard = () => {
  const { roles } = useSlice("myStatus");
  const { loadState, currentTeachingCourse: course } = useCurrentCourses();

  const sections = course.sections.filter(
    (section) => !section.scheduledWithCcn
  );
  const instructorCount = sections.reduce(
    (accumulator, section) => accumulator + section.instructors.length,
    0
  );

  return (
    <Card data-testid="teaching-instructors-card">
      <CardTitle>
        <h2>{instructorCount === 1 ? "Instructor" : "Instructors"}</h2>
      </CardTitle>
      <CardBody>
        {loadState === "pending" && <Spinner />}
        {loadState === "success" && (
          <>
            {sections.map((section, index) => (
              <Section
                key={index}
                section={section}
                index={index}
                roles={roles}
              />
            ))}
            <GradingLegend isInstructor={roles.faculty} />
          </>
        )}
      </CardBody>
    </Card>
  );
};

TeachingInstructorsCard.displayName = "InstructorsCard";

function Section({ index, section, roles }) {
  const instructors = [...section.instructors].sort(
    (a, b) => a.ccDelegateRoleOrder - b.ccDelegateRoleOrder
  );
  return (
    <SectionTable data-testid="section-table">
      <thead>
        <tr>
          <th width="50%">
            <h3>{section.section_label}</h3>
          </th>
          <th width="30%">{index === 0 && <h3>Role</h3>}</th>
          <GradingHeader roles={roles} index={index} />
        </tr>
      </thead>
      <tbody>
        {instructors.map((instructor, index) => (
          <tr key={index}>
            <td>
              <a
                href={`https://www.berkeley.edu/directory/results?search-term=${instructor.name.replace(
                  / /g,
                  "+"
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AccessibleLinkContent showNewWindow={true}>
                  &#9632; {instructor.name}
                </AccessibleLinkContent>
              </a>
            </td>
            <td>{instructor.ccDelegateRole}</td>
            <FacultyGrading
              isInstructor={roles.faculty}
              gradingAccess={instructor.ccGradingAccess}
            />
          </tr>
        ))}
      </tbody>
    </SectionTable>
  );
}

Section.displayName = "Section";
Section.propTypes = {
  index: PropTypes.number,
  section: sectionShape,
  roles: rolesShape,
};

const SectionTable = styled(CCTable)`
  thead th {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;
