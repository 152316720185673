import React from "react";
import PropTypes from "prop-types";

const isNumber = function (number) {
  return !isNaN(parseFloat(number)) && isFinite(number);
};

const numberWithCommas = function (number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const Amount = ({ value, removeColor }) => {
  if (isNumber(value)) {
    let text = "";
    let styles = {};
    const numberString = numberWithCommas(value);

    if (!removeColor) {
      styles.color = `var(--jewel)`;
    }

    if (value >= 0) {
      text += "$ " + numberString;
    } else {
      text += "- $ " + numberString.replace("-", "");
    }
    // replaces spaces with non-breaking spaces
    text = text.replace(/\s/g, "\u00A0");

    return (
      <span style={styles} data-testid="amount">
        {text}
      </span>
    );
  } else {
    return null;
  }
};

Amount.displayName = "Amount";
Amount.propTypes = {
  value: PropTypes.number,
  removeColor: PropTypes.bool,
};

export { Amount as default };
